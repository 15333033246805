// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

import FingerprintOutlined from '@mui/icons-material/FingerprintOutlined';
import VerifiedUser from '@mui/icons-material/VerifiedUser';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH } from '../../../config/constants.config';

import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import Button from '../../shared/Buttons/Button.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_NAME = '';
const DEFAULT_MAX_NAME_LENGTH = MAX_LENGTH[1];
const DEFAULT_START_DATE = null;
const DEFAULT_END_DATE = null;
const DEFAULT_SUBJECT_NAME = '';
const DEFAULT_HIDE_HASH_BUTTON = false;
const DEFAULT_TRANSACTION_HASH = '';
const DEFAULT_SIGNED_TOKEN = '';
const DEFAULT_STATUS_COLOR = '#012A2D';
const DEFAULT_STATUS = '';
const DEFAULT_MAX_STATUS_LENGTH = MAX_LENGTH[3];
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_HIDE_LEVEL_OF_ASSURANCE = false;
const DEFAULT_LEVEL_OF_ASSURANCE = 0;
const DEFAULT_HIDE_DATES = false;
const DEFAULT_VALUE_IS_FILE = false;
const DEFAULT_VALUE = '';
const DEFAULT_TRANSLATE_PREFIX = 'credential-row';
const DEFAULT_ACTION_BUTTONS = null;

const CHECKBOXES = [1, 2, 3];

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CredentialRow(props) {
  const i18nTranslate = props.translate || DEFAULT_TRANSLATE;
  const name = props.name || DEFAULT_NAME;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const startDate = props.startDate || DEFAULT_START_DATE;
  const endDate = props.endDate || DEFAULT_END_DATE;
  const subjectName = props.subjectName || DEFAULT_SUBJECT_NAME;
  const hideHashButton = props.hideHashButton || DEFAULT_HIDE_HASH_BUTTON;
  const transactionHash = props.transactionHash || DEFAULT_TRANSACTION_HASH;
  const signedToken = props.signedToken || DEFAULT_SIGNED_TOKEN;
  const statusColor = props.statusColor || DEFAULT_STATUS_COLOR;
  const status = props.status || DEFAULT_STATUS;
  const maxStatusLength = props.maxStatusLength || DEFAULT_MAX_STATUS_LENGTH;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const hideLevelOfAssurance = props.hideLevelOfAssurance || DEFAULT_HIDE_LEVEL_OF_ASSURANCE;
  const levelOfAssurance = props.levelOfAssurance || DEFAULT_LEVEL_OF_ASSURANCE;
  const hideDates = props.hideDates || DEFAULT_HIDE_DATES;
  const valueIsFile = props.valueIsFile || DEFAULT_VALUE_IS_FILE;
  const value = props.value || DEFAULT_VALUE;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;
  const actionButtons = props.actionButtons || DEFAULT_ACTION_BUTTONS;

  const parsedStartDate = startDate
    ? new Date(startDate * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';
  const parsedEndDate = endDate
    ? new Date(endDate * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';

  const translate = (string) => {
    return i18nTranslate(`${translatePrefix}-${string}`);
  };

  const copyHashTooltip = translate('copy-hash-button-tooltip');
  const copySignedTokenTooltip = translate('copy-signed-token-button-tooltip');

  const attachedData = value ? (
    valueIsFile ? (
      <Link
        sx={{
          fontSize: '0.75rem',
          fontWeight: 'bold',
          textDecoration: 'none',
          color: '#1B5255',
          'a:visited': {
            textDecoration: 'none',
            color: '#1B5255'
          }
        }}
        target='_blank'
        href={value}>
        {translate('download-document')}
      </Link>
    ) : (
      <MaxLengthText text={value} maxLength={MAX_LENGTH[1]} />
    )
  ) : (
    <MaxLengthText text={translate('no-data')} maxLength={MAX_LENGTH[1]} />
  );

  return (
    <TableRow>
      <Hider isHidden={hideLevelOfAssurance}>
        <TableCell useTypography={false}>
          <Grid container direction='row' justifyContent='space-around'>
            {CHECKBOXES.map((loa) => (
              <Grid item key={loa}>
                <Checkbox
                  key={loa}
                  value={levelOfAssurance === loa}
                  checked={levelOfAssurance === loa}
                  disabled={true}
                />
              </Grid>
            ))}
          </Grid>
        </TableCell>
      </Hider>
      <TableCell align='left' useTypography={false}>
        <MaxLengthText text={name} maxLength={maxNameLength} />
      </TableCell>
      <Hider isHidden={hideDates}>
        <TableCell>{`${parsedStartDate} - ${parsedEndDate}`}</TableCell>
      </Hider>
      <TableCell>{subjectName}</TableCell>
      <TableCell useTypography={false}>{attachedData}</TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={translate(status.toLowerCase())} color={statusColor} maxLength={maxStatusLength} />
      </TableCell>
      <TableCell useTypography={false}>
        <Hider isHidden={hideHashButton}>
          <Button hasIcon tooltip={copyHashTooltip} onClick={() => navigator.clipboard.writeText(transactionHash)}>
            <FingerprintOutlined />
          </Button>
        </Hider>
        <Button hasIcon tooltip={copySignedTokenTooltip} onClick={() => navigator.clipboard.writeText(signedToken)}>
          <VerifiedUser />
        </Button>
      </TableCell>
      <Hider isHidden={hideActions}>
        <TableCell useTypography={!actionButtons}>{actionButtons || translate('no-actions-available')}</TableCell>
      </Hider>
    </TableRow>
  );
}
