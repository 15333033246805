// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link, useLinkClickHandler } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import {
  clearCatalogCache,
  fetchCatalogAsync,
  selectCatalogState,
  selectCatalog,
  selectCatalogCount,
  selectCatalogPage
} from '../../../../helpers/reducers/catalog';
import { PAGE_ROUTES, ROW_COUNT } from '../../../../config/constants.config';

import CatalogCredentialTable from '../../../../components/private/Tables/CatalogCredentialTable.component';
import CatalogFilters from '../../../../components/private/Filters/CatalogFilters.component';

import TableCell from '../../../../components/shared/Tables/TableCell.component';
import Button from '../../../../components/shared/Buttons/Button.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreateRequest(props) {
  const translate = props.translator || DEFAULT_TRANSLATE;

  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState(undefined);
  const [search, setSearch] = useState(null);
  const [holder, setHolder] = useState(null);
  const [useCase, setUseCase] = useState(null);
  const [issuer, setIssuer] = useState(null);
  const [isMovingAway, setIsMovingAway] = useState(false);
  const [page, setPage] = useState(useSelector(selectCatalogPage));
  const [selectedCredentials, setSelectedCredentials] = useState({});
  const [disableSendButton, setDisableSendButton] = useState(false);

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);
  const catalogCount = useSelector(selectCatalogCount);

  const isFiltersLoading = catalogState === 0 && filterData === undefined;
  const isCatalogLoading = catalogState === 2;
  const catalogNeedsReload = catalogState === 1 && !isMovingAway;

  const urlForm = new URLSearchParams();
  urlForm.append('credentials', JSON.stringify(selectedCredentials));
  const redirect = useLinkClickHandler(`${PAGE_ROUTES.AdminPresentationForm}?${urlForm}`);

  if (catalogNeedsReload) {
    dispatch(
      fetchCatalogAsync({
        page,
        search,
        holder: holder ? holder.holder : null,
        useCase: useCase ? useCase.useCase : null,
        issuer: issuer ? issuer.issuer : null,
        limit: filterData ? undefined : null
      })
    );
  }

  if (isFiltersLoading) {
    setFilterData(catalog || []);
    dispatch(clearCatalogCache());
  }

  const clearFilters = () => {
    setSearch(null);
    setHolder(null);
    setUseCase(null);
    setIssuer(null);
    onFilterSubmit();
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCatalogCache());
  };

  const selectedCredentialsChanged = (newSelectedCredentials) => {
    if (disableSendButton) {
      setDisableSendButton(false);
    }

    setSelectedCredentials(newSelectedCredentials);
  };

  return (
    <Grid container direction='column' alignItems='stretch' spacing={2}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.AdminPresentations}>{translate('presentations-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>
                  {translate('presentations-create-page-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CatalogFilters
          translate={translate}
          width='12.5rem'
          catalog={filterData}
          isLoading={isFiltersLoading}
          search={search}
          setSearch={setSearch}
          issuer={issuer}
          setIssuer={setIssuer}
          useCase={useCase}
          setUseCase={setUseCase}
          holder={holder}
          setHolder={setHolder}
          onFilterSubmit={onFilterSubmit}
          clearFilters={clearFilters}
        />
      </Grid>

      <CatalogCredentialTable
        translate={translate}
        isLoading={isCatalogLoading}
        catalog={catalog}
        rowsPerPage={ROW_COUNT[0]}
        catalogCount={catalogCount}
        page={page}
        setPage={setPage}
        hideActions
        maxNameLength={50}
        maxUseCaseLength={50}
        selectedCredentialsChanged={selectedCredentialsChanged}
        selectedCredentials={selectedCredentials}>
        <TableCell useTypography={false}>
          <Button
            tooltip={disableSendButton ? translate('admin-presentations-create-not-enough-credentials') : ''}
            disabled={disableSendButton}
            buttonProps={{
              sx: {
                opacity: disableSendButton ? '0.5' : '1',
                backgroundColor: '#1B5255',
                color: '#ffffff',
                textTransform: 'none',
                fontWeight: 'bold',
                boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: '#1B5255',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }
            }}
            tooltipProps={{
              open: true
            }}
            onClick={(e) => {
              if (Object.keys(selectedCredentials).length === 0) {
                setDisableSendButton(true);
                return;
              }

              setIsMovingAway(true);
              dispatch(clearCatalogCache());
              redirect(e);
            }}>
            {translate('create-request-page-table-process-data')}
          </Button>
        </TableCell>
      </CatalogCredentialTable>
    </Grid>
  );
}
