import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function CepsaIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 269.44, height: 69.2 }}>
      <path className="cls-1" d="M20.13,69.2A8.92,8.92,0,0,1,20,66.8c1.27-6.24,2.61-12.45,3.93-18.68.49-2.29.22-2.65-2.1-2.65-6.37,0-12.74,0-19.11,0-.73,0-1.46-.16-2.7-.31a21.75,21.75,0,0,1,1.84-1.9c2.42-1.92,4.8-3.89,7.34-5.64a7.37,7.37,0,0,1,3.69-1.32c4.89-.15,9.78-.08,14.67,0a9.8,9.8,0,0,1,2.84.45c3.71,1.13,5.45,3.91,4.7,7.69-.93,4.72-1.88,9.46-3.07,14.12A7.61,7.61,0,0,1,29.56,62c-2.92,2.51-6,4.8-9,7.18Z" />
      <path className="cls-1" d="M58,0a10.34,10.34,0,0,1,.12,2.41c-1.26,6.17-2.6,12.34-3.9,18.5-.54,2.53-.31,2.83,2.26,2.83,6.25,0,12.5,0,18.75,0a22,22,0,0,1,2.32.23c0,.41,0,.61,0,.65C74.2,27.32,70.86,30,67.4,32.57a4.83,4.83,0,0,1-2.62.41c-4.51,0-9,.05-13.53,0a12.91,12.91,0,0,1-3.61-.52c-3.62-1.13-5.3-3.86-4.58-7.59.9-4.61,1.79-9.23,3-13.75A9.19,9.19,0,0,1,48.89,7C51.67,4.48,54.71,2.3,57.64,0Z" />
      <path className="cls-1" d="M263.1,52.77h-8.22c.7-3.41,1.38-6.75,2.11-10.34-4.5,0-8.73,0-13,.06-.41,0-1,.84-1.16,1.39-.62,2.5-1.14,5-1.61,7.55-.2,1.08-.65,1.49-1.79,1.44-2.18-.09-4.36,0-6.87,0l7.69-36.49h9.55c4.38,0,8.76,0,13.14,0,4.86.05,7.25,3.13,6.24,7.9q-2.81,13.22-5.57,26.43C263.49,51.38,263.29,52.05,263.1,52.77ZM244.68,35.2c4.56,0,8.79,0,13,0a1.43,1.43,0,0,0,1-.89c.63-2.75,1.25-5.51,1.71-8.3a1.9,1.9,0,0,0-2.06-2.4c-3.35-.05-6.69-.07-10,0a1.88,1.88,0,0,0-1.38,1.11C246.14,28.07,245.47,31.47,244.68,35.2Z" />
      <path className="cls-1" d="M222.78,27.85c.06-.71.09-1.15.14-1.59.2-1.68-.57-2.64-2.22-2.69-2.77-.07-5.55-.11-8.31,0-2.5.12-4.6,3.2-3.71,5.5a3,3,0,0,0,2,1.41c4.11.76,8.26,1.35,12.38,2.05a13.15,13.15,0,0,1,3,.83,4.3,4.3,0,0,1,2.66,5c-.44,2.4-.93,4.8-1.51,7.18a9.43,9.43,0,0,1-9.3,7.27q-7.53,0-15.07,0c-4.6,0-7.1-3.16-6.18-7.67.77-3.81.77-3.81,4.67-3.81h4.36c-.1.73-.19,1.22-.25,1.72-.18,1.59.54,2.52,2.13,2.55,2.89.07,5.8.09,8.69,0,2.28-.08,4.55-3.84,3.48-5.81a3.17,3.17,0,0,0-2.09-1.08c-4.18-.74-8.38-1.39-12.57-2.05-4.36-.69-6-2.93-5.1-7.29.36-1.83.76-3.65,1.16-5.48a9.23,9.23,0,0,1,9-7.53c5.21-.08,10.44-.09,15.65,0,4.44.07,6.84,3.18,6,7.49-.81,4-.81,4-4.94,4Z" />
      <path className="cls-1" d="M159.47,16.36,158,23.55h-7c-3.8,0-7.6,0-11.39,0-1.19,0-1.77.36-2,1.53-.31,1.82-.71,3.62-1.12,5.69h18.49c-.44,2.25-.74,4.27-1.29,6.22-.13.46-1.1.95-1.7,1-5,.08-10,.13-15.06,0-1.69,0-2.32.58-2.47,2.11-.09.83-.32,1.64-.47,2.47-.43,2.22.29,3.14,2.55,3.15,5.09,0,10.18,0,15.26,0,.44,0,.88.07,1.46.12l-1.44,6.92c-.53,0-1,.13-1.4.13-6.31,0-12.62,0-18.93,0-5,0-7.42-3.12-6.39-8,1.87-8.87,3.78-17.74,5.57-26.63.31-1.52.91-1.92,2.4-1.9,8,.07,16.1,0,24.15,0Z" />
      <path className="cls-1" d="M167.36,52.73h-8.28c1.31-6.3,2.58-12.46,3.87-18.61,1.12-5.35,2.3-10.68,3.37-16,.26-1.31.76-1.77,2.15-1.75,6.63.08,13.27,0,19.9,0,5.1,0,7.43,2.82,6.46,7.85q-1.07,5.49-2.32,10.94a9.33,9.33,0,0,1-9.08,7.25c-4.05,0-8.11.05-12.17,0-1.26,0-1.8.35-2,1.65C168.71,47,168,49.82,167.36,52.73ZM171.18,35c3.87,0,7.52.18,11.13-.12.89-.07,2-1.55,2.4-2.61a33.38,33.38,0,0,0,1.3-5.82c.34-1.9-.43-2.88-2.32-2.92-3,0-5.92,0-8.88,0a1.75,1.75,0,0,0-1.37.89C172.63,27.9,172,31.35,171.18,35Z" />
      <path className="cls-1" d="M113.84,27.84c.46-3.8,0-4.29-3.6-4.29h-6.76a3.12,3.12,0,0,0-3.39,2.76C99,31.66,97.8,37,96.7,42.36c-.49,2.36.29,3.28,2.66,3.3s4.9,0,7.34,0c2.26,0,3.36-.79,3.85-3,.24-1,.66-1.37,1.67-1.34,2.24,0,4.48,0,6.73,0-.23,7.63-4.74,12-11.25,11.52-1.79-.14-3.6,0-5.41,0-2.83,0-5.67,0-8.5,0-4.5-.06-7-3.15-6-7.58q2.19-10.59,4.43-21.16c1-4.92,4.57-7.73,9.61-7.73,4.83,0,9.66,0,14.49,0,5.16,0,7.58,3,6.52,8-.22,1.07-.48,2.13-.78,3.45h-8.18Z" />
      <path className="cls-1" d="M2.58,32c.63-.43,1.28-.83,1.88-1.29C6.66,29,8.89,27.34,11,25.57a7.89,7.89,0,0,1,5.52-1.84c2.77.07,5.55.15,8.31,0a7.9,7.9,0,0,0,3.87-1.32c3.56-2.55,7-5.33,10.45-8A6.3,6.3,0,0,1,41,13.79a7.47,7.47,0,0,1,.11,1.81c-.57,3-1.19,5.93-1.83,8.89A10.21,10.21,0,0,1,30,32.84c-3,.27-6.05.15-9.08.16q-7.81,0-15.65,0A20.22,20.22,0,0,1,3,32.77Z" />
      <path className="cls-1" d="M75.06,36.38c0,.45,0,.55,0,.58-4.45,3.55-7.9,9.13-14.58,8.43s-12.21,1.06-16.91,5.82A36.19,36.19,0,0,1,38.68,55a4.58,4.58,0,0,1-1.54.37,4.57,4.57,0,0,1-.2-1.43c.72-3.6,1.44-7.19,2.27-10.77.74-3.19,4.71-6.27,8.65-6.78a25.49,25.49,0,0,1,3.27-.16q10.83,0,21.65,0C73.52,36.23,74.26,36.33,75.06,36.38Z" />
    </SvgIcon>
  )
}