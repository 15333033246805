const mainLanding = [
  {
    title: "landing-what-title",
    subtitle: "landing-what-subtitle",
    description: "landing-what-description"
  },
  {
    title: "landing-how-title",
    subtitle: "landing-how-subtitle",
    description: "landing-how-description"
  },
  {
    title: "landing-why-title",
    subtitle: "landing-why-subtitle",
    description: "landing-why-description"
  }
]

export default mainLanding