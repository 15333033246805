import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getResponseJson, isOk } from "../axios.helper";
import config from "../../config/config";
import { decodeAllJwts, mapPresentationRequests, mapPresentations } from "../jwt.helpers";
import { ROW_COUNT } from "../../config/constants.config";

const initialState = {
  value: [],
  isRequest: sessionStorage.getItem("entityPresentationsIsRequest") == null ? true : sessionStorage.getItem("entityPresentationsIsRequest") === 'true', 
  status: 1,
  page: 0,
  count: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchEntityPresentationsAsync = createAsyncThunk("entityPresentations/fetchPresentations", async ({ isRequest, page, startDate, endDate, search }) => {
  // count should be made user configurable.
  const limit = ROW_COUNT[0];
  const form = new URLSearchParams();
  let jwts = [];
  let endpoint = "/corporate/presentation";
  let mappingFunc = mapPresentations;

  if (isRequest) {
    endpoint = "/corporate/presentationRequest";
    mappingFunc = mapPresentationRequests;
  }

  let count = 0;
  if (config.mocked) {
    jwts = isRequest ? config.mockedData.presentations : config.mockedData.presentationRequests;
    count = jwts.length;
  } else {
    form.append("offset", limit * page);
    form.append("limit", limit);
    form.append("isDeleted", false);

    if (search) {
      form.append("search", search);
    }

    if (startDate) {
      form.append("validUntil", startDate);
    }
  
    if (endDate) {
      form.append("expiredFrom", endDate);
    }

    const response = await get(endpoint, form);

    if (isOk(response.status)) {
      jwts = getResponseJson(response);
      count = Number(response.headers["x-app-total-count"]) || 0;
    }
  }

  const value = await mappingFunc(decodeAllJwts(jwts), true);
  return { isRequest, page, count, value };
});

export const entityPresentationsSlice = createSlice({
  name: "entityPresentations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearEntityPresentationsCache: (state) => {
      state.status = 1;
      state.value = [];
    },

    setEntityPresentationsIsRequest: (state, action) => {
      state.isRequest = action.payload;
      sessionStorage.setItem("entityPresentationsIsRequest", action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntityPresentationsAsync.pending, (state) => {
        state.status = 2;
      })
      .addCase(fetchEntityPresentationsAsync.fulfilled, (state, action) => {
        state.status = 0;
        const payload = action.payload;
        state.isRequest = payload.isRequest;
        state.value = payload.value;
        state.page = payload.page;
        state.count = payload.count;
      });
  },
});

export const { clearEntityPresentationsCache, setEntityPresentationsIsRequest } = entityPresentationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectEntityPresentations = (state) => state.entityPresentations.value;
export const selectEntityPresentationsState = (state) => state.entityPresentations.status;
export const selectEntityPresentationsIsRequest = (state) => state.entityPresentations.isRequest;
export const selectEntityPresentationsPage = (state) => state.entityPresentations.page;
export const selectEntityPresentationsCount = (state) => state.entityPresentations.count;

export default entityPresentationsSlice.reducer;
