// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useLinkClickHandler, useLoaderData, Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

// ------FILE MODULES---------------------------------------------------------
import { PAGE_ROUTES, MAX_LENGTH } from '../../../../config/constants.config';
import { decodeAllJwts, mapPresentationRequests } from '../../../../helpers/jwt.helpers';
import { isOk, get, getResponseJson } from '../../../../helpers/axios.helper';

import PresentationRequestButtons from '../../../../components/private/Buttons/PresentationRequestButtons.component';
import PresentationRequestTable from '../../../../components/private/Tables/PresentationRequestTable.component';
import CatalogCredentialTable from '../../../../components/private/Tables/CatalogCredentialTable.component';

import config from '../../../../config/config';

// ---------------------------------------------------------------------------
// PRIVATE COMPONENTS
// ---------------------------------------------------------------------------
function PresentationRequestLoadFailed(props) {
  const translate = props.translate;
  const isFailed = props.isFailed;
  const children = props.children;

  if (!isFailed) {
    return children;
  }

  return (
    <Grid item>
      <Grid container direction='row' alignItems='center' justifyContent='center'>
        <Grid item>
          <Typography>{translate('entity-presentation-request-detail-page-failed-to-load')}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

async function loadPresentationRequest(presentationRequestId, setIsLoading, setPresentation, setErrorPopup) {
  setIsLoading(true);

  try {
    const prResponse = await get(`/dit/presentationRequest/${presentationRequestId}`);

    if (isOk(prResponse.status)) {
      const presentations = await mapPresentationRequests(decodeAllJwts([getResponseJson(prResponse)]), true);

      if (presentations.length > 0) {
        setPresentation(presentations[0]);
      }
    }
  } catch (exception) {
    setErrorPopup(exception);
  }

  setIsLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export async function entityPresentationRequestDetailsLoader({ params }) {
  return params.presentationRequestId;
}

export default function EntityPresentationRequestDetails(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const presentationRequestId = useLoaderData();

  const [isLoading, setIsLoading] = useState(undefined);
  const [presentation, setPresentation] = useState(undefined);
  const [selectedCredentials, setSelectedCredentials] = useState(undefined);

  const failedToGetPresentation = presentation === undefined;

  if (isLoading === undefined) {
    loadPresentationRequest(presentationRequestId, setIsLoading, setPresentation, setErrorPopup);
  }

  let credentials = [];
  if (!isLoading && !failedToGetPresentation) {
    const newSelectedCredentials = {};
    credentials = presentation.decoded.pr.data;

    if (selectedCredentials === undefined) {
      presentation.decoded.pr.data.forEach((credential) => {
        newSelectedCredentials[credential.field_name] = credential.levelOfAssurance;
      });
      setSelectedCredentials(newSelectedCredentials);
    }
  }

  let presentationRequest = {};
  let hideCredentialActions = true;

  if (!isLoading && !failedToGetPresentation) {
    presentationRequest = presentation.decoded.pr;
    presentationRequest.data = presentationRequest.data || presentationRequest.verifiableCredential;
    hideCredentialActions = presentationRequest.verifiableCredential || presentationRequest.type.includes(config.prConfig.delegatedType);
  }

  return (
    <Grid container direction='column' alignItems='stretch' spacing={5}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '1.375rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.EntityPresentations}>{translate('entity-presentations-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '1.375rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '1.375rem' }}>
                  {translate('presentations-send-page-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: '#1B5255',
                color: 'white',
                fontSize: '1.125rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1B5255',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                {translate('presentations-create-back')}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PresentationRequestLoadFailed translate={translate} isFailed={!isLoading && failedToGetPresentation}>
        <PresentationRequestTable
          translate={translate}
          setErrorPopup={setErrorPopup}
          isLoading={isLoading}
          presentationRequests={[presentation]}
          paddingBottom={0}
          hidePagination
          hideCredentialsButton
          buttonComponent={PresentationRequestButtons}
        />

        <CatalogCredentialTable
          translate={translate}
          isLoading={isLoading}
          catalog={credentials}
          hidePagination
          hideActions={hideCredentialActions}
          disableCheckboxes
          maxNameLength={MAX_LENGTH[1]}
          maxUseCaseLength={MAX_LENGTH[1]}
          selectedCredentialsLoading={selectedCredentials === undefined}
          selectedCredentials={selectedCredentials}
        />
      </PresentationRequestLoadFailed>
    </Grid>
  );
}
