import { Grid, Typography, Button, TextField, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { ArrowForwardIos, VisibilityOff, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { isOk, post } from "../../helpers/axios.helper";
import { setSession } from "../../helpers/reducers/session";
import DigitalisIcon from "../../components/shared/Icons/DigitalisIcon.component";
import config from "../../config/config";
import "./Login.view.css";
import { PAGE_ROUTES } from "../../config/constants.config";

const mockUsername = "example@example.com";
const mockPassword = "example";

async function onFormSubmit(username, password, navigate, dispatch, setSession, setErrorPopup) {
  if (!username || !password) {
    return;
  }

  if (config.mocked) {
    if (mockUsername === username && mockPassword === password) {
      navigate(PAGE_ROUTES.EntityCredentials)
    }

    return null;
  }

  var form = new URLSearchParams();
  form.append("username", username);
  form.append("password", password);
  form.append("client_id", "digitalis-wwc");
  form.append("grant_type", "password");

  const response = await post(config.keycloakURL, form);

  if (isOk(response.status)) {
    dispatch(setSession(response.data.access_token));
    navigate(PAGE_ROUTES.EntityCredentials)
    return;
  } 

  // If auth usage is disabled, the response from keycloak is ignored, and we are forcefully "logged in".
  // One should disable auth when testing locally without a keycloak server to point to.
  if (!config.useAuth) {
    navigate(PAGE_ROUTES.EntityCredentials)
    return;
  }

  setErrorPopup(response);
}

export default function Login(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const usernameChanged = (event) => {
    setUsername(event.target.value);
  };

  const passwordChanged = (event) => {
    setPassword(event.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(username, password, navigate, dispatch, setSession, setErrorPopup);
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" className="login-view">
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" padding={5}>
          <Grid item md={4}>
            <DigitalisIcon />
          </Grid>

          <Grid item md={4}>
            <Grid container direction="column" justifyContent="center" alignItems="stretch">
              <Grid item paddingTop={15}>
                <Typography sx={{ fontSize: "20px" }}>{translate("login-title")}</Typography>
              </Grid>

              <Grid item paddingTop={5}>
                <Typography sx={{ fontSize: "20px" }}>{translate("login-description")}</Typography>
              </Grid>

              <Grid item paddingTop={2}>
                <form onSubmit={formSubmit}>
                  <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid item>
                      <Grid container direction="column" justifyContent="center" alignItems="stretch">
                        <Grid item>
                          <Typography sx={{ fontSize: "20px" }}>{translate("login-email")}</Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            fullWidth
                            required
                            name="username"
                            value={username || ""}
                            onChange={usernameChanged}
                            id="outlined-required"
                            sx={{
                              border: "2px solid #049294",
                              borderRadius: "4px",
                              backgroundColor: "rgba(1, 42, 45, 1)",
                              input: { color: "white" },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" justifyContent="center" alignItems="stretch">
                        <Grid item>
                          <Typography sx={{ fontSize: "20px" }}>{translate("login-password")}</Typography>
                        </Grid>
                        <Grid item>
                          <OutlinedInput
                            sx={{
                              border: "2px solid #049294",
                              borderRadius: "4px",
                              backgroundColor: "rgba(1, 42, 45, 1)",
                              input: { color: "white" },
                            }}
                            fullWidth
                            required
                            name="password"
                            value={password || ""}
                            onChange={passwordChanged}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                  {showPassword ? <VisibilityOff sx={{ color: "#048183" }} /> : <Visibility sx={{ color: "#048183" }} />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container justifyContent="flex-end">
                        <Button
                          type="submit"
                          sx={{
                            border: "3px solid #049294",
                            borderRadius: "4px",
                            color: "white",
                            fontSize: "18px",
                          }}
                        >
                          <Typography paddingRight={5} paddingLeft={5}>
                            {translate("login-button")}
                          </Typography>
                          <ArrowForwardIos />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1.5} className="login-video">
        <video autoPlay loop muted>
          <source src="/images/login.mp4" type="video/mp4" />
        </video>
      </Grid>
    </Grid>
  );
}