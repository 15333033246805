// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLinkClickHandler, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { PAGE_ROUTES, PRESENTATION_REQUEST_STATUS } from '../../../config/constants.config';

import { clearPresentationsCache } from '../../../helpers/reducers/adminPresentations';
import { isOk, post } from '../../../helpers/axios.helper';

import GreenButton from '../Buttons/GreenButton.component';
import RedButton from '../Buttons/RedButton.component';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import WarningBox from '../../shared/DialogBoxes/WarningBox.component';
import TableCell from '../../shared/Tables/TableCell.component';
import Hider from '../../shared/Hider/Hider.component';

import config from '../../../config/config';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const TRANSLATE_PREFIX = 'presentation-request-template-row-';
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_HIDE_SEND_BUTTON = false;
const DEFAULT_HIDE_REQUEST_DETAILS = false;
const DEFAULT_LINK_STYLE = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textDecoration: 'none',
  color: '#1B5255',
  'a:visited': {
    textDecoration: 'none',
    color: '#1B5255'
  }
};
const DEFAULT_PRESENTATION_REQUEST_TEMPLATE = { storedJwt: {}, decoded: { pr: { type: [] } } };

function PresentationRequestTemplateButtons(props) {
  const translate = props.translate;
  const setErrorPopup = props.setErrorPopup;
  const presentationRequestTemplate = props.presentationRequestTemplate;
  const hideSendButton = props.hideSendButton;
  const hideRejectDeleteButton = props.hideRejectDeleteButton;

  const storedJwt = presentationRequestTemplate.storedJwt;
  const jti = storedJwt.jti;
  const canBeRejected = storedJwt.status === PRESENTATION_REQUEST_STATUS[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formSendState, setFormSendState] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const sendLinkClickHandler = useLinkClickHandler(`${PAGE_ROUTES.AdminPresentationsSend}/${jti}`);

  const onRejectOrDeleteClicked = async () => {
    const form = {
      ...storedJwt
    };
    let queryParams = new URLSearchParams();

    if (canBeRejected) {
      form.status = PRESENTATION_REQUEST_STATUS[1];
    } else {
      form.isDeleted = true;
      queryParams.append('propagated', true);
    }

    if (!form.fileUri) {
      form.fileUri = '';
    }

    setFormSendState(true);

    const result = await post(`/dit/presentationRequest/${jti}?${queryParams}`, form);
    if (isOk(result.status)) {
      dispatch(clearPresentationsCache());
      
      let navigationTarget = form.isDeleted ? PAGE_ROUTES.AdminPresentations : 0;
      navigate(navigationTarget);
    } else {
      setPopupOpen(false);
      setErrorPopup(result);
    }

    setFormSendState(false);
  };

  return (
    <Grid item>
      <WarningBox
        title={translate(`presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-title`)}
        open={popupOpen}
        actions={
          formSendState
            ? []
            : [
                {
                  label: translate('presentations-request-reject-popup-cancel'),
                  onClick: (event) => {
                    setPopupOpen(false);
                  }
                },
                {
                  label: translate(`presentations-request-popup${canBeRejected ? '-reject' : '-delete'}`),
                  onClick: (event) => {
                    onRejectOrDeleteClicked(event);
                  }
                }
              ]
        }>
        <SpinnerLoader
          isLoading={formSendState}
          text={translate(`presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-sending`)}>
          <Typography>
            {translate(`presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-body`)}
          </Typography>
        </SpinnerLoader>
      </WarningBox>
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Hider isHidden={hideSendButton || !canBeRejected}>
          <Grid item flexGrow={1}>
            <GreenButton text={translate('presentations-card-send')} onClick={sendLinkClickHandler} />
          </Grid>
        </Hider>
        <Hider isHidden={hideRejectDeleteButton}>
          <Grid item flexGrow={1}>
            <RedButton text={translate(`presentations-card-${canBeRejected ? 'reject' : 'delete'}`)} onClick={() => setPopupOpen(true)} />
          </Grid>
        </Hider>
      </Grid>
    </Grid>
  );
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTemplateRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const presentationRequestTemplate = props.presentationRequestTemplate || DEFAULT_PRESENTATION_REQUEST_TEMPLATE;
  const hideSendButton = props.hideSendButton || DEFAULT_HIDE_SEND_BUTTON;
  const hideRequestDetails = props.hideRequestDetails || DEFAULT_HIDE_REQUEST_DETAILS;
  const linkStyle = props.linkStyle || DEFAULT_LINK_STYLE;
  const ButtonComponent = props.buttonComponent || PresentationRequestTemplateButtons;

  const navigate = useNavigate();

  const jti = presentationRequestTemplate.storedJwt.jti;
  const name = presentationRequestTemplate.decoded.pr.procId;
  const purpose = presentationRequestTemplate.decoded.pr.procDesc;
  const file = presentationRequestTemplate.decoded.pr.procUrl;
  const isDelegated = presentationRequestTemplate.decoded.pr.type.includes(config.prConfig.delegatedType);
  const startDate = presentationRequestTemplate.decoded.nbf
    ? new Date(presentationRequestTemplate.decoded.nbf * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';
  const endDate = presentationRequestTemplate.decoded.exp
    ? new Date(presentationRequestTemplate.decoded.exp * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';

  const FileComponent = file ? Link : Fragment;
  const fileComponentProps = file
    ? {
        sx: linkStyle,
        target: '_blank',
        href: file
      }
    : {};
  const fileText = TRANSLATE_PREFIX + (file ? 'download-document' : 'no-file');
  const delegatedColor = isDelegated ? '#049294' : 'inherit';
  const delegatedText = TRANSLATE_PREFIX + (isDelegated ? 'delegated' : 'not-delegated');
  const detailsText = TRANSLATE_PREFIX + 'details';

  const detailsRoute = `${PAGE_ROUTES.AdminPresentations}/${jti}`;

  const openDetails = (event) => {
    event.preventDefault();
    navigate(detailsRoute);
  }

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{purpose}</TableCell>
      <TableCell>{`${startDate} - ${endDate}`}</TableCell>
      <TableCell useTypography={!file}>
        <FileComponent {...fileComponentProps}>{translate(fileText)}</FileComponent>
      </TableCell>
      <TableCell color={delegatedColor}>{translate(delegatedText)}</TableCell>
      <Hider isHidden={hideRequestDetails}>
        <TableCell useTypography={false}>
          <Link sx={linkStyle} onClick={openDetails} href={detailsRoute}>{translate(detailsText)}</Link>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <ButtonComponent
          translate={translate}
          setErrorPopup={setErrorPopup}
          presentationRequestTemplate={presentationRequestTemplate}
          hideSendButton={hideSendButton}
        />
      </TableCell>
    </TableRow>
  );
}
