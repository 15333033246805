// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useLinkClickHandler } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Add from "@mui/icons-material/Add";

// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from "../../../helpers/reducers/customers";
import { clearCredentialsCache, fetchCredentialsAsync, selectCredentials, selectCredentialsCount, selectCredentialsPage, selectCredentialsState } from "../../../helpers/reducers/adminCredentials";

import { CREDENTIAL_REQUEST_STATUS, CUSTOMER_STATUS, PAGE_ROUTES } from "../../../config/constants.config";

import CredentialTable from "../../../components/private/Tables/CredentialTable.component";

import "./AdminCredentials.view.css";
import CredentialFilters from "../../../components/private/Filters/CredentialFilters.component";

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AdminCredentials(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;
  const isRequest = props.isRequests;
  const dispatch = useDispatch();

  const credentials = useSelector(selectCredentials);
  const credentialsState = useSelector(selectCredentialsState);
  const credentialsCount = useSelector(selectCredentialsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(isRequest ? { status: CREDENTIAL_REQUEST_STATUS[0] } : null);
  const [page, setPage] = useState(useSelector(selectCredentialsPage));

  const isLoadingCredentials = credentialsState === 2;
  const credentialsNeedsReload = credentialsState === 1;
  
  const isLoadingUsers = customerState === 2;
  const usersNeedsReload = customerState === 1;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialsNeedsReload) {
    dispatch(fetchCredentialsAsync({
      isRequest,
      page,
      search,
      customerId: customer ? customer.did : undefined,
      status: status ? status.status : undefined
    }));
  }

  const clearFilters = () => {
    setSearch(null);
    setCustomer(null);
    setStatus(null);
    onFilterSubmit();
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCredentialsCache());
  }

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCredentialsCache());
  };

  return (
    <Grid container className="admin-credentials-view" direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" sx={{ color: "#1B5255", fontWeight: "bold", fontSize: "2rem" }}>
              {translate(`admin-credentials-page-title${isRequest ? "-request" : ""}`)}
            </Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={5}>
              <Grid item>
                <Button
                  sx={{
                    height: "2.938rem",
                    backgroundColor: "#1B5255",
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#1B5255",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                  onClick={useLinkClickHandler(isRequest ? PAGE_ROUTES.AdminCredentialRequestsCreate : PAGE_ROUTES.AdminCredentialsCreate)}
                  size="large">
                  <Add fontSize="large" />
                  <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }} paddingRight={1} paddingLeft={1}>
                    {translate("admin-credentials-page-create-button")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={isRequest}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          clearFilters={clearFilters}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        setErrorPopup={setErrorPopup}
        isLoading={isLoadingCredentials}
        credentials={credentials}
        credentialCount={credentialsCount}
        isCredentialRequests={isRequest}
        page={page}
        setPage={handleSetPage}
        hideLevelOfAssurance={isRequest}
        translatePrefix={isRequest ? "credential-request-table" : "credential-table"}
      />
    </Grid>
  );
}
