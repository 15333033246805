// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useLoaderData } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { useLinkClickHandler, Link, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import crypto from 'crypto-browserify';

// ------FILE MODULES---------------------------------------------------------
import { NETWORK_IDS, PAGE_ROUTES, PLATFORM_TYPE, TECHNOLOGIES, TRUST_FRAMEWORKS } from '../../../../config/constants.config';

import { clearEntityCredentialsCache, setEntityCredentialsIsRequest } from '../../../../helpers/reducers/entityCredentials';
import { fetchCatalogAsync, selectCatalogState, selectCatalog } from '../../../../helpers/reducers/catalog';
import { getResponseJson, isOk, post, get, customFilePost } from '../../../../helpers/axios.helper';
import { selectWhoAmI } from '../../../../helpers/reducers/corporate';
import { sign } from '../../../../helpers/jwt.helpers';

import SpinnerLoader from '../../../../components/shared/Loader/SpinnerLoader.component';
import WarningBox from '../../../../components/shared/DialogBoxes/WarningBox.component';
import DynamicForm from '../../../../components/shared/Forms/DynamicForm.component';
import Dropdown from '../../../../components/shared/Dropdowns/Dropdown.component';

import config from '../../../../config/config';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function onFormSubmit(form) {
  if (config.mocked) {
    const jwt = await sign(form);
    const signCredential = { jwt, status: 'OK' };

    config.mockedData.credentials.push(signCredential);
    return { status: 200 };
  }

  return await post('/dit/credentialRequest', form);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export async function createEntityCredentialRequestLoader({ request }) {
  if (config.mocked) {
    return config.mockedData.invitations;
  }
  const url = new URL(request.url);
  let catalogType = url.searchParams.get('id') || undefined;

  var urlForm = new URLSearchParams();
  urlForm.append('comms', PLATFORM_TYPE[0]);
  const response = await get('/customer', urlForm);
  let users = [];

  if (isOk(response.status)) {
    users = getResponseJson(response);
  }

  return { users, catalogType };
}

export default function CreateEntityCredentialRequest(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);
  const data = useLoaderData();
  const users = data.users;
  const whoAmI = useSelector(selectWhoAmI);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (catalogState === 1) {
    dispatch(fetchCatalogAsync({ limit: null }));
  }

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [kid, setKid] = useState('default');
  const [credential, setCredential] = useState(undefined);
  const [dynamicFormValue, setDynamicFormValue] = useState({});
  const [user, setUser] = useState(null);

  if (data.catalogType && credential === undefined && catalogState === 0) {
    setCredential(catalog.find((catalogEntry) => catalogEntry.type === data.catalogType) || null);
  }

  const isFormInvalid = () => {
    return !kid || !credential || !user || Object.keys(dynamicFormValue).length === 0;
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (isFormInvalid()) return;

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid,
      sub: user.did,
      credentialContext: 'https://www.w3.org/2018/credentials/examples/v1',
      credentialType: credential.type,
      file: ''
    };

    const dynamicFormKeys = Object.keys(dynamicFormValue);

    if (dynamicFormKeys.length > 0) {
      form.file = dynamicFormValue[dynamicFormKeys[0]];
    }

    setFormSendState(true);
    asyncFormSubmit(form);
  };

  const asyncFormSubmit = async (form) => {
    if (typeof form.file !== 'string') {
      let procHash = `0x0000000000000000000000000000000000000000000000000000000000000000`;

      const hash = crypto.createHash('sha256');
      hash.update(`${JSON.stringify(form)}${Date.now()}`);
      procHash = `0x${hash.digest().toString('hex')}`;

      const fileUpload = await customFilePost(
        `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/api`,
        form.file,
        procHash
      );

      if (!isOk(fileUpload.status)) {
        setFormSendState(false);
        setWarningBoxOpen(false);
        setErrorPopup(fileUpload);
        return;
      }

      form.file = `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/files/${fileUpload.uriSafeFileName}`;
    }

    const response = await onFormSubmit(form);
    if (isOk(response.status)) {
      dispatch(clearEntityCredentialsCache());
      dispatch(setEntityCredentialsIsRequest(true));
      navigate(-1);
    } else {
      setFormSendState(false);
      setWarningBoxOpen(false);
      setErrorPopup(response);
    }
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <Grid container className='create-entity-credential-view' direction='column' alignItems='stretch' spacing={2}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.EntityCredentials}>{translate('entity-credentials-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>
                  {translate('entity-credentials-create-page-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              sx={{
                height: '2.938rem',
                backgroundColor: '#1B5255',
                color: 'white',
                fontSize: '1.125rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1B5255',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                {translate('entity-credentials-create-back')}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <form onSubmit={validForm}>
          <Grid container justifyContent='space-evenly' alignItems='center'>
            <Grid item>
              <Grid container direction='column' spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('entity-credentials-create-credential-name')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('entity-credentials-create-credential-name-placeholder')}
                  value={credential}
                  required
                  disabled={data.catalogType !== undefined}
                  options={catalog}
                  getOptionLabel={(catalogItem) => catalogItem.name}
                  onChange={(catalogItem) => {
                    setCredential(catalogItem);
                    setDynamicFormValue({});
                  }}
                  selectStyle={{
                    opacity: data.catalogType !== undefined ? '0.5' : '1',
                    width: '50rem',
                    borderRadius: '10px',
                    fontSize: '1.125rem'
                  }}
                />

                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('entity-credentials-create-receiver')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('entity-credentials-create-receiver-placeholder')}
                  value={user}
                  required
                  options={users}
                  getOptionLabel={(user) => user.businessName}
                  onChange={(user) => {
                    setUser(user);
                  }}
                  selectStyle={{
                    width: '50rem',
                    borderRadius: '10px',
                    fontSize: '1.125rem'
                  }}
                />

                <DynamicForm
                  translate={translate}
                  formJson={credential ? credential.template || '{}' : '{}'}
                  format={credential ? credential.format : ''}
                  value={dynamicFormValue}
                  onChange={setDynamicFormValue}
                />

                <Grid item>
                  <Grid container justifyContent='flex-end' spacing={2}>
                    <Grid item>
                      <Button
                        type='submit'
                        sx={{
                          height: '2.938rem',
                          backgroundColor: '#1B5255',
                          color: 'white',
                          fontSize: '1.125rem',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#1B5255',
                            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                          }
                        }}>
                        <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                          {translate('entity-credentials-create-send')}
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <WarningBox
                        title={translate('entity-credentials-create-popup-title')}
                        open={warningBoxOpen}
                        onClose={() => {
                          if (formSendState) return;
                          setWarningBoxOpen(false);
                        }}
                        actions={
                          formSendState
                            ? []
                            : [
                                {
                                  label: translate('entity-credentials-create-popup-reject'),
                                  onClick: () => setWarningBoxOpen(false)
                                },
                                { label: translate('entity-credentials-create-popup-accept'), onClick: formSubmit }
                              ]
                        }>
                        <SpinnerLoader isLoading={formSendState} text={translate('entity-credentials-create-sending')}>
                          <Typography>{translate('entity-credentials-create-popup-body-kid')}</Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {whoAmI.businessName}
                          </Typography>
                        </SpinnerLoader>
                      </WarningBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
