// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid, Typography, Button, TextField } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

import multibase from 'multibase';

// ------FILE MODULES---------------------------------------------------------
import { isOk, getResponseJson, get } from '../../helpers/axios.helper';
import { PAGE_ROUTES } from '../../config/constants.config';

import blockchain from '../../helpers/blockchain.helper';

import DigitalisIcon from '../../components/shared/Icons/DigitalisIcon.component';
import SpinnerLoader from '../../components/shared/Loader/SpinnerLoader.component';

import config from '../../config/config';

import './Register.view.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
// Result from from /invitation/validate API is Invitation object. Same for /invitation/{invitationId}/vaildateMobile
// InvitationID must be gotten from /invitation by searching with invitation code, rather than /invitation/validate
// POST to /identity with code as json body
async function onFormSubmit(form, setInvitationId, navigate, setSendState) {
  const code = form.code;
  const invitationId = form.invitationId;
  const mobileCode = form.mobileCode;

  if (config.mocked) {
    if (!invitationId) {
      setInvitationId(12345);
    } else {
      navigate(PAGE_ROUTES.RegisterSuccess);
    }

    setSendState(false);
    return;
  }

  var urlForm = new URLSearchParams();
  urlForm.append('code', code);

  try {
    if (invitationId && mobileCode) {
      urlForm.append('mobileCode', mobileCode);

      const response = await get('/invitation/' + invitationId + '/validateMobile', urlForm);

      if (isOk(response.status)) {
        let cryptoKey = await window.crypto.subtle.generateKey(
          { name: 'ECDSA', namedCurve: 'P-256', hash: 'SHA-256' },
          true,
          ['sign']
        );
        let spki = await window.crypto.subtle.exportKey('spki', cryptoKey.publicKey);
        let pkcs8 = await window.crypto.subtle.exportKey('pkcs8', cryptoKey.privateKey);
        let pem = {
          public: spkiToPEM(spki),
          private: pkcs8ToPEM(pkcs8)
        };
        let raw = await window.crypto.subtle.exportKey('raw', cryptoKey.publicKey);
        const res = await blockchain.createDigitalIdentity(code, mobileCode, buf2hex(raw));

        if (isOk(res.status)) {
          configureKeys(res, pem);
          navigate(PAGE_ROUTES.RegisterSuccess);
          setSendState(false);
          return;
        }
        setSendState(false);
        return;
      }

      setSendState(false);
      return;
    }

    const response = await get('/invitation/validate', urlForm);

    if (isOk(response.status)) {
      var responseJson = getResponseJson(response);
      if (responseJson.length > 0) setInvitationId(responseJson[0].invitationId);
    }
  } catch (e) {
    setSendState(false);
    return;
  }
  setSendState(false);
}

async function configureKeys(res, pem) {
  const randomBytes = new Uint32Array(16);
  await window.crypto.getRandomValues(randomBytes);
  const didMethodSpecificIdentifier = multibase.encode('base58btc', randomBytes);
  sessionStorage.setItem(
    'keystore',
    JSON.stringify({
      alg: 'ECDSA',
      didAla: res.did,
      didVeia: '',
      didw3c: `did:key:${Buffer.from(didMethodSpecificIdentifier).toString('utf-8')}#key-0`,
      kid: `${res.did}#key-0`,
      kty: 'ECDSA',
      privateKey: pem.private,
      publicKey: pem.public,
      use: 'sig',
      ethKeystore: res.keystore,
      ethKeystorePassword: res.password
    })
  );
}

function pkcs8ToPEM(keydata) {
  var keydataS = arrayBufferToString(keydata);
  var keydataB64 = window.btoa(keydataS);
  var keydataB64Pem = formatAsPem(keydataB64, 'private');
  return keydataB64Pem;
}

function spkiToPEM(keydata) {
  var keydataS = arrayBufferToString(keydata);
  var keydataB64 = window.btoa(keydataS);
  var keydataB64Pem = formatAsPem(keydataB64);
  return keydataB64Pem;
}

function arrayBufferToString(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
}

function formatAsPem(str, type) {
  var finalString = type === 'private' ? '-----BEGIN PRIVATE KEY-----\n' : '-----BEGIN PUBLIC KEY-----\n';

  while (str.length > 0) {
    finalString += str.substring(0, 64) + '\n';
    str = str.substring(64);
  }

  finalString += type === 'private' ? '-----END PRIVATE KEY-----' : '-----END PUBLIC KEY-----';

  return finalString;
}

function buf2hex(buffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, '0')).join('');
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function Register(props) {
  const translate = props.translator;
  const navigate = useNavigate();

  const [formSendState, setFormSendState] = useState(false);
  const [invitationId, setInvitationId] = useState(null);
  const [invitationCode, setInvitationCode] = useState(null);
  const [mobileCode, setMobileCode] = useState(null);

  const invitationCodeChanged = (event) => {
    setInvitationCode(event.target.value);
  };

  const mobileCodeChanged = (event) => {
    setMobileCode(event.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (!invitationCode) return;

    if (invitationId && !mobileCode) return;

    onFormSubmit({ code: invitationCode, invitationId, mobileCode }, setInvitationId, navigate, setFormSendState);
    setFormSendState(true);
  };

  return (
    <div className='register-view'>
      <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Grid item md={8}>
          <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={5} padding={5}>
            <Grid item>
              <DigitalisIcon />
            </Grid>

            <Grid item md={5}>
              <SpinnerLoader
                isLoading={formSendState}
                text={translate(`sign-up-${invitationId ? 'creating' : 'sending'}`)}>
                <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                  <Grid item paddingTop={15}>
                    <Typography sx={{ fontSize: '1.25rem' }}>{translate('sign-up-title')}</Typography>
                  </Grid>

                  <Grid item paddingTop={5}>
                    <Typography sx={{ fontSize: '1.25rem' }}>{translate('sign-up-description')}</Typography>
                  </Grid>

                  <Grid item paddingTop={2}>
                    <form onSubmit={formSubmit}>
                      <Grid container direction='column' justifyContent='center' alignItems='stretch' spacing={2}>
                        <Grid item>
                          <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                            <Grid item>
                              <Typography sx={{ fontSize: '1.25rem' }}>
                                {translate('sign-up-invitation-code')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <TextField
                                fullWidth
                                required
                                InputProps={{
                                  readOnly: invitationId ? true : false
                                }}
                                value={invitationCode || ''}
                                onChange={invitationCodeChanged}
                                name='invitationCode'
                                id='outlined-required'
                                sx={{
                                  border: '2px solid #049294',
                                  borderRadius: '4px',
                                  backgroundColor: 'rgba(1, 42, 45, 1)',
                                  input: { color: 'white' }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item style={invitationId ? {} : { display: 'none' }}>
                          <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                            <Grid item>
                              <Typography>{translate('sign-up-invitation-mobile-code')}</Typography>
                            </Grid>
                            <Grid item>
                              <TextField
                                fullWidth
                                required={invitationId ? true : false}
                                value={mobileCode || ''}
                                onChange={mobileCodeChanged}
                                name='mobileCode'
                                id='outlined-required'
                                sx={{
                                  border: '2px solid #049294',
                                  borderRadius: '4px',
                                  backgroundColor: 'rgba(1, 42, 45, 1)',
                                  input: { color: 'white' }
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid container justifyContent='flex-end'>
                            <Button
                              type='submit'
                              sx={{
                                border: '3px solid #049294',
                                borderRadius: '4px',
                                color: 'white',
                                fontSize: '1.125rem'
                              }}>
                              <Typography paddingRight={5} paddingLeft={5}>
                                {translate('sign-up-button-' + (invitationId ? 'mobile-code' : 'code'))}
                              </Typography>
                              <ArrowForwardIos />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </SpinnerLoader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className='login-video' md={1.5}>
          <video autoPlay loop muted>
            <source src='/images/login.mp4' type='video/mp4' />
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
