// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useDispatch } from "react-redux";

import { useLinkClickHandler, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ------FILE MODULES---------------------------------------------------------
import { NETWORK_IDS, PAGE_ROUTES, PRESENTATION_REQUEST_CUSTOMER_STATUS, PRESENTATION_REQUEST_STATUS, TECHNOLOGIES, TRUST_FRAMEWORKS } from "../../../config/constants.config";
import { clearEntityPresentationsCache } from "../../../helpers/reducers/entityPresentations";
import { isOk, post } from "../../../helpers/axios.helper";

import GreenButton from "./GreenButton.component";
import RedButton from "./RedButton.component";

import LevelOfAssurance from "../../shared/Icons/LevelOfAssurance.component";
import MaxLengthText from "../../shared/Typography/MaxLengthText.component";
import SpinnerLoader from "../../shared/Loader/SpinnerLoader.component";
import WarningBox from "../../shared/DialogBoxes/WarningBox.component";
import Dropdown from "../../shared/Dropdowns/Dropdown.component";
import Hider from "../../shared/Hider/Hider.component";

import config from "../../../config/config";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {return string;};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_PRESENTATION_REQUEST = {storedJwt: {}};
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_DELEGATE_BUTTON = false;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const presentation = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideDelegateButton = props.hideDelegateButton || DEFAULT_HIDE_DELEGATE_BUTTON;
  
  const storedJwt = presentation.storedJwt;
  const jti = storedJwt.jti;
  const decoded = presentation.decoded;
  const presentationRequest = decoded.pr;
  const isDelegated = presentationRequest.type.includes(config.prConfig.delegatedType);
  const canBeRejected = storedJwt.status === PRESENTATION_REQUEST_STATUS[0];

  const defaultSelectionStatus = presentationRequest.presentations || {};

  const delegateButton = useLinkClickHandler(`${PAGE_ROUTES.EntityPresentationsSend}/${storedJwt.jti}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formSendState, setFormSendState] = useState(false);
  const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [selectionStatus, setSelectionStatus] = useState(defaultSelectionStatus);

  const onRejectOrDeleteClicked = async () => {
    const form = {
      ...storedJwt
    };
    let queryParams = new URLSearchParams();

    if (canBeRejected) {
      form.customers = [
        {
          customer: {
            did: decoded.customers[0].customer.did
          },
          customerStatus: PRESENTATION_REQUEST_CUSTOMER_STATUS[2]
        }
      ];

      const result = await post(`/dit/presentationRequest/${jti}`, form);
      if (!isOk(result.status)) {
        setRejectPopupOpen(false);
        setErrorPopup(result);
        setFormSendState(false);
        return;
      }

      form.status = PRESENTATION_REQUEST_STATUS[1];
    } else {
      form.isDeleted = true;
      queryParams.append('propagated', true);
    }

    if (!form.fileUri) {
      form.fileUri = '';
    }

    setFormSendState(true);

    const result = await post(`/dit/presentationRequest/${jti}?${queryParams}`, form);
    if (isOk(result.status)) {
      dispatch(clearEntityPresentationsCache());
      
      let navigationTarget = form.isDeleted ? PAGE_ROUTES.EntityPresentations : 0;
      navigate(navigationTarget);
    } else {
      setRejectPopupOpen(false);
      setErrorPopup(result);
    }

    setFormSendState(false);
  };

  const onDelegateButtonClicked = async (event) => {
    if (presentationRequest.hasCreatedPr) {
      delegateButton(event);
      return;
    }

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid: 'default',
      validFrom: presentation.decoded.nbf,
      validUntil: presentation.decoded.exp,
      presentationRequestContext: 'https://www.w3.org/2018/credentials/examples/v1',
      presentationRequestType: config.prConfig.formType,
      procHash: presentation.decoded.pr.procHash,
      procId: presentation.decoded.pr.procId,
      procDesc: presentation.decoded.pr.procDesc,
      procUrl: presentation.decoded.pr.procUrl,
      credentials: presentation.decoded.pr.data.map((credential) => {
        const didMethods = credential.didMethods[0].split(':');

        return {
          trustFramework: didMethods[1],
          networkTechnology: didMethods[2],
          networkId: didMethods[3],
          kid: 'default',
          validFrom: presentation.decoded.nbf,
          validUntil: presentation.decoded.exp,
          credentialContext: 'https://www.w3.org/2018/credentials/examples/v1',
          credentialType: credential.type,
          levelOfAssurance: credential.levelOfAssurance,
          file: credential.file
        };
      })
    };

    await post('/dit/presentationRequest', form);
    delegateButton(event);
  };

  const formSubmit = () => {
    const keys = Object.keys(selectionStatus);
    const signedTokens = keys
      .map((key) => selectionStatus[key])
      .filter((credential) => credential)
      .map((credential) => credential.storedJwt.signedToken);

    if (keys.length === 0 || signedTokens.length !== keys.length) return;

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid: 'default',
      sub: decoded.iss,
      cbu: decoded.cbu,
      validFrom: decoded.nbf,
      validUntil: decoded.exp,
      presentationContext: 'https://www.w3.org/2018/credentials/presentation/examples/v1',
      presentationRequestJti: jti,
      credentials: signedTokens
    };

    setFormSendState(true);
    asyncFormSubmit(form);
  };

  const asyncFormSubmit = async (form) => {
    const response = await post('/dit/presentation', form);

    if (isOk(response.status)) {
      dispatch(clearEntityPresentationsCache());
      navigate(PAGE_ROUTES.EntityPresentations);
    } else {
      setErrorPopup(response);
    }

    setFormSendState(false);
    setSignPopupOpen(false);
    setSelectionStatus(defaultSelectionStatus);
  };

  const NonDelegatedPopup = () => (
    <Grid container direction='column' alignItems='stretch' justifyContent='center' spacing={1}>
      <Grid item>
        <Typography>{translate('entity-presentations-popup-disclaimer')}</Typography>
      </Grid>

      {presentationRequest.data.map((credential) => {
        if (selectionStatus[credential.field_name] === undefined) {
          let modifiableStatus = { ...selectionStatus };
          modifiableStatus[credential.field_name] = credential.emitted.length === 0 ? '' : credential.emitted[0];
          setSelectionStatus(modifiableStatus);
        }

        return (
          <Grid item key={credential.name}>
            <Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
              <Grid item>
                <MaxLengthText text={credential.name} maxLength={30} typographyProps={{ sx: { fontSize: '1rem' } }} />
              </Grid>
              <Dropdown
                value={selectionStatus[credential.field_name]}
                options={credential.emitted}
                getOptionLabel={(emitted) => (
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography>
                        {emitted.decoded.vc.credentialSubject.name} -{' '}
                        {new Date(emitted.decoded.exp * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <LevelOfAssurance
                        translate={translate}
                        level={emitted.decoded.vc.credentialSubject.levelOfAssurance}
                      />
                    </Grid>
                  </Grid>
                )}
                onChange={(emitted) => {
                  let modifiableStatus = { ...selectionStatus };
                  modifiableStatus[credential.field_name] = emitted;
                  setSelectionStatus(modifiableStatus);
                }}
                selectStyle={{
                  width: '20rem',
                  height: '4rem',
                  borderRadius: '10px',
                  fontSize: '1.125rem'
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
  
  const DelegatedPopup = () => (
    <Grid container direction='column' alignItems='stretch' justifyContent='center' spacing={1}>
      <Grid item>
        <Typography>{translate('entity-presentations-popup-disclaimer')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{translate('entity-presentations-popup-delegated-text')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{decoded.issuerName.businessName}</Typography>
      </Grid>
      <Dropdown
        value={selectionStatus}
        multivalue
        options={presentationRequest.presentations}
        getOptionLabel={(presentation) => (
          <Typography>
            {presentation.decoded.issuer.businessName} - {presentation.decoded.issuer.did}
          </Typography>
        )}
        getOptionValue={(presentation) => presentation.storedJwt.signedToken}
        onChange={(presentations) => {
          setSelectionStatus(presentations);
        }}
        selectStyle={{
          width: '100%',
          height: '4rem',
          borderRadius: '10px',
          fontSize: '1.125rem'
        }}
      />
    </Grid>
  );

  const PopupComponent = isDelegated ? DelegatedPopup : NonDelegatedPopup;
  const disableSignButton = isDelegated ? (presentationRequest.presentations.length === 0) : (presentationRequest.data.filter((credential) => credential.emitted.length > 0).length !==
  presentationRequest.data.length)

  return (
    <Grid item>
      <WarningBox
        title={translate(`entity-presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-title`)}
        open={rejectPopupOpen}
        actions={
          formSendState
            ? []
            : [
                {
                  label: translate('entity-presentations-request-reject-popup-cancel'),
                  onClick: (event) => {
                    setRejectPopupOpen(false);
                  }
                },
                {
                  label: translate(`entity-presentations-request-popup${canBeRejected ? '-reject' : '-delete'}`),
                  onClick: (event) => {
                    onRejectOrDeleteClicked(event);
                  }
                }
              ]
        }>
        <SpinnerLoader
          isLoading={formSendState}
          text={translate(`entity-presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-sending`)}>
          <Typography>
            {translate(`entity-presentations-request${canBeRejected ? '-reject' : '-delete'}-popup-body`)}
          </Typography>
        </SpinnerLoader>
      </WarningBox>
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <Hider isHidden={hideSignButton || !canBeRejected || (isDelegated && !hideDelegateButton)}>
          <WarningBox
            open={signPopupOpen}
            title={translate('entity-presentations-popup-title')}
            maxWidth='50%'
            minWidth='50%'
            minHeight='30rem'
            actions={
              formSendState
                ? []
                : [
                    { onClick: () => setSignPopupOpen(false), label: translate('entity-presentations-popup-cancel') },
                    { onClick: formSubmit, label: translate('entity-presentations-popup-submit') }
                  ]
            }>
            <SpinnerLoader isLoading={formSendState} text={translate('entity-presentations-popup-sending')}>
              <PopupComponent />
            </SpinnerLoader>
          </WarningBox>
          <Grid item flexGrow={1}>
            <GreenButton
              text={translate('entity-presentations-card-sign')}
              onClick={() => setSignPopupOpen(true)}
              disabled={disableSignButton}
            />
          </Grid>
        </Hider>
        <Hider isHidden={hideDelegateButton || !isDelegated || !canBeRejected}>
          <Grid item flexGrow={1}>
            <GreenButton text={translate(`entity-presentations-card-send`)} onClick={onDelegateButtonClicked} />
          </Grid>
        </Hider>
        <Grid item flexGrow={1}>
          <RedButton
            text={translate(`entity-presentations-card-${canBeRejected ? 'reject' : 'delete'}`)}
            onClick={() => setRejectPopupOpen(true)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}