import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function CaixaIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 263.46, height: 53.23 }}>
      <path className="cls-1" d="M34.79,28.65c-.23.45-.45.84-.65,1.24a5.77,5.77,0,0,0-.34.81c-.31,1.1-.6,2.21-.92,3.31a4.83,4.83,0,0,1-.4,1.06c-1.59,3.07-3.15,6.17-4.83,9.19-1,1.79-2.14,3.49-3.29,5.18A14.12,14.12,0,0,1,20.65,53c-.68.48-1.21.18-1.32-.66-.15-1.28.1-2.56,0-3.83a23.08,23.08,0,0,1,.36-3.25,23.42,23.42,0,0,1,.94-4.77c.31-1.16.58-2.34.84-3.51.12-.56.15-1.15.28-1.72a1.65,1.65,0,0,1,.39-.82,1.83,1.83,0,0,0,.5-1.5,2.83,2.83,0,0,1,.18-.9c.65-2,1.32-3.95,2-5.93.2-.6.11-.69-.53-.7-1.18,0-2.38.07-3.56,0A61.63,61.63,0,0,1,12,24.32l-2.5-.54,0,0C8,24,6.8,23.19,5.49,23c-1.14-.19-2.24-.63-3.37-.9a3.2,3.2,0,0,1-1.8-.79c-.4-.44-.44-.5,0-1a8.12,8.12,0,0,1,3.06-2,28.19,28.19,0,0,1,7-1.66,44.54,44.54,0,0,1,10.45.05,18.71,18.71,0,0,1,3.88.55,2.23,2.23,0,0,0,.52,0c-.07-.19-.11-.34-.18-.49a2,2,0,0,0-.24-.33,44.85,44.85,0,0,1-4-6.34,6.39,6.39,0,0,1-1-3.46c0-.4-.27-.81.11-1.17.11-.1.25-.23.37-.23a8.26,8.26,0,0,1,1.93.08A42.76,42.76,0,0,1,26.3,6.55c2.64.94,4.85,2.65,7.16,4.18a6.49,6.49,0,0,1,1.2,1.2,1.29,1.29,0,0,0,2,.11,4.79,4.79,0,0,1,1.06-.71c.83-.43,1.69-.8,2.49-1.25A22.82,22.82,0,0,0,44,7.79a49.13,49.13,0,0,1,4.45-2.9,43.65,43.65,0,0,1,4.7-2.46c.69-.36,1.37-.72,2-1.12A7,7,0,0,0,56,.55c.11-.1.23-.26.36-.28A16.83,16.83,0,0,1,58.51,0c.46,0,.67.29.61.81A11.09,11.09,0,0,1,57,5.8c-.69,1-1.43,2-2.1,3-.41.63-.7,1.35-1.12,2-.61.91-1.25,1.81-1.95,2.66-1.42,1.72-2.9,3.41-4.34,5.12-.39.48-.38.7.08,1.14s.91.74,1.33,1.14a11.63,11.63,0,0,1,1.19,1.34A5.37,5.37,0,0,0,51.73,24a10.11,10.11,0,0,1,2.83,2.85c.64.85,1.31,1.69,1.89,2.58,1.32,2,2.58,4.06,3.87,6.09a2.46,2.46,0,0,1,.14.38,10.84,10.84,0,0,0,.63,1.58,60.26,60.26,0,0,1,4,8.23,22.69,22.69,0,0,0,1.06,2.3,1.08,1.08,0,0,1,0,1.07.51.51,0,0,1-.69.26,28.82,28.82,0,0,1-7.07-3.46c-2.23-1.46-4.39-3.05-6.52-4.65-2.39-1.8-4.71-3.69-7.07-5.51-1.15-.88-2.33-1.72-3.51-2.56-2-1.41-3.95-2.8-5.92-4.2C35.17,28.86,35,28.75,34.79,28.65Z" />
      <path className="cls-1" d="M179.5,37.85V37.1l.87-.19A1.91,1.91,0,0,0,182,35a6.46,6.46,0,0,0,0-1q0-12-.12-23.94c0-1.37-.35-1.81-1.62-2.2a.56.56,0,0,1-.29-.33,3.82,3.82,0,0,1,0-.69h.63c2.63,0,5.27.12,7.9,0a20.9,20.9,0,0,1,5.64.47A11.23,11.23,0,0,1,197.84,9a6.79,6.79,0,0,1,.48,11A11.37,11.37,0,0,1,195,22l-.59.22.6.22a10.5,10.5,0,0,1,4.33,2.42,7.4,7.4,0,0,1-1.86,11.84A14.48,14.48,0,0,1,190.78,38c-3.63,0-7.27-.1-10.9-.15A2.73,2.73,0,0,1,179.5,37.85Zm7.33-14.65c0,.15,0,.24,0,.32,0,3.58,0,7.17,0,10.75,0,.19,0,.38,0,.57a1.49,1.49,0,0,0,1.47,1.51,12.67,12.67,0,0,0,1.85.09,5.74,5.74,0,0,0,5.42-3.84,7.86,7.86,0,0,0,.35-3.16,6.44,6.44,0,0,0-4.81-6.08A15.5,15.5,0,0,0,186.83,23.2Zm0-1.94c.65,0,1.26.09,1.88.09a10,10,0,0,0,1.34-.1,5.84,5.84,0,0,0,5.2-4.62,8.63,8.63,0,0,0-.12-4,4.81,4.81,0,0,0-3.73-3.75,16,16,0,0,0-3.32-.32c-.93,0-1.07.19-1.09,1.11-.05,2.72-.11,5.44-.16,8.16C186.84,18.93,186.86,20.06,186.86,21.26Z" />
      <path className="cls-1" d="M106.89,31.33c1.4.48,1.4.48,1,1.86-.31,1.12-.64,2.25-.94,3.38a.56.56,0,0,1-.5.48A43.3,43.3,0,0,1,97,38a22.31,22.31,0,0,1-10.92-2.89,15.28,15.28,0,0,1-7.64-11.73A15.8,15.8,0,0,1,82,10.89,16.22,16.22,0,0,1,91.7,5.34a29.53,29.53,0,0,1,13.5.85c.6.17,1.2.31,1.79.5a.57.57,0,0,1,.31.37c.14,1.91.25,3.81.37,5.8-.61,0-1.19,0-1.77,0-.08,0-.19-.13-.23-.22-1.25-3.24-3.82-4.81-7-5.49a12.32,12.32,0,0,0-6.83.4,9.42,9.42,0,0,0-5.68,5.6A20,20,0,0,0,86,28.36c1.68,4.22,5,6.61,9.4,7.48a11,11,0,0,0,8.38-1.46A10.38,10.38,0,0,0,105.47,33C106,32.51,106.4,31.91,106.89,31.33Z" />
      <path className="cls-1" d="M263.45,47.31H79V46H263.45Z" />
      <path className="cls-1" d="M260.73,19.68c.11.76.08.7-.57,1a13.48,13.48,0,0,0-2.8,1.72c-1.47,1.32-2.79,2.81-4.19,4.25l2,2.4c1.52,1.78,3,3.57,4.57,5.35A9.28,9.28,0,0,0,263.16,37c.5.21.2.61.28.94H255c0-.36-.18-.75.36-1a.91.91,0,0,0,.27-.84,4.2,4.2,0,0,0-.75-1.51c-1.64-1.92-3.35-3.79-5.11-5.77a4.55,4.55,0,0,0-.05.5c0,1.9,0,3.79,0,5.69a1.86,1.86,0,0,0,1.31,2.09c.16.06.2.52.32.85h-7.61v-.79c1.43-.54,1.65-.86,1.65-2.4,0-5,0-9.93,0-14.9,0-2.46-.21-4.92-.41-7.38a2.45,2.45,0,0,0-2.14-2.43V9.13c1-.37,2-.69,2.94-1.11a22.31,22.31,0,0,0,2.41-1.29,1.68,1.68,0,0,1,1.54-.34v21l.48-.44c1.07-1.06,2.16-2.09,3.19-3.18a6.76,6.76,0,0,0,1-1.46,1.1,1.1,0,0,0-.28-1.46,1.08,1.08,0,0,1-.36-1.14Z" />
      <path className="cls-1" d="M223.07,38a.73.73,0,0,1,.56-1.07,1.34,1.34,0,0,0,1-1.4c0-2.8,0-5.59-.08-8.38,0-.88-.07-1.75-.11-2.63-.07-1.68-.32-2-1.92-2.36-.12-.82-.12-.82.65-1A15.91,15.91,0,0,0,228,19.36a6,6,0,0,1,1.28-.38v2.29l.5-.3a11,11,0,0,1,4-1.71,6.85,6.85,0,0,1,4.27.64,5.16,5.16,0,0,1,2.69,4.44c.12,1.32.09,2.65.1,4,0,2.32,0,4.65,0,7,0,1.07.25,1.39,1.24,1.77.4.15.41.17.3.92H235c-.19-.68-.16-.76.49-1a1.28,1.28,0,0,0,1-1.18,7.74,7.74,0,0,0,.07-.83c0-2.63,0-5.27,0-7.91a11.46,11.46,0,0,0-.39-2.8,3.18,3.18,0,0,0-3.93-2.61,8.6,8.6,0,0,0-2.19.7,1.57,1.57,0,0,0-.9,1.57c0,3.58-.06,7.17-.08,10.75a8.61,8.61,0,0,0,.08,1.24,1.23,1.23,0,0,0,1,1.1c.69.19.69.2.57.93Z" />
      <path className="cls-1" d="M170.82,26.86a8.2,8.2,0,0,0-.43-3.4A2.58,2.58,0,0,0,168,21.78a4.69,4.69,0,0,0-4.6,2.09c-.33.5-.8.19-1.27.28,0-1.39,0-2.73,0-4.07,0-.09.14-.23.24-.26a17.38,17.38,0,0,1,9.38-.22,4.54,4.54,0,0,1,3.49,4.68c0,2.69-.12,5.37-.15,8.06a10.25,10.25,0,0,0,.16,2,1.06,1.06,0,0,0,1.43.95,1,1,0,0,1,1.32.56l-1,.81c-.66.52-1.33,1-2,1.58a.82.82,0,0,1-.85.18,4.49,4.49,0,0,1-3.06-2.06c-.75.49-1.46,1-2.2,1.41a6.4,6.4,0,0,1-7.4-1.09,5.47,5.47,0,0,1-.08-7.14,7.7,7.7,0,0,1,5-2.57,32.32,32.32,0,0,1,3.55-.15C170.23,26.82,170.49,26.84,170.82,26.86Zm.16,5h-.13c0-1,0-2,0-3,0-.25,0-.44-.35-.46a19.36,19.36,0,0,0-2.52-.09,3.5,3.5,0,0,0-3.26,2.46,3.75,3.75,0,0,0,1,4.13,4.52,4.52,0,0,0,5.12.24.76.76,0,0,0,.17-.49C171,33.73,171,32.8,171,31.87Z" />
      <path className="cls-1" d="M112.87,24.14c0-1.38,0-2.72,0-4.06,0-.09.14-.23.24-.26a17.77,17.77,0,0,1,9-.32,4.68,4.68,0,0,1,3.83,5c0,1.79-.11,3.58-.13,5.37,0,1.32,0,2.65,0,4s.61,1.63,1.84,1.37c.43-.08.44-.08,1,.61-.91.73-1.84,1.44-2.72,2.21a1.35,1.35,0,0,1-1.44.29,4.39,4.39,0,0,1-2.74-2l-1.64,1.11a6.09,6.09,0,0,1-5.58.68,5.31,5.31,0,0,1-2-9,8.52,8.52,0,0,1,5.21-2.24c1-.1,2,0,2.94,0h.81a8,8,0,0,0-.49-3.49,2.59,2.59,0,0,0-2.33-1.55,4.7,4.7,0,0,0-4.6,2.08C113.81,24.36,113.35,24.07,112.87,24.14Zm8.8,4.25a24.87,24.87,0,0,0-3-.07,3.48,3.48,0,0,0-3.26,2.45,3.75,3.75,0,0,0,1,4.17,4.57,4.57,0,0,0,5.08.21.6.6,0,0,0,.16-.44c0-.73,0-1.45,0-2.17Z" />
      <path className="cls-1" d="M221.72,35.85c-1.06.85-2.12,1.71-3.19,2.54a.64.64,0,0,1-.48,0,4.43,4.43,0,0,1-3.22-2.09c-.55.37-1.08.74-1.62,1.09a6.06,6.06,0,0,1-5.43.74c-5.1-1.61-4.55-7-2.08-9.18A9,9,0,0,1,211,26.86a26.62,26.62,0,0,1,2.68,0c.29,0,.58,0,.91,0a8,8,0,0,0-.53-3.55,2.62,2.62,0,0,0-2.42-1.49,4.62,4.62,0,0,0-4.42,2c-.35.53-.82.26-1.32.33V21.09c0-1.21,0-1.22,1.17-1.47a18.32,18.32,0,0,1,7.59-.22c3,.61,4.42,2.41,4.36,5.47,0,2.59-.12,5.17-.16,7.76a9.06,9.06,0,0,0,.12,1.64,1.14,1.14,0,0,0,1.48,1C221.19,35.2,221.19,35.2,221.72,35.85Zm-7-7.32a8.3,8.3,0,0,0-3.49-.11A3.43,3.43,0,0,0,208.39,31a3.75,3.75,0,0,0,1.06,4,4.55,4.55,0,0,0,5.08.2.57.57,0,0,0,.15-.39C214.69,32.7,214.69,30.64,214.69,28.53Z" />
      <path className="cls-1" d="M158.76,19.7v.62c-.82.51-1.62.94-2.33,1.48a7.31,7.31,0,0,0-1.21,1.33c-1.22,1.52-2.41,3.07-3.63,4.58a.51.51,0,0,0,0,.75c1.5,2.06,3,4.14,4.48,6.21a7.6,7.6,0,0,0,2.68,2.45c.19.09.2.54.3.87h-8c-.05-.36-.23-.73.33-1a.86.86,0,0,0,.39-1.23,7.72,7.72,0,0,0-.62-1.13c-.77-1.14-1.57-2.25-2.4-3.44-.88,1.22-1.74,2.36-2.54,3.54a3.27,3.27,0,0,0-.44,1.15c-.15.65,0,.94.64,1.21.3.13.32.22.2.88h-6.33c-.16-.73-.16-.74.52-1a4.28,4.28,0,0,0,1.93-1.42c1.54-1.93,3.05-3.88,4.58-5.81a.4.4,0,0,0,0-.58l-5-6.82a4.38,4.38,0,0,0-1.8-1.59.78.78,0,0,1-.52-1.1h8c0,.42.17.85-.43,1.06a.71.71,0,0,0-.45.85,3.25,3.25,0,0,0,.39,1.16c.82,1.19,1.69,2.34,2.62,3.62.91-1.31,1.82-2.53,2.63-3.81a1.17,1.17,0,0,0-.51-1.79c-.57-.25-.6-.61-.51-1.09Z" />
      <path className="cls-1" d="M11.71,52A7.47,7.47,0,0,1,6.35,48.7a1,1,0,0,1-.21-.52c-.09-1.25-.23-2.5-.2-3.75a6.32,6.32,0,0,1,.5-2.4c.35-.75,1.06-1.32,1.48-2a5.61,5.61,0,0,1,2.67-2,3,3,0,0,1,.68-.18l.7-.13a1.21,1.21,0,0,1,.5,0,6.46,6.46,0,0,0,1.23.08,2.66,2.66,0,0,1,2,.85,8.39,8.39,0,0,1,1.64,3.64c.14.51.2,1,.29,1.56A8.52,8.52,0,0,1,16.68,49a1.87,1.87,0,0,1-1.34,1.23.93.93,0,0,0-.51.41C14.09,51.85,12.92,51.93,11.71,52Z" />
      <path className="cls-1" d="M130.39,38c-.12-.51-.09-.88.55-1a1.11,1.11,0,0,0,.89-1,4.39,4.39,0,0,0,.07-.72c0-2.86,0-5.72-.1-8.58a15.1,15.1,0,0,0-.3-2.2,2.25,2.25,0,0,0-2.06-2.17v-.89c.59-.18,1.22-.31,1.8-.54,1.24-.5,2.46-1.06,3.7-1.56a7.37,7.37,0,0,1,1.07-.24,4.85,4.85,0,0,1,.05.54q.1,7.71.2,15.41a4.77,4.77,0,0,0,.08.92A1.42,1.42,0,0,0,137.41,37c.66.23.66.24.53,1Z" />
      <path className="cls-1" d="M11.45,29.9A5.44,5.44,0,0,1,12.14,28a3,3,0,0,1,2-1.15c.73-.08,1.47-.08,2.2-.15a1.83,1.83,0,0,1,1.85,1.19A5,5,0,0,1,19,30.37c0,.12.07.27,0,.35a6.14,6.14,0,0,1-1.39,1.88,4.84,4.84,0,0,1-4,.54A3,3,0,0,1,11.45,29.9Z" />
      <path className="cls-1" d="M133.89,16.12a2.36,2.36,0,0,1-2.48-2.24,2.47,2.47,0,0,1,4.93,0A2.35,2.35,0,0,1,133.89,16.12Z" />
    </SvgIcon>
  )
}