// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useDispatch } from "react-redux";

import { useLinkClickHandler, Link, useNavigate } from "react-router-dom";

import { Grid, Button, Typography, TextField } from "@mui/material";

import { ArrowForwardIos } from "@mui/icons-material";

// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS, PAGE_ROUTES } from "../../../../config/constants.config";
import { clearCustomerCache } from "../../../../helpers/reducers/customers";
import { isOk, post } from "../../../../helpers/axios.helper";

import WarningBox from "../../../../components/shared/DialogBoxes/WarningBox.component";
import SpinnerLoader from "../../../../components/shared/Loader/SpinnerLoader.component";

import config from "../../../../config/config";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function onFormSubmit(form) {
  if (config.mocked) {
    const mockedResponse = { status: CUSTOMER_STATUS[1], ...form };
    config.mockedData.invitations.push(mockedResponse);
    return { code: 200 };
  }

  return await post("/invitation", form);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreateInvitation(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [prefix, setPrefix] = useState(null);

  const emailChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setEmail(value);
    }
  };

  const businessNameChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 70) {
      setBusinessName(value);
    }
  };

  const prefixChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 3) {
      setPrefix(value);
    }
  };

  const phoneChanged = (event) => {
    setPhone(event.target.value.replace(" ", ""));
  };

  const isFormInvalid = () => {
    return !email || !businessName || !prefix || !phone;
  }

  const formSubmit = async (event) => {
    event.preventDefault();

    if (isFormInvalid()) {
      return;
    }

    setFormSendState(true);
    const response = await onFormSubmit({ email, businessName, tel: `+${prefix}${phone}` });

    if (isOk(response.status)) {
      dispatch(clearCustomerCache());
      navigate(-1);
    } else {
      setErrorPopup(response);
    }
    setFormSendState(false);
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <Grid container className="create-admin-invitation-view" direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: "#1B5255",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textDecoration: "none",
                    "a:visited": {
                      textDecoration: "none",
                      color: "#1B5255",
                    },
                    "a:link": { textDecoration: "none" },
                    "a:hover": { textDecoration: "none" },
                    "a:active": { textDecoration: "none" },
                  }}>
                  <Link to={PAGE_ROUTES.AdminEntityContacts}>{translate("entities-page-title")}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1B5255", fontSize: "2rem" }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1B5255", fontSize: "2rem" }}>{translate("invitations-create-page-title")}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              sx={{
                height: "2.938rem",
                backgroundColor: "#1B5255",
                color: "white",
                fontSize: "1.375rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#1B5255",
                  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant="h5" paddingRight={5} paddingLeft={5}>
                {translate("invitations-create-back")}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container justifyContent="center" alignItems="center" paddingTop={5} spacing={3}>
          <Grid item md={6}>
            <form onSubmit={validForm}>
              <Grid container direction="column" alignItems="stretch" spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("invitations-create-email")}</Typography>
                </Grid>

                <Grid item>
                  <TextField
                    required
                    type="email"
                    fullWidth
                    id="outlined-required"
                    onChange={emailChanged}
                    value={email || ""}
                    name="email"
                    placeholder={translate("invitations-create-email-placeholder")}
                    inputProps={{
                      style: {
                        fontSize: "1.125rem",
                      },
                      maxLength: 50,
                    }}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.38)",
                      border: "3px solid rgba(4, 146, 148, 1)",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      fontSize: "1.125rem",
                    }}
                  />
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("invitations-create-employee-name")}</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    required
                    type="text"
                    fullWidth
                    id="outlined-required"
                    onChange={businessNameChanged}
                    value={businessName || ""}
                    name="businessName"
                    placeholder={translate("invitations-create-company-name-placeholder")}
                    inputProps={{
                      style: {
                        fontSize: "1.125rem",
                      },
                    }}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.38)",
                      border: "3px solid rgba(4, 146, 148, 1)",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      fontSize: "1.125rem",
                    }}
                  />
                </Grid>

                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item flexGrow={0.1}>
                      <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("invitations-create-phone-number-prefix")}</Typography>
                      <TextField
                        required
                        type="number"
                        fullWidth
                        id="outlined-required"
                        onChange={prefixChanged}
                        value={prefix || ""}
                        placeholder={translate("invitations-create-phone-number-prefix-placeholder")}
                        name="prefix"
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.38)",
                          border: "3px solid rgba(4, 146, 148, 1)",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          fontSize: "1.125rem",
                          width:'6.25rem'
                        }}
                        inputProps={{
                          maxlength: 3,
                        }}
                      />
                    </Grid>
                    <Grid item flexGrow={10}>
                      <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("invitations-create-phone-number")}</Typography>
                      <TextField
                        required
                        fullWidth
                        type="number"
                        id="outlined-required"
                        onChange={phoneChanged}
                        value={phone || ""}
                        placeholder={translate("invitations-create-phone-number-placeholder")}
                        name="phone"
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.38)",
                          border: "3px solid rgba(4, 146, 148, 1)",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          fontSize: "1.125rem",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container justifyContent="flex-end" paddingTop={2}>
                    <Grid item>
                      <Button
                        sx={{
                          height: "2.938rem",
                          backgroundColor: "#1B5255",
                          color: "white",
                          fontSize: "22px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#1B5255",
                            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                          },
                        }}
                        type="submit">
                        <Typography variant="h5" paddingRight={5} paddingLeft={5}>
                          {translate("invitations-create-send")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <WarningBox
                  title={translate(`invitations-create-popup-title`)}
                  open={warningBoxOpen}
                  onClose={() => {
                    if (formSendState) return;
                    setWarningBoxOpen(false);
                  }}
                  actions={
                    formSendState
                      ? []
                      : [
                          {
                            label: translate(`invitations-create-popup-reject`),
                            onClick: (event) => {
                              setWarningBoxOpen(false);
                            }
                          },
                          {
                            label: translate(`invitations-create-popup-accept`),
                            onClick: (event) => {
                              formSubmit(event);
                            }
                          }
                        ]
                  }>
                  <SpinnerLoader isLoading={formSendState} text={translate(`invitations-create-popup-sending`)}>
                    <Typography>{translate(`invitations-create-popup-body`)}</Typography>
                  </SpinnerLoader>
                </WarningBox>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
