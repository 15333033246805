// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useLinkClickHandler } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AccessTime from "@mui/icons-material/AccessTime";
import Add from "@mui/icons-material/Add";
import Check from "@mui/icons-material/Check";

// ------FILE MODULES---------------------------------------------------------
import { isOk, post } from "../../../helpers/axios.helper";
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from "../../../helpers/reducers/customers";
import { clearEntityCredentialsCache, fetchEntityCredentialsAsync, selectEntityCredentials, selectEntityCredentialsCount, selectEntityCredentialsIsRequest, selectEntityCredentialsPage, selectEntityCredentialsState, setEntityCredentialsIsRequest } from "../../../helpers/reducers/entityCredentials";

import { CREDENTIAL_REQUEST_STATUS, CREDENTIAL_STATUS, CUSTOMER_STATUS, PAGE_ROUTES } from "../../../config/constants.config";

import CredentialTable from "../../../components/private/Tables/CredentialTable.component";
import SpinnerLoader from "../../../components/shared/Loader/SpinnerLoader.component";
import RedButton from "../../../components/private/Buttons/RedButton.component";

import WarningBox from "../../../components/shared/DialogBoxes/WarningBox.component";

import "./EntityCredentials.view.css";
import CredentialFilters from "../../../components/private/Filters/CredentialFilters.component";

// ---------------------------------------------------------------------------
// PRIVATE COMPONENTS
// ---------------------------------------------------------------------------
function StateButtons(props) {
  const translate = props.translate;
  const credential = props.credential;
  const setErrorPopup = props.setErrorPopup;
  const storedJwt = props.storedJwt;
  const isRequest = props.isRequest;

  const status = credential.status;
  const canBeUserRevoked = status === CREDENTIAL_STATUS[0] || status === CREDENTIAL_STATUS[1] || status === CREDENTIAL_REQUEST_STATUS[0];

  const dispatch = useDispatch();

  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [formSendState, setFormSendState] = useState(false);

  const onRevokeOrDeleteClicked = async () => {
    const endpoint = isRequest ? "/dit/credentialRequest" : "/dit/credential";
    let form = {
      ...storedJwt,
    };

    let queryParams = new URLSearchParams();

    if (isRequest) {
      if (canBeUserRevoked) {
        form.status = CREDENTIAL_REQUEST_STATUS[1];
      } else {
        form.isDeleted = true;
        queryParams.append("propagated", true);
      }
    } else {
      if (canBeUserRevoked) {
        form.status = CREDENTIAL_STATUS[4];
      } else {
        form.isDeleted = true;
        queryParams.append("propagated", true);
      }
    }

    if (!form.fileUri) {
      form.fileUri = "";
    }

    setFormSendState(true);

    const result = await post(`${endpoint}/${storedJwt.jti}?${queryParams}`, form);
    if (isOk(result.status)) {
      dispatch(clearEntityCredentialsCache());
    } else {
      setWarningBoxOpen(false);
      setErrorPopup(result);
    }

    setFormSendState(false);
  };

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <WarningBox
        title={translate(`entity-credentials${canBeUserRevoked ? "-revoke" : "-delete"}-popup-title${isRequest ? "-request" : "-emitted"}`)}
        open={warningBoxOpen}
        actions={
          formSendState
            ? []
            : [
                {
                  label: translate(`entity-credentials-popup-reject${canBeUserRevoked ? "-revoke" : "-delete"}${isRequest ? "-request" : "-emitted"}`),
                  onClick: (event) => {
                    setWarningBoxOpen(false);
                  },
                },
                {
                  label: translate(`entity-credentials-popup-confirm${canBeUserRevoked ? "-revoke" : "-delete"}${isRequest ? "-request" : "-emitted"}`),
                  onClick: (event) => {
                    onRevokeOrDeleteClicked(event);
                  },
                },
              ]
        }>
        <SpinnerLoader
          isLoading={formSendState}
          text={translate(`entity-credentials${canBeUserRevoked ? "-revoking" : "-deleting"}${isRequest ? "-request" : "-emitted"}`)}>
          <Typography>{translate(`entity-credentials${canBeUserRevoked ? "-revoke" : "-delete"}-popup-body${isRequest ? "-request" : "-emitted"}`)}</Typography>
        </SpinnerLoader>
      </WarningBox>

      <Grid item sx={{ flexGrow: 1 }}>
        <RedButton
          text={translate(`entity-credentials-table${canBeUserRevoked ? "-revoke" : "-delete"}-button${isRequest ? "-request" : "-emitted"}`)}
          onClick={() => setWarningBoxOpen(true)}
        />
      </Grid>
    </Grid>
  );
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityCredentials(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const dispatch = useDispatch();

  const credentials = useSelector(selectEntityCredentials);
  const credentialsState = useSelector(selectEntityCredentialsState);
  const credentialsCount = useSelector(selectEntityCredentialsCount);
  const isRequest = useSelector(selectEntityCredentialsIsRequest);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(isRequest ? { status: CREDENTIAL_REQUEST_STATUS[0] } : null);
  const [page, setPage] = useState(useSelector(selectEntityCredentialsPage));

  const isLoadingUsers = customerState === 2;
  const usersNeedsReload = customerState === 1;

  const isCredentialsLoading = credentialsState === 2;
  const credentialsNeedsReload = credentialsState === 1;
  
  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialsNeedsReload) {
    dispatch(fetchEntityCredentialsAsync({
      isRequest,
      page,
      search,
      customer: customer ? customer.did : undefined,
      status: status ? status.status : undefined
    }));
  }

  const clearFilters = (event, newIsRequest) => {
    setSearch(null);
    setCustomer(null);
    setStatus(newIsRequest ? { status: CREDENTIAL_REQUEST_STATUS[0] } : null);
    onFilterSubmit();
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearEntityCredentialsCache());
  }

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearEntityCredentialsCache());
  };

  const changeIsRequest = (isRequest) => {
    dispatch(setEntityCredentialsIsRequest(isRequest));
    clearFilters(null, isRequest);
  };

  return (
    <Grid container className="entity-credentials-view" direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" sx={{ color: "#1B5255", fontWeight: "bold", fontSize: "2rem" }}>
              {translate("entity-credentials-page-title")}
            </Typography>
          </Grid>

          <Grid item md={4}>
            <Button
              sx={{
                backgroundColor: "#00000000",
                color: "#1B5255",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "0%",
                borderBottom: "1px solid rgba(1, 1, 1, 1)",
                "&:hover": {
                  boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              }}
              onClick={() => {
                changeIsRequest(true);
              }}>
              <AccessTime sx={{ fontSize: "1.5rem", fontWeight: isRequest ? "bold" : "" }} />
              <Typography sx={{ fontSize: "1.125rem", fontWeight: isRequest ? "bold" : "" }} paddingRight={1} paddingLeft={1}>
                {translate("entity-credentials-switch-requests")}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: "#00000000",
                color: "#1B5255",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "0%",
                borderBottom: "1px solid rgba(1, 1, 1, 1)",
                "&:hover": {
                  boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              }}
              onClick={() => {
                changeIsRequest(false);
              }}>
              <Check sx={{ fontSize: "1.5rem", fontWeight: isRequest ? "" : "bold" }} />
              <Typography sx={{ fontSize: "1.125rem", fontWeight: isRequest ? "" : "bold" }} paddingRight={1} paddingLeft={1}>
                {translate("entity-credentials-switch-credentials")}
              </Typography>
            </Button>
          </Grid>

          <Grid item>
            <Grid container spacing={5}>
              <Grid item>
                <Button
                  sx={{
                    height: "2.938rem",
                    backgroundColor: "#1B5255",
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#1B5255",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                  onClick={useLinkClickHandler(PAGE_ROUTES.EntityCredentialsCreate)}
                  size="large">
                  <Add fontSize="large" />
                  <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }} paddingRight={1} paddingLeft={1}>
                    {translate("entity-credentials-page-create-button")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={isRequest}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          clearFilters={clearFilters}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        setErrorPopup={setErrorPopup}
        isLoading={isCredentialsLoading}
        credentials={credentials}
        credentialCount={credentialsCount}
        isCredentialRequests={isRequest}
        page={page}
        setPage={handleSetPage}
        hideLevelOfAssurance={isRequest}
        translatePrefix={isRequest ? "credential-request-table" : "credential-table"}
        actionButtonsComponent={StateButtons}
      />
    </Grid>
  );
}
