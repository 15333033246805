// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AccessTime from '@mui/icons-material/AccessTime';
import Check from '@mui/icons-material/Check';

// ------FILE MODULES---------------------------------------------------------
import { clearEntityPresentationsCache, fetchEntityPresentationsAsync, selectEntityPresentations, selectEntityPresentationsCount, selectEntityPresentationsIsRequest, selectEntityPresentationsPage, selectEntityPresentationsState, setEntityPresentationsIsRequest } from '../../../helpers/reducers/entityPresentations';
import { PAGE_ROUTES } from '../../../config/constants.config';

import PresentationRequestButtons from '../../../components/private/Buttons/PresentationRequestButtons.component';
import PresentationRequestTable from '../../../components/private/Tables/PresentationRequestTable.component';
import PresentationButtons from '../../../components/private/Buttons/PresentationButtons.component';

import SearchBar from '../../../components/shared/SearchBars/SearchBar.component';
import DatePicker from '../../../components/shared/DatePicker/DatePicker.component';
import Hider from '../../../components/shared/Hider/Hider.component';

import './EntityPresentations.view.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityPresentations(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const dispatch = useDispatch();

  const [page, setPage] = useState(useSelector(selectEntityPresentationsPage));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState(null);

  const presentations = useSelector(selectEntityPresentations);
  const presentationState = useSelector(selectEntityPresentationsState);
  const presentationCount = useSelector(selectEntityPresentationsCount);
  const isRequest = useSelector(selectEntityPresentationsIsRequest);

  const isLoading = presentationState === 2;
  const needsReload = presentationState === 1;

  if (needsReload) {
    dispatch(
      fetchEntityPresentationsAsync({
        isRequest,
        page,
        startDate: Math.round(startDate / 1000),
        endDate: Math.round(endDate / 1000),
        search
      })
    );
  }

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSearch(null);
    setPage(0);
    dispatch(clearEntityPresentationsCache());
  };

  const changeEntityPresentationsIsRequest = (isRequest) => {
    dispatch(setEntityPresentationsIsRequest(isRequest));
    clearFilters();
  };

  return (
    <Grid container className='entity-presentations-view' direction='column' alignItems='stretch' spacing={3}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item flexGrow={1}>
            <Typography variant='h5' sx={{ color: '#1B5255', fontWeight: 'bold', fontSize: '2rem' }}>
              {translate('entity-presentations-page-title')}
            </Typography>
          </Grid>
          <Grid item flexGrow={1}>
            <Button
              sx={{
                backgroundColor: '#00000000',
                color: '#1B5255',
                textTransform: 'none',
                fontWeight: 'bold',
                borderRadius: '0%',
                borderBottom: '1px solid rgba(1, 1, 1, 1)',
                '&:hover': {
                  boxShadow: '1px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={() => {
                changeEntityPresentationsIsRequest(true);
              }}>
              <AccessTime sx={{ fontSize: '1.5rem', fontWeight: isRequest ? 'bold' : '' }} />
              <Typography
                sx={{ fontSize: '1.125rem', fontWeight: isRequest ? 'bold' : '' }}
                paddingRight={1}
                paddingLeft={1}>
                {translate('entity-presentations-switch-requests')}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#00000000',
                color: '#1B5255',
                textTransform: 'none',
                fontWeight: 'bold',
                borderRadius: '0%',
                borderBottom: '1px solid rgba(1, 1, 1, 1)',
                '&:hover': {
                  boxShadow: '1px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={() => {
                changeEntityPresentationsIsRequest(false);
              }}>
              <Check sx={{ fontSize: '1.5rem', fontWeight: isRequest ? 'bold' : '' }} />
              <Typography
                sx={{ fontSize: '1.125rem', fontWeight: isRequest ? '' : 'bold' }}
                paddingRight={1}
                paddingLeft={1}>
                {translate('entity-presentations-switch-presentations')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          className='entityPresentationsFilterContainer'>
          <Grid item paddingLeft={1} paddingRight={4}>
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
              {translate('entity-presentations-page-filter')}
            </Typography>
          </Grid>
          <Hider isHidden={!isRequest}>
            <Grid item paddingTop={3}>
              <SearchBar
                translate={translate}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(0);
                  dispatch(clearEntityPresentationsCache());
                }}
                onSubmit={() => {
                  setPage(0);
                  dispatch(clearEntityPresentationsCache());
                }}
              />
            </Grid>
          </Hider>

          <Grid item paddingTop={1} paddingBottom={1}>
            <Typography paddingLeft={1.5}>{translate('presentations-page-filter-start-date')}</Typography>
            <DatePicker
              required
              max={endDate}
              value={startDate}
              onChange={(unixTime) => {
                setStartDate(unixTime);
                setPage(0);
                dispatch(clearEntityPresentationsCache());
              }}
              textFieldStyle={{
                width: '16.563rem',
                height: '2.938rem',
                borderRadius: '30px'
              }}
            />
          </Grid>
          <Grid item paddingTop={1} paddingBottom={1}>
            <Typography paddingLeft={1.5}>{translate('presentations-page-filter-end-date')}</Typography>
            <DatePicker
              required
              min={startDate}
              value={endDate}
              onChange={(unixTime) => {
                setEndDate(unixTime);
                setPage(0);
                dispatch(clearEntityPresentationsCache());
              }}
              textFieldStyle={{
                width: '16.563rem',
                height: '2.938rem',
                borderRadius: '30px'
              }}
            />
          </Grid>

          <Grid item>
            <Button
              sx={{
                backgroundColor: 'rgb(232, 112, 112, 0.63)',
                border: '2px solid #AB0000',
                color: '#000000',
                textTransform: 'none',
                fontWeight: 'bold',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: 'rgb(232, 112, 112, 0.63)',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={clearFilters}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
                {translate('entity-presentations-page-filter-remove-filters')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PresentationRequestTable
        translate={translate}
        setErrorPopup={setErrorPopup}
        isLoading={isLoading}
        detailsRoute={isRequest ? PAGE_ROUTES.EntityPresentationRequestDetails : PAGE_ROUTES.EntityPresentationSignedDetails}
        presentationRequests={presentations}
        presentationCount={presentationCount}
        page={page}
        setPage={setPage}
        hideSignButton
        hideDelegateButton
        buttonComponent={isRequest ? PresentationRequestButtons : PresentationButtons}
      />
    </Grid>
  );
}
