const benefits = [
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/costs.gif",
      alt: "costs.gif"
    },
    title: "shared-costs-title",
    description: "shared-costs-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/interoperability.gif",
      alt: "interoperability.gif"
    },
    title: "interoperability-title",
    description: "interoperability-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/ux.gif",
      alt: "ux.gif"
    },
    title: "ux-title",
    description: "ux-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/automatization.gif",
      alt: "automatization.gif"
    },
    title: "automation-title",
    description: "automation-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/riskControl.gif",
      alt: "riskControl.gif"
    },
    title: "risk-control-title",
    description: "risk-control-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/masterQuality.gif",
      alt: "masterQuality.gif"
    },
    title: "quality-master-title",
    description: "quality-master-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/transparency.gif",
      alt: "transparency.gif"
    },
    title: "transparency-title",
    description: "transparency-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/reduction.gif",
      alt: "reduction.gif"
    },
    title: "reduction-title",
    description: "reduction-description"
  },
  {
    image: {
      className: "benefit-card-image",
      src: "images/small/validation.gif",
      alt: "validation.gif"
    },
    title: "validation-title",
    description: "validation-description"
  }
]

export default benefits