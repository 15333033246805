import { Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import FileInput from "../FileInput/FileInput.component";

export default function DynamicForm(props) {
  const translate = props.translate;
  const format = props.format;
  const fixedValue = props.fixedValue;
  const form = JSON.parse(props.formJson);
  const formState = props.value;

  const readOnly = fixedValue !== undefined;
  let formatToCheck = format.toLowerCase();

  if (readOnly) {
    formatToCheck = "value";
  }

  return (
    <Grid item>
      <Grid container direction="column" alignitems="stretch">
        {Object.keys(form).map((formKey) => {
          switch (formatToCheck) {
            case "value":
            default:
              return (
                <Grid item key={formKey} paddingBottom={2}>
                  <Grid container direction="column" alignitems="stretch" spacing={1}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("admin-credentials-create-credential-value")}</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        disabled={readOnly}
                        value={formState[formKey] || ""}
                        placeholder={translate("admin-credentials-create-credential-value-placeholder")}
                        onChange={(event) => {
                          let modifiableState = { ...formState };
                          modifiableState[formKey] = event.target.value;

                          if (props.onChange) {
                            props.onChange(modifiableState);
                          }
                        }}
                        sx={{
                          opacity: readOnly ? "0.5" : "1",
                          width: "50rem",
                          backgroundColor: "rgba(255, 255, 255, 0.38)",
                          border: "3px solid rgba(4, 146, 148, 1)",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          fontSize: "18px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            case "int":
              return (
                <Grid item key={formKey} paddingBottom={2}>
                  <Grid container direction="column" alignitems="stretch" spacing={1}>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("admin-credentials-create-credential-value")}</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        disabled={readOnly}
                        type="number"
                        value={formState[formKey] || ""}
                        placeholder={translate("admin-credentials-create-credential-value-placeholder")}
                        onChange={(event) => {
                          let modifiableState = { ...formState };
                          modifiableState[formKey] = event.target.value;

                          if (props.onChange) {
                            props.onChange(modifiableState);
                          }
                        }}
                        sx={{
                          opacity: readOnly ? "0.5" : "1",
                          width: "50rem",
                          backgroundColor: "rgba(255, 255, 255, 0.38)",
                          border: "3px solid rgba(4, 146, 148, 1)",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          fontSize: "18px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            case "file":
              return (
                <Grid container direction="column" alignitems="stretch" spacing={1}>
                  <Grid item>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("admin-credentials-create-credential-attach-file")}</Typography>
                  </Grid>
                  <Grid item key={formKey} paddingTop={1}>
                    <FileInput
                      required
                      disabled={readOnly}
                      value={formState[formKey] || ""}
                      placeholder={translate("admin-credentials-create-credential-attach-file-placeholder")}
                      onChange={(file) => {
                        let modifiableState = { ...formState };
                        modifiableState[formKey] = file;

                        if (props.onChange) {
                          props.onChange(modifiableState);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              );
          }
        })}
      </Grid>
    </Grid>
  );
}
