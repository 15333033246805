// ROUTER
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// LAYOUTS
import LandingLayout from "../layouts/Landing/Landing.layout";
import AdminLayout from "../layouts/Admin/Admin.layout";

// VIEWS
import MainLanding from "../views/MainLanding/MainLanding.view";
import Error404 from "../views/ErrorPages/Error404.view";

import Login from "../views/Login/Login.view";

import Register from "../views/Register/Register.view";
import Success from "../views/Register/Success/Success.view";

import EntityCredentials from "../views/Entity/Credentials/EntityCredentials.view";
import CreateEntityCredentialRequest, { createEntityCredentialRequestLoader } from "../views/Entity/Credentials/Create/CreateEntityCredentials.view";

import EntityPresentations from "../views/Entity/Presentations/EntityPresentations.view";
import SendEntityPresentation, { sendEntityPresentationLoader } from "../views/Entity/Presentations/Send/SendPresentation.view";
import EntityPresentationDetails, { entityPresentationDetailsLoader } from "../views/Entity/Presentations/Details/PresentationDetails.view";
import EntityPresentationRequestDetails, { entityPresentationRequestDetailsLoader } from "../views/Entity/Presentations/Details/PresentationRequestDetails.view";

import Catalog from "../views/Admin/Catalog/Catalog.view";

import Entities from "../views/Admin/Entities/Entities.view";
import CreateInvitation from "../views/Admin/Entities/Create/CreateInvitation.view";
import AddDid from "../views/Admin/Entities/Create/AddDid.view";

import AdminCredentials from "../views/Admin/Credentials/AdminCredentials.view";
import CreateAdminCredential, { createAdminCredentialLoader } from "../views/Admin/Credentials/Create/CreateAdminCredential.view";

import AdminPresentations from "../views/Admin/Presentations/Presentations.view";
import SendPresentation, { sendPresentationLoader } from "../views/Admin/Presentations/Send/SendPresentation.view";
import PresentationRequestDetails, { presentationRequestDetailsLoader } from "../views/Admin/Presentations/Details/PresentationRequestDetails.view";

import CreatePresentation, { createPresentationLoader } from "../views/Admin/PresentationForm/CreatePresentation.view";
import CreateRequest from "../views/Admin/PresentationForm/CreateRequest/CreateRequest.view";

// COMPONENTS
import Error from "@mui/icons-material/Error";
import Header from "../components/shared/Header/Header.component";
import SidePanel from "../components/shared/SidePanel/SidePanel.component";

// WHOAMI
import { fetchCorporateAsync, selectWhoAmI, selectWhoAmIStatus } from "../helpers/reducers/corporate";
import { PAGE_ROUTES, REDUCER_STATUS } from "../config/constants.config";
import { PLATFORM_TYPE } from "../config/constants.config";
import { useDispatch, useSelector } from "react-redux";

// Documentation on how the router works is available here:
// https://reactrouter.com/en/main/routers/create-browser-router
export default function DataRouter(props) {
  const translator = props.translator;
  const setErrorPopup = props.setErrorPopup;
  const changeLanguage = props.changeLanguage;

  const whoAmI = useSelector(selectWhoAmI);
  const whoAmIStatus = useSelector(selectWhoAmIStatus);

  const dispatch = useDispatch();

  const whoAmINeedsRefresh = whoAmIStatus === REDUCER_STATUS.NEEDS_REFRESH;
  const whoAmIIsReady = whoAmIStatus === REDUCER_STATUS.READY;
  
  if (whoAmINeedsRefresh) {
    dispatch(fetchCorporateAsync());
  }

  if (whoAmIIsReady) {
    window.document.title = whoAmI.businessName;
  }

  return (
    <RouterProvider
      router={createBrowserRouter([
        // All routes start with a path, the react component to render (element) which should be a layout, and any sub-pages under that path for that layout.
        {
          // This route is for the main landing, and will render the child view MainLanding using the LandingLayout
          path: PAGE_ROUTES.Root,
          element: <LandingLayout />,
          children: [
            {
              // This renders the MainLanding view, and makes it the index page for the path on the parent. In other words, this page will be rendered within the LandingLayout component.
              element: <MainLanding translator={translator} changeLanguage={changeLanguage} />,
              index: true,
            },
          ],
        },
        // When seeing a function, this is a view-initialized router path.
        // This is done when actions or loaders are needed but we don't want to expose them.
        // In this case, the login() function returns a path for /login with the Login view component, and the redirection action should the login succeed.
        {
          // This route is for the login page, and will render the Login view component.
          path: PAGE_ROUTES.Login,
          element: <Login translator={translator} setErrorPopup={setErrorPopup} />,
        },
        // A route can have no element defined component, but if so, should have one defined in a sub-page with the index declaration.
        {
          // This route will render all pages under /register path.
          path: PAGE_ROUTES.Register,
          children: [
            {
              path: PAGE_ROUTES.Register,
              element: <Register translator={translator} />,
              index: true,
            },
            // This object will return a subpage at /register/success and will render the Success view.
            {
              path: PAGE_ROUTES.RegisterSuccess,
              element: <Success translator={translator} />,
            },
          ],
        },

        {
          // This entry sets the layout for pages under the /admin route.
          // This also configures the layout to use the correct header component.
          path: PAGE_ROUTES.Admin,
          element: <AdminLayout header={<Header translator={translator} changeLanguage={changeLanguage} />} sidePanel={<SidePanel translator={translator} />} />,
          children: [
            {
              // This is the path entries for /admin/entity-contacts
              path: PAGE_ROUTES.AdminEntityContacts,
              children: [
                // This object points to render the Entities view, and an Error component if there are rendering issues.
                // It is set as the index, so it will render at /admin/entity-contacts
                {
                  element: <Entities key="contacts" translator={translator} comms={PLATFORM_TYPE[1]} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /admin/entity-contacts/create with the CreateInvitation view
                {
                  path: PAGE_ROUTES.AdminEntityContactsInvite,
                  element: <CreateInvitation translator={translator} setErrorPopup={setErrorPopup} />,
                },
                // This function will return a subpage at /admin/entity-contacts/add-did with the AddDid view
                {
                  path: PAGE_ROUTES.AdminEntityContactsAddDid,
                  element: <AddDid translator={translator} comms={PLATFORM_TYPE[1]} setErrorPopup={setErrorPopup} />,
                },
              ],
            },
            {
              // This is the path entries for /admin/corporate-contacts
              path: PAGE_ROUTES.AdminCompanyContacts,
              children: [
                // This object points to render the Entities view, and an Error component if there are rendering issues.
                // It is set as the index, so it will render at /admin/corporate-contacts
                {
                  element: <Entities key="company" translator={translator} comms={PLATFORM_TYPE[0]} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /admin/corporate-contacts/add-did with the AddDid view
                {
                  path: PAGE_ROUTES.AdminCompanyContactsAddDid,
                  element: <AddDid translator={translator} comms={PLATFORM_TYPE[0]} setErrorPopup={setErrorPopup} />,
                },
              ],
            },

            {
              // This is the path entries for /admin/credentials
              path: PAGE_ROUTES.AdminCredentials,
              children: [
                // This object points to render the AdminCredentials view, and an Error component if there are rendering issues.
                {
                  path: PAGE_ROUTES.AdminCredentials,
                  element: <AdminCredentials key="emissions" translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /admin/credentials/create with the CreateAdminCredential view
                {
                  path: PAGE_ROUTES.AdminCredentialsCreate,
                  element: <CreateAdminCredential translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  loader: createAdminCredentialLoader,
                },
              ],
            },
            {
              // This is the path entries for /admin/credential-requests
              path: PAGE_ROUTES.AdminCredentialRequests,
              children: [
                // This object points to render the AdminCredentials view, and an Error component if there are rendering issues.
                {
                  path: PAGE_ROUTES.AdminCredentialRequests,
                  element: <AdminCredentials key="requests" translator={translator} setErrorPopup={setErrorPopup} isRequests />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /admin/credential-requests/create with the CreateAdminCredential view
                {
                  path: PAGE_ROUTES.AdminCredentialRequestsCreate,
                  element: <CreateAdminCredential translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  loader: createAdminCredentialLoader,
                },
              ],
            },

            {
              // This is the path entries for /admin/presentations
              // This object points to render the AdminPresentations view, and an Error component if there are rendering issues.
              path: PAGE_ROUTES.AdminPresentations,
              children: [
                {
                  path: PAGE_ROUTES.AdminPresentations,
                  element: <AdminPresentations translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /admin/presentations/send with the VerificationDetail view
                {
                  path: `${PAGE_ROUTES.AdminPresentationsSend}/:presentationId`,
                  element: <SendPresentation translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: sendPresentationLoader,
                },
                // This function will return a subpage at /admin/presentations/:presentationRequestId with the PresentationRequestDetails view
                {
                  path: ":presentationRequestId",
                  element: <PresentationRequestDetails translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: presentationRequestDetailsLoader,
                },
              ],
            },
            // This function will return a subpage at /admin/presentationForm with the CreateVerification view
            {
              path: PAGE_ROUTES.AdminPresentationForm,
              children: [
                {
                  element: <CreatePresentation translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: createPresentationLoader,
                  index: true,
                },
                {
                  path: PAGE_ROUTES.AdminPresentationFormCatalog,
                  element: <CreateRequest translator={translator} />,
                },
              ],
            },
          ],
        },

        {
          // This entry sets the layout for pages under the /entity route.
          // This also configures the layout to use the correct header component and color scheme.
          path: PAGE_ROUTES.Entity,
          element: <AdminLayout header={<Header translator={translator} changeLanguage={changeLanguage} />} sidePanel={<SidePanel translator={translator} />} isEntity />,
          children: [
            {
              // This is the path entries for /entity/credentials
              path: PAGE_ROUTES.EntityCredentials,
              children: [
                // This object points to render the EntityCredentials view, and an Error component if there are rendering issues.
                {
                  element: <EntityCredentials translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /entity/credentials/create with the CreateEntityCredentials view
                {
                  path: PAGE_ROUTES.EntityCredentialsCreate,
                  element: <CreateEntityCredentialRequest translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  loader: createEntityCredentialRequestLoader,
                },
              ],
            },
            {
              // This is the path entries for /entity/presentations
              path: PAGE_ROUTES.EntityPresentations,
              children: [
                // This object points to render the EntityPresentations view, and an Error component if there are rendering issues.
                // It is set as the index, so it will render at /entity/presentations
                {
                  element: <EntityPresentations translator={translator} setErrorPopup={setErrorPopup} />,
                  errorElement: <Error />,
                  index: true,
                },
                // This function will return a subpage at /entity/presentations/send/:presentationId with the VerificationDetail view
                {
                  path: `${PAGE_ROUTES.EntityPresentationsSend}/:presentationId`,
                  element: <SendEntityPresentation translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: sendEntityPresentationLoader,
                },
                // This function will return a subpage at /entity/presentations/request-details/:presentationRequestId with the EntityPresentationRequestDetails view
                {
                  path: `${PAGE_ROUTES.EntityPresentationRequestDetails}/:presentationRequestId`,
                  element: <EntityPresentationRequestDetails translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: entityPresentationRequestDetailsLoader,
                },
                // This function will return a subpage at /entity/presentations/signed-details/:presentationRequestId with the EntityPresentationDetails view
                {
                  path: `${PAGE_ROUTES.EntityPresentationSignedDetails}/:presentationRequestId`,
                  element: <EntityPresentationDetails translator={translator} setErrorPopup={setErrorPopup} />,
                  loader: entityPresentationDetailsLoader,
                },
              ],
            },
          ],
        },

        // This object will render the catalog, on its own, with an empty header and no sidebar.
        {
          path: PAGE_ROUTES.AdminCatalog,
          element: <AdminLayout header={<Header translator={translator} changeLanguage={changeLanguage} onlyTranslate />} noSidePanel />,
          // This is the path entries for /catalog
          children: [
            {
              index: true,
              element: <Catalog translator={translator} />,
              errorElement: <Error />,
            },
          ],
        },

        // A * will catch all remaining routes. This should be included as a minimum to either redirect back to the main page, or show a 404 page on any invalid route.
        {
          path: "*",
          element: <Error404 translator={translator} />,
        },
      ])}
    />
  );
}
