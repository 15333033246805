// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLinkClickHandler, Link, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

// ------FILE MODULES---------------------------------------------------------
import { CREDENTIAL_STATUS, CUSTOMER_STATUS, PAGE_ROUTES, PLATFORM_TYPE } from '../../../../config/constants.config';
import { clearCustomerCache } from '../../../../helpers/reducers/customers';
import { isOk, post } from '../../../../helpers/axios.helper';

import config from '../../../../config/config';
import WarningBox from '../../../../components/shared/DialogBoxes/WarningBox.component';
import SpinnerLoader from '../../../../components/shared/Loader/SpinnerLoader.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function onFormSubmit(form) {
  if (config.mocked) {
    const mockedResponse = { status: CREDENTIAL_STATUS[0], ...form };
    config.mockedData.invitations.push(mockedResponse);
    return { status: 200 };
  }

  return await post('/customer', form);
}

function MobileForm(props) {
  const translate = props.translate;
  const setErrorPopup = props.setErrorPopup;
  const comms = props.comms;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [did, setDid] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [prefix, setPrefix] = useState(null);

  const didChanged = (event) => {
    setDid(event.target.value);
  };
  const emailChanged = (event) => {
    setEmail(event.target.value);
  };
  const businessNameChanged = (event) => {
    setBusinessName(event.target.value);
  };
  const phoneChanged = (event) => {
    setPhone(event.target.value);
  };
  const prefixChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 3) {
      setPrefix(value);
    }
  };

  const isFormInvalid = () => {
    return !did || !email || !businessName || !prefix || !phone;
  }

  const formSubmit = async (event) => {
    event.preventDefault();
    if (isFormInvalid()) {
      return;
    }

    setFormSendState(true);
    const response = await onFormSubmit({
      did,
      comms,
      email,
      businessName,
      tel: `+${prefix}${phone}`,
      status: CUSTOMER_STATUS[1]
    });

    if (isOk(response.status)) {
      dispatch(clearCustomerCache());
      navigate(-1);
    } else {
      setErrorPopup(response);
    }

    setFormSendState(false);
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <form onSubmit={validForm}>
      <Grid container direction='column' alignItems='stretch' spacing={1}>
        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>{translate('entities-add-did-did')}</Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            type='text'
            fullWidth
            id='outlined-required'
            onChange={didChanged}
            value={did || ''}
            name='businessName'
            placeholder={translate('entities-add-did-did-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
            {translate('entities-add-did-email')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            type='email'
            fullWidth
            id='outlined-required'
            onChange={emailChanged}
            value={email || ''}
            name='email'
            placeholder={translate('entities-add-did-email-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
            {translate('entities-add-did-employee-name')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id='outlined-required'
            onChange={businessNameChanged}
            value={businessName || ''}
            name='businessName'
            placeholder={translate('entities-add-did-employee-name-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Grid container direction='row' spacing={1}>
            <Grid item flexGrow={0.1}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                {translate('entities-add-did-phone-number-prefix')}
              </Typography>
              <TextField
                required
                type='number'
                fullWidth
                id='outlined-required'
                onChange={prefixChanged}
                value={prefix || ''}
                placeholder={translate('entities-add-did-phone-number-prefix-placeholder')}
                name='prefix'
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.38)',
                  border: '3px solid rgba(4, 146, 148, 1)',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  fontSize: '1.125rem',
                  width: '6.25rem'
                }}
                inputProps={{
                  maxlength: 3
                }}
              />
            </Grid>
            <Grid item flexGrow={10}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                {translate('entities-add-did-phone-number')}
              </Typography>
              <TextField
                required
                fullWidth
                type='number'
                id='outlined-required'
                onChange={phoneChanged}
                value={phone || ''}
                placeholder={translate('entities-add-did-phone-number-placeholder')}
                name='phone'
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.38)',
                  border: '3px solid rgba(4, 146, 148, 1)',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  fontSize: '1.125rem'
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container paddingTop={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  height: '2.938rem',
                  backgroundColor: '#1B5255',
                  color: 'white',
                  fontSize: '22px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#1B5255',
                    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                  }
                }}
                type='submit'>
                <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                  {translate('entities-add-did-send')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <WarningBox
          title={translate(`entities-add-did-popup-title`)}
          open={warningBoxOpen}
          onClose={() => {
            if (formSendState) return;
            setWarningBoxOpen(false);
          }}
          actions={
            formSendState
              ? []
              : [
                  {
                    label: translate(`entities-add-did-popup-reject`),
                    onClick: (event) => {
                      setWarningBoxOpen(false);
                    }
                  },
                  {
                    label: translate(`entities-add-did-popup-accept`),
                    onClick: (event) => {
                      formSubmit(event);
                    }
                  }
                ]
          }>
          <SpinnerLoader isLoading={formSendState} text={translate(`entities-add-did-popup-sending`)}>
            <Typography>{translate(`entities-add-did-popup-body`)}</Typography>
          </SpinnerLoader>
        </WarningBox>
      </Grid>
    </form>
  );
}

function CompanyForm(props) {
  const translate = props.translate;
  const setErrorPopup = props.setErrorPopup;
  const comms = props.comms;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [did, setDid] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [alias, setAlias] = useState(null);
  const [cif, setCif] = useState(null);

  const didChanged = (event) => {
    setDid(event.target.value);
  };
  const emailChanged = (event) => {
    setEmail(event.target.value);
  };
  const businessNameChanged = (event) => {
    setBusinessName(event.target.value);
  };
  const aliasChanged = (event) => {
    setAlias(event.target.value);
  };
  const cifChanged = (event) => {
    setCif(event.target.value);
  };

  const isFormInvalid = () => {
    return !did || !email || !businessName || !alias || !cif;
  }
  const formSubmit = async (event) => {
    event.preventDefault();
    if (isFormInvalid()) {
      return;
    }

    setFormSendState(true);
    const response = await onFormSubmit({
      did,
      comms,
      email,
      businessName,
      alias,
      cif,
      status: CUSTOMER_STATUS[1]
    });

    if (isOk(response.status)) {
      dispatch(clearCustomerCache());
      navigate(-1);
    } else {
      setErrorPopup(response);
    }

    setFormSendState(false);
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <form onSubmit={validForm}>
      <Grid container direction='column' alignItems='stretch' spacing={1}>
        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>{translate('entities-add-did-did')}</Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            type='text'
            fullWidth
            id='outlined-required'
            onChange={didChanged}
            value={did || ''}
            name='businessName'
            placeholder={translate('entities-add-did-did-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
            {translate('entities-add-did-company-name')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id='outlined-required'
            onChange={businessNameChanged}
            value={businessName || ''}
            name='businessName'
            placeholder={translate('entities-add-did-company-name-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
            {translate('entities-add-did-alias')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id='outlined-required'
            onChange={aliasChanged}
            value={alias || ''}
            name='businessName'
            placeholder={translate('entities-add-did-alias-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
            {translate('entities-add-did-email')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id='outlined-required'
            onChange={emailChanged}
            value={email || ''}
            name='alias'
            placeholder={translate('entities-add-did-email-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>{translate('entities-add-did-cif')}</Typography>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            id='outlined-required'
            onChange={cifChanged}
            value={cif || ''}
            name='cif'
            placeholder={translate('entities-add-did-cif-placeholder')}
            InputProps={{
              style: {
                fontSize: '1.125rem'
              }
            }}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.38)',
              border: '3px solid rgba(4, 146, 148, 1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              fontSize: '1.125rem'
            }}
          />
        </Grid>

        <Grid item>
          <Grid container paddingTop={2} justifyContent='flex-end'>
            <Grid item>
              <Button
                sx={{
                  height: '2.938rem',
                  backgroundColor: '#1B5255',
                  color: 'white',
                  fontSize: '22px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#1B5255',
                    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                  }
                }}
                type='submit'>
                <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                  {translate('entities-add-did-send')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <WarningBox
          title={translate(`entities-add-did-popup-title`)}
          open={warningBoxOpen}
          onClose={() => {
            if (formSendState) return;
            setWarningBoxOpen(false);
          }}
          actions={
            formSendState
              ? []
              : [
                  {
                    label: translate(`entities-add-did-popup-reject`),
                    onClick: (event) => {
                      setWarningBoxOpen(false);
                    }
                  },
                  {
                    label: translate(`entities-add-did-popup-accept`),
                    onClick: (event) => {
                      formSubmit(event);
                    }
                  }
                ]
          }>
          <SpinnerLoader isLoading={formSendState} text={translate(`entities-add-did-popup-sending`)}>
            <Typography>{translate(`entities-add-did-popup-body`)}</Typography>
          </SpinnerLoader>
        </WarningBox>
      </Grid>
    </form>
  );
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AddDid(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;
  const comms = props.comms;

  return (
    <Grid container direction='column' alignItems='stretch' spacing={3}>
      <Grid item>
        <Grid container direction='row' alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.AdminEntityContacts}>{translate('entities-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>
                  {translate('entities-add-did-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Button
            sx={{
              height: '2.938rem',
              backgroundColor: '#1B5255',
              color: 'white',
              fontSize: '1.375rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#1B5255',
                boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
              }
            }}
            onClick={useLinkClickHandler(-1)}>
            <Typography variant='h5' paddingRight={5} paddingLeft={5}>
              {translate('entities-add-did-back')}
            </Typography>
            <ArrowForwardIos />
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container justifyContent='space-evenly' alignItems='center' paddingTop={5} spacing={3}>
          <Grid item md={6}>
            {comms === PLATFORM_TYPE[1] ? (
              <MobileForm translate={translate} setErrorPopup={setErrorPopup} comms={comms} />
            ) : (
              <CompanyForm translate={translate} setErrorPopup={setErrorPopup} comms={comms} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
