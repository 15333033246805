// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { clearCatalogCache } from '../../../helpers/reducers/catalog';

import { ROW_COUNT } from '../../../config/constants.config';

import CollapsibleCatalogCredentialRow from '../Rows/CollapsibleCatalogCredentialRow.component';

import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Hider from '../../shared/Hider/Hider.component';

import './CatalogCredentialTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CATALOG = [];
const DEFAULT_IS_LOADING = false;
const DEFAULT_CATALOG_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[1];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_SELECTED_CREDENTIALS_LOADING = false;
const DEFAULT_SELECTED_CREDENTIALS_CHANGED = () => {};
const DEFAULT_SELECTED_CREDENTIALS = {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_CHECKBOXES = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_DISABLE_CHECKBOXES = false;
const DEFAULT_MAX_NAME_LENGTH = 100;
const DEFAULT_MAX_USE_CASE_LENGTH = 100;
const DEFAULT_CHILDREN = null;

const checkboxText = 'catalog-credential-table-checkboxes';
const actionsText = 'catalog-credential-table-actions';

const loas = [1, 2, 3];

function getHeaders(hideActions, hideCheckboxes, translate) {
  return [
    {
      text: checkboxText,
      component: (
        <Grid container direction='row' justifyContent='space-around'>
          {loas.map((loa) => (
            <Grid item key={loa}>
              <Typography sx={{ fontWeight: 'bold' }}>{loa}</Typography>
            </Grid>
          ))}
        </Grid>
      )
    },
    { text: 'catalog-credential-table-credential-name', align: 'left' },
    { text: 'catalog-credential-table-official-issuer' },
    { text: 'catalog-credential-table-category' },
    { text: 'catalog-credential-table-format' },
    { text: 'catalog-credential-table-use-case' },
    { text: actionsText }
  ]
    .filter((header) => {
      if (hideActions && header.text === actionsText) {
        return false;
      }
      if (hideCheckboxes && header.text === checkboxText) {
        return false;
      }
      return true;
    })
    .map((header) => {
      header.text = translate(header.text);
      return header;
    });
}

function getGroupedCatalog(catalog) {  
  const groupedData = {};
  catalog.forEach((catalogEntry) => {
    const category = catalogEntry.category || catalogEntry.type;
    const group = groupedData[category];
    if (group) {
      group.metadata.push(catalogEntry);
      return;
    }
    const catalogEntryWithMetadata = { ...catalogEntry, metadata: [] };

    groupedData[category] = catalogEntryWithMetadata;
  });

  return groupedData;
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogCredentialTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const catalog = props.catalog || DEFAULT_CATALOG;
  const catalogCount = props.catalogCount || DEFAULT_CATALOG_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const selectedCredentialsLoading = props.selectedCredentialsLoading || DEFAULT_SELECTED_CREDENTIALS_LOADING;
  const selectedCredentialsChanged = props.selectedCredentialsChanged || DEFAULT_SELECTED_CREDENTIALS_CHANGED;
  const selectedCredentials = props.selectedCredentials || DEFAULT_SELECTED_CREDENTIALS;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideCheckboxes = props.hideCheckboxes || DEFAULT_HIDE_CHECKBOXES;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const disableCheckboxes = props.disableCheckboxes || DEFAULT_DISABLE_CHECKBOXES;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxUseCaseLength = props.maxUseCaseLength || DEFAULT_MAX_USE_CASE_LENGTH;
  const children = props.children || DEFAULT_CHILDREN;

  const [countCredentials, setCountCredentials] = useState([]);

  const dispatch = useDispatch();

  const checkBoxChange = (e, type, loa) => {
    let modifySelectedCredentials = { ...selectedCredentials };

    const loaIndex = loa - 1;
    if (e.target.checked) {
      const oldLoa = modifySelectedCredentials[type];

      if (oldLoa !== undefined) {
        countCredentials[oldLoa - 1]--;
      }

      modifySelectedCredentials[type] = loa;
      countCredentials[loaIndex]++;
    } else {
      delete modifySelectedCredentials[type];
      countCredentials[loaIndex]--;
    }

    setCountCredentials(countCredentials);
    selectedCredentialsChanged(modifySelectedCredentials);
  };

  if (!selectedCredentialsLoading && countCredentials.length === 0) {
    const selectedKeys = Object.keys(selectedCredentials);
    const count = [0, 0, 0];

    if (selectedKeys.length > 0) {
      selectedKeys.forEach((key) => {
        const loa = selectedCredentials[key];
        count[loa - 1]++;
      });
    }

    setCountCredentials(count);
  }

  const headers = getHeaders(hideActions, hideCheckboxes, translate);
  const groupedCatalog = getGroupedCatalog(catalog);

  const paginationColSpan = headers.length - (hideCheckboxes ? 0 : 1) - (children ? 1 : 0);
  const spinnerColSpan = headers.length;

  return (
    <Grid item paddingBottom={4}>
      <TableContainer className='catalog-credential-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('catalog-credential-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {Object.keys(groupedCatalog).map((groupKey) => {
                let catalogEntry = groupedCatalog[groupKey];
                return (
                  <CollapsibleCatalogCredentialRow
                    key={catalogEntry.type}
                    catalogEntry={catalogEntry}
                    translate={translate}
                    checkBoxChange={checkBoxChange}
                    selectedCredentials={selectedCredentials}
                    hideCheckboxes={hideCheckboxes}
                    disableCheckboxes={disableCheckboxes}
                    hideActions={hideActions}
                    maxNameLength={maxNameLength}
                    maxUseCaseLength={maxUseCaseLength}
                  />
                );
              })}
            </SpinnerLoader>
            <TableRow>
              <Hider isHidden={hideCheckboxes}>
                <TableCell useTypography={false}>
                  <Grid container direction='row' justifyContent='space-around'>
                    {countCredentials.map((count, index) => (
                      <Grid item key={index}>
                        <Typography sx={{ fontWeight: 'bold' }}>{count}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </Hider>
              <TableCell colSpan={paginationColSpan} useTypography={false}>
                <Hider isHidden={hidePagination}>
                  <TablePagination
                    component='div'
                    count={catalogCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                      dispatch(clearCatalogCache());
                    }}
                  />
                </Hider>
              </TableCell>
              {children}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
