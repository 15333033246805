import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getResponseJson, isOk } from "../axios.helper";
import config from "../../config/config";
import { decodeAllJwts, mapCredentialRequests, mapCredentials } from "../jwt.helpers";
import { CREDENTIAL_STATUS, ROW_COUNT } from "../../config/constants.config";

const initialState = {
  value: [],
  isRequest: false,
  status: 1,
  page: 0,
  count: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCredentialsAsync = createAsyncThunk(
  "credentials/fetchCredentials",
  async ({ isRequest, page, credentialType, search, customerId, status }) => {
    // count should be made user configurable.
    const limit = ROW_COUNT[0];
    const form = new URLSearchParams();
    let jwts = [];
    let endpoint = "/dit/credential";
    let didFilter = "issuer";
    let customerFilter = "subject";
    let mappingFunc = mapCredentials;

    if (isRequest) {
      endpoint = "/dit/credentialRequest";
      didFilter = "subject";
      customerFilter = "issuer";
      mappingFunc = mapCredentialRequests;
    }

    let count = 0;
    if (config.mocked) {
      jwts = isRequest ? config.mockedData.entityCredentials : config.mockedData.credentials;
      count = jwts.length;
    } else {
      const whoAmIResponse = await get("/corporate");

      if (isOk(whoAmIResponse.status)) {
        const whoAmI = getResponseJson(whoAmIResponse);
        form.append(didFilter, whoAmI.did);
        form.append("offset", limit * page);
        form.append("limit", limit);
        form.append("isDeleted", false);

        if (credentialType) {
          form.append("type", credentialType);
        }

        if (search) {
          form.append("search", search);
        }

        if (customerId) {
          form.append(customerFilter, customerId);
        }

        if (status) {
          switch (status) {
            case CREDENTIAL_STATUS[0]:
              form.append("status", status);
              form.append("expiresAfter", Math.floor(Date.now() / 1000));
              break;
            case CREDENTIAL_STATUS[2]:
              form.append("status", CREDENTIAL_STATUS[0]);
              form.append("expiredFrom", Math.floor(Date.now() / 1000));
              break;
            default:
              form.append("status", status);
              break;
          }
        }

        const response = await get(endpoint, form);

        if (isOk(response.status)) {
          jwts = getResponseJson(response);
          count = Number(response.headers["x-app-total-count"]) || 0;
        }
      }
    }

    const value = await mappingFunc(decodeAllJwts(jwts), false);
    return { isRequest, page, count, value };
  }
);

export const credentialsSlice = createSlice({
  name: "credentials",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearCredentialsCache: (state) => {
      state.status = 1;
      state.value = [];
    },

    setCredentialsIsRequest: (state, action) => {
      state.isRequest = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCredentialsAsync.pending, (state) => {
        state.status = 2;
      })
      .addCase(fetchCredentialsAsync.fulfilled, (state, action) => {
        state.status = 0;
        const payload = action.payload;
        state.value = payload.value;
        state.isRequest = payload.isRequest;
        state.page = payload.page;
        state.count = payload.count;
      });
  },
});

export const { clearCredentialsCache, setCredentialsIsRequest } = credentialsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectCredentials = (state) => state.credentials.value;
export const selectCredentialsState = (state) => state.credentials.status;
export const selectCredentialsIsRequest = (state) => state.credentials.isRequest;
export const selectCredentialsPage = (state) => state.credentials.page;
export const selectCredentialsCount = (state) => state.credentials.count;

export default credentialsSlice.reducer;
