import constants from './constants.config';
import mockedData from './mockedData.config';

const apiURL = '/veia/v4';
const keycloakURL = '/auth/realms/digitalis/protocol/openid-connect/token';
const blockExplorerURL = '//explorer.';
const fileExplorerURL = '//files.';
const mocked = false;
const useAuth = true;
const apiVersion = 4;
const axiosTimeout = 120000;
const prConfig = {
  formType: 'Form',
  delegatedType: 'Delegated'
};

export default { constants, apiURL, keycloakURL, mocked, apiVersion, useAuth, mockedData, axiosTimeout, prConfig, blockExplorerURL, fileExplorerURL };
