// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import InfoOutlined from '@mui/icons-material/InfoOutlined';

// ------FILE MODULES---------------------------------------------------------
import { clearEntityPresentationsCache } from '../../../helpers/reducers/entityPresentations';
import { ROW_COUNT } from '../../../config/constants.config';

import PresentationRequestRow from '../Rows/PresentationRequestRow.component';

import Hider from '../../shared/Hider/Hider.component';
import TableCell from '../../shared/Tables/TableCell.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';

import './PresentationRequestTable.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_IS_LOADING = false;
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_CREDENTIALS_BUTTON = false;
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_DELEGATE_BUTTON = false;
const DEFAULT_DETAILS_ROUTE = '';
const DEFAULT_PRESENTATION_COUNT = 0;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_PADDING_BOTTOM = 4;
const DEFAULT_PRESENTATION_REQUESTS = [];

const requestDetailsText = 'presentation-request-table-details';

function getHeaders(translate, hideRequestDetails) {
  return [
    {
      text: 'presentation-request-table-request-name'
    },
    {
      text: 'presentation-request-table-purpose'
    },
    {
      text: 'presentation-request-table-issuer'
    },
    {
      text: 'presentation-request-table-valid-dates'
    },
    {
      text: 'presentation-request-table-status'
    },
    {
      text: 'presentation-request-table-delegated'
    },
    {
      text: requestDetailsText
    },
    {
      text: 'presentation-request-table-actions'
    },
    {
      text: '',
      component: <InfoOutlined />
    }
  ].filter((header) => {
    if (hideRequestDetails && header.text === requestDetailsText) {
      return false;
    }

    return true;
  }).map((header) => {
    header.text = translate(header.text);
    return header;
  });
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideCredentialsButton = props.hideCredentialsButton || DEFAULT_HIDE_CREDENTIALS_BUTTON;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideDelegateButton = props.hideDelegateButton || DEFAULT_HIDE_DELEGATE_BUTTON;
  const detailsRoute = props.detailsRoute || DEFAULT_DETAILS_ROUTE;
  const presentationRequests = props.presentationRequests || DEFAULT_PRESENTATION_REQUESTS;
  const presentationCount = props.presentationCount || DEFAULT_PRESENTATION_COUNT;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const paddingBottom = props.paddingBottom === undefined ? DEFAULT_PADDING_BOTTOM : props.paddingBottom;
  const buttonComponent = props.buttonComponent;

  const dispatch = useDispatch();

  const headers = getHeaders(translate, hideCredentialsButton);

  return (
    <Grid item paddingBottom={paddingBottom}>
      <TableContainer className='presentation-request-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate('presentation-request-table-loading')}
              component={SingleTableRow}
              componentProps={{ colSpan: headers.length, cellProps: { useTypography: false } }}>
              {presentationRequests.map((presentationRequest, index) => (
                <PresentationRequestRow
                  key={index}
                  translate={translate}
                  setErrorPopup={setErrorPopup}
                  presentationRequest={presentationRequest}
                  buttonComponent={buttonComponent}
                  hideCredentialsButton={hideCredentialsButton}
                  hideSignButton={hideSignButton}
                  hideDelegateButton={hideDelegateButton}
                  detailsRoute={detailsRoute}
                />
              ))}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={headers.length} useTypography={false}>
                  <TablePagination
                    component='div'
                    count={presentationCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onPageChange={(e, newPage) => {
                      setPage(newPage);
                      dispatch(clearEntityPresentationsCache());
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
