// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import MoreHoriz from '@mui/icons-material/MoreHoriz';

// ------FILE MODULES---------------------------------------------------------
import TableCell from '../../shared/Tables/TableCell.component';

import { getColorForStatus } from '../../../helpers/color.helper';

import config from '../../../config/config';
import Hider from '../../shared/Hider/Hider.component';
import { Menu, MenuItem, Typography } from '@mui/material';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const TRANSLATE_PREFIX = 'presentation-request-row-';
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_PRESENTATION_REQUEST = { storedJwt: { status: '' }, decoded: { pr: { type: [] } } };
const DEFAULT_BUTTON_COMPONENT = Fragment;
const DEFAULT_HIDE_CREDENTIALS_BUTTON = false;
const DEFAULT_HIDE_SIGN_BUTTON = false;
const DEFAULT_HIDE_DELEGATE_BUTTON = false;
const DEFAULT_DETAILS_ROUTE = '';
const DEFAULT_LINK_STYLE = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textDecoration: 'none',
  color: '#1B5255',
  'a:visited': {
    textDecoration: 'none',
    color: '#1B5255'
  }
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationRequestRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const presentationRequest = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;
  const ButtonComponent = props.buttonComponent || DEFAULT_BUTTON_COMPONENT;
  const hideCredentialsButton = props.hideCredentialsButton || DEFAULT_HIDE_CREDENTIALS_BUTTON;
  const hideSignButton = props.hideSignButton || DEFAULT_HIDE_SIGN_BUTTON;
  const hideDelegateButton = props.hideDelegateButton || DEFAULT_HIDE_DELEGATE_BUTTON;
  const detailsRoute = props.detailsRoute || DEFAULT_DETAILS_ROUTE;
  const linkStyle = props.linkStyle || DEFAULT_LINK_STYLE;

  const [anchorElement, setAnchorElement] = useState(null);
  const navigate = useNavigate();

  const prOrVp = presentationRequest.decoded.pr || presentationRequest.decoded.vp;
  const name = prOrVp.procId;
  const purpose = prOrVp.procDesc;
  const issuer = presentationRequest.decoded.issuerName
    ? presentationRequest.decoded.issuerName.businessName
    : presentationRequest.decoded.iss;
  const file = prOrVp.procUrl;
  const isDelegated = prOrVp.type.includes(config.prConfig.delegatedType);
  const startDate = presentationRequest.decoded.nbf
    ? new Date(presentationRequest.decoded.nbf * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';
  const endDate = presentationRequest.decoded.exp
    ? new Date(presentationRequest.decoded.exp * 1000).toLocaleDateString(undefined, { dateStyle: 'short' })
    : 'N/A';
  const status = presentationRequest.storedJwt.status;
  const signedToken = presentationRequest.storedJwt.signedToken;
  const transactionHash = presentationRequest.storedJwt.transactionHash;

  const FileComponent = file ? Link : Typography;
  const fileComponentProps = {
    sx: {
      textDecoration: 'none',
      color: '#1B5255',
      'a:visited': {
        textDecoration: 'none',
        color: '#1B5255'
      }
    },
    target: '_blank',
    href: file
  };
  const fileText = TRANSLATE_PREFIX + (file ? 'download-document' : 'no-file');
  const statusColor = getColorForStatus(status);
  const statusText = TRANSLATE_PREFIX + status.toLowerCase();
  const delegatedColor = isDelegated ? '#049294' : 'inherit';
  const delegatedText = TRANSLATE_PREFIX + (isDelegated ? 'delegated' : 'not-delegated');
  const detailsText = 'presentation-buttons-credentials';
  const copyHashText = TRANSLATE_PREFIX + 'copy-hash';
  const copyTokenText = TRANSLATE_PREFIX + 'copy-signed-token';

  const route = `${detailsRoute}/${presentationRequest.storedJwt.jti}`;

  const openDetails = (event) => {
    event.preventDefault();
    navigate(route);
  };

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{purpose}</TableCell>
      <TableCell>{issuer}</TableCell>
      <TableCell>{`${startDate} - ${endDate}`}</TableCell>
      <TableCell color={statusColor}>{translate(statusText)}</TableCell>
      <TableCell color={delegatedColor}>{translate(delegatedText)}</TableCell>
      <Hider isHidden={hideCredentialsButton}>
        <TableCell useTypography={false}>
          <Link sx={linkStyle} onClick={openDetails} href={detailsRoute}>
            {translate(detailsText)}
          </Link>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <ButtonComponent
          translate={translate}
          setErrorPopup={setErrorPopup}
          presentationRequest={presentationRequest}
          hideCredentialsButton={hideCredentialsButton}
          hideSignButton={hideSignButton}
          hideDelegateButton={hideDelegateButton}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={(event) => setAnchorElement(event.currentTarget)}>
          <MoreHoriz />
        </IconButton>
        <Menu open={!!anchorElement} onClose={() => setAnchorElement(null)} anchorEl={anchorElement}>
          <MenuItem>
            <FileComponent {...fileComponentProps}>{translate(fileText)}</FileComponent>
          </MenuItem>
          <Hider isHidden={!transactionHash}>
            <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(transactionHash);
                setAnchorElement(null);
              }}>
              <Typography>{translate(copyHashText)}</Typography>
            </MenuItem>
          </Hider>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(signedToken);
              setAnchorElement(null);
            }}>
            <Typography>{translate(copyTokenText)}</Typography>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
