import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function AenorIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 165.14, height: 50.38 }}>
      <path className="cls-1" d="M88.21,22.58V7.24c-1.07,0-2.1,0-3.12,0-.42,0-1-.08-1.23-.36-1.38-1.74-2.67-3.54-4.11-5.49.59-.06.93-.13,1.28-.13H93.76c.75,0,1.31,0,1.31,1,0,10,0,19.9,0,29.85a5.63,5.63,0,0,1-.12.7c-2.26,0-4.53,0-6.8,0-.36,0-.78-.55-1.06-.93q-6.91-9.27-13.8-18.56c-.35-.47-.71-.93-1.25-1.63v15.1a3.35,3.35,0,0,0,.77.07c2.43-.49,4,.5,5.18,2.63a30.14,30.14,0,0,0,2.35,3.25H65.18V1.25c2.27,0,4.49,0,6.7,0,.38,0,.81.52,1.09.9Q80,11.61,87,21.07Z" />
      <path className="cls-1" d="M99.35,17c0-10,7.2-17,17.32-17s17.14,7.11,17.15,17-7.18,17.08-17.26,17.07S99.34,27,99.35,17Zm27.1-.55A10.21,10.21,0,0,0,123,8.86a9.8,9.8,0,0,0-16,5.39c-1.26,5.81,1.42,11.23,6.36,12.83C120.18,29.29,126.46,24.47,126.45,16.44Z" />
      <path className="cls-1" d="M158.1,21.81l6.56,11c-2.47,0-4.68,0-6.89,0-.27,0-.63-.34-.78-.62q-4-7.07-8-14.16a2.69,2.69,0,0,1-.13-.57c1.32,0,2.58.05,3.83,0,3.86-.17,5.9-2.87,5-6.58a4,4,0,0,0-3.54-3.36c-3-.24-6.07-.06-9.26-.06V32.75h-6.67c0-.44-.1-.87-.1-1.3,0-9.58,0-19.16,0-28.73,0-1.09.24-1.53,1.43-1.51,4.88.09,9.76.05,14.64.15a12.61,12.61,0,0,1,3.48.62,10.84,10.84,0,0,1,6,15.79A9.12,9.12,0,0,1,158.1,21.81Z" />
      <path className="cls-1" d="M54.86,14c0,1.92,0,3.56,0,5.19,0,.92-.56.88-1.18.88h-11v6.73h1.44c4.25,0,8.49,0,12.73,0a1.47,1.47,0,0,1,1.7,1.26c.47,1.56,1.06,3.08,1.63,4.71H35.91V1.34H59.72C59.07,3.2,58.52,5,57.81,6.68c-.14.32-.88.53-1.34.53-4.07,0-8.13,0-12.19,0H42.71V14Z" />
      <path className="cls-1" d="M0,32.84c.68-1.78,1.29-3.39,1.91-5,3.28-8.4,6.59-16.79,9.82-25.21A1.83,1.83,0,0,1,13.8,1.2c2,.1,4.11.05,6.16,0a1.29,1.29,0,0,1,1.45.91q5.82,15,11.7,30.05c.06.16.09.33.18.65-2.26,0-4.43,0-6.6,0-.3,0-.71-.49-.85-.84-.6-1.48-1.15-3-1.64-4.49a1.22,1.22,0,0,0-1.39-1c-2.83.05-5.66,0-8.73,0l2.09-6h5.4L16.73,7.24c-.29.72-.49,1.16-.66,1.62C13.31,16.33,10.52,23.8,7.83,31.3a2,2,0,0,1-2.34,1.57C3.73,32.75,2,32.84,0,32.84Z" />
      <path className="cls-1" d="M21.86,50H18.28a9.58,9.58,0,0,0,0-8.28l.35-.26c.83.16,1.66.35,2.5.47a2.66,2.66,0,0,0,.87-.13,12.38,12.38,0,0,1,2.38-.42,2,2,0,0,1,2.15,2c.07.81.07,1.63.14,2.45.09,1-.39,2.15.64,3,.17.15,0,.68,0,1.12H24.63c0-1.7,0-3.41,0-5.12,0-.94,0-1.91-1.33-1.89-1.6,0-2.17.53-2.18,2a12.18,12.18,0,0,0,0,1.91c.1.6.42,1.16.57,1.76A8.83,8.83,0,0,1,21.86,50Z" />
      <path className="cls-1" d="M7.53,38.65v2.74c-.48-.09-1.12,0-1.23-.26-.67-1.34-1.85-.78-2.61-.51A2.79,2.79,0,0,0,2.2,42.48a10.82,10.82,0,0,0,0,3.93,2.8,2.8,0,0,0,1.47,1.88c.8.29,2.05.91,2.66-.6.08-.2.69-.18,1.17-.29V50a8.26,8.26,0,0,1-5.13,0A3,3,0,0,1,.24,47.32a28.7,28.7,0,0,1,0-5.56,3.32,3.32,0,0,1,3.3-3.09C4.81,38.54,6.13,38.65,7.53,38.65Z" />
      <path className="cls-1" d="M45.09,44.87c.07-1.66-.15-2-1.6-1.89a11.77,11.77,0,0,0-2.58.84c-.39-2-.33-2.14,1.53-2.39a8.9,8.9,0,0,1,1.63-.07c2.26,0,3,.79,3.11,3.06,0,.59,0,1.19,0,1.78.07.89-.33,1.88.6,2.63.19.15.07.68.1,1.25-.7,0-1.32,0-1.93,0a14.45,14.45,0,0,0-2.29.13c-2.44.52-3.73-.56-3.53-3a2,2,0,0,1,1.88-2C43,45,44,45,45.09,44.87Zm.37,1.35c-1.39.13-2.63-.51-3.29.75a2,2,0,0,0,.19,1.58,3.71,3.71,0,0,0,2.41-.07C45.23,48.2,45.21,47.13,45.46,46.22Z" />
      <path className="cls-1" d="M9.16,45.8c0-3.61,2.18-5.3,5.44-4.21a2.43,2.43,0,0,1,.38.13c1.6.78,1.88,2.22,1.89,3.79s0,3.49-1.82,4.36a4.37,4.37,0,0,1-4.66-.34C9.13,48.62,9.16,47.18,9.16,45.8Zm1.92,0h0v.68c0,1.19.4,2.05,1.73,2.15s1.92-.53,2-2a5.08,5.08,0,0,0-.19-2.41A2.32,2.32,0,0,0,13,43.07a2.78,2.78,0,0,0-1.72,1.16C11,44.59,11.13,45.28,11.08,45.82Z" />
      <path className="cls-1" d="M31.45,43.22,32.13,50H28.77c.26-.6.47-1.09.66-1.59a2.3,2.3,0,0,0,.22-.75c0-1.23.06-2.46,0-3.69,0-.33-.43-.65-.69-.94s-.67-.63-1.27-1.2l1.79-.35c.84-3.23,1.3-3.68,3.4-3.39.74.1,1.23.35.9,1.22-2.18.42-2.18.42-2,2.2l2,.14V43Z" />
      <path className="cls-1" d="M37.78,41.69c.43,2.76-.58,5.59,1.07,8.3H35a9.73,9.73,0,0,0-.05-8.3Z" />
      <path className="cls-1" d="M39.27,38.5c-1.07,1-1.77,2.44-3.57,1.93C36.63,38.47,37.2,38.15,39.27,38.5Z" />
    </SvgIcon>
  )
}