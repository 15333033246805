import AenorIcon from '../components/shared/Icons/AenorIcon.component'
import CaixaIcon from '../components/shared/Icons/CaixaIcon.component'
import CepsaIcon from '../components/shared/Icons/CepsaIcon.component'
import CtaimaIcon from '../components/shared/Icons/CtaimaIcon.component'
import GrantThorntonIcon from '../components/shared/Icons/GrantThorntonIcon.component'
import MadridIcon from '../components/shared/Icons/MadridIcon.component'
import MapfreIcon from '../components/shared/Icons/MapfreIcon.component'
import RepsolIcon from '../components/shared/Icons/RepsolIcon.component'
import SapIcon from '../components/shared/Icons/SapIcon.component'

const participants = {
  partnersContainer: {
    title: "participants-partners-title",
    participantSvgs: [
      {
        height: 71.69,
        svg: (<RepsolIcon />)
      },
      {
        height: 103.5,
        svg: (<CtaimaIcon />)
      },
      {
        height: 44.75,
        svg: (<MapfreIcon />)
      },
      {
        height: 69.2,
        svg: (<CepsaIcon />)
      },
      {
        height: 53.23,
        svg: (<CaixaIcon />)
      }
    ]
  },
  integObserverAndEmisorContainers: [
    {
      title: "participants-integrator-title",
      participantSvgs: [
        {
          height: 63.51,
          svg: (<SapIcon />)
        }
      ]
    },
    {
      title: "participants-emisor-title",
      participantSvgs: [
        {
          height: 50.38,
          svg: (<AenorIcon />)
        }
      ]
    },
    {
      title: "participants-integrator-title",
      participantSvgs: [
        {
          height: 39.29,
          svg: (<MadridIcon />)
        }
      ]
    }
  ],
  strategicPartnerContainer: {
    title: "participants-partner-title",
    participantSvgs: [
      {
        height: 73.56,
        svg: (<GrantThorntonIcon />)
      }
    ]
  }
}

export default participants