// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ------ FILE MODULES ---------------------------------------------------------
import {
  clearPresentationsCache,
  fetchPresentationsAsync,
  selectPresentations,
  selectPresentationsCount,
  selectPresentationsPage,
  selectPresentationsState
} from '../../../helpers/reducers/adminPresentations';

import SearchBar from '../../../components/shared/SearchBars/SearchBar.component';
import DatePicker from '../../../components/shared/DatePicker/DatePicker.component';

import './Presentations.view.css';
import PresentationRequestTemplateTable from '../../../components/private/Tables/PresentationRequestTemplateTable.component';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AdminPresentations(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const [page, setPage] = useState(useSelector(selectPresentationsPage));
  const [search, setSearch] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const presentationTemplates = useSelector(selectPresentations);
  const presentationsState = useSelector(selectPresentationsState);
  const presentationCount = useSelector(selectPresentationsCount);

  const isLoading = presentationsState === 2;
  const needsReload = presentationsState === 1;

  if (needsReload) {
    dispatch(
      fetchPresentationsAsync({
        page,
        search,
        startDate: Math.round(startDate / 1000),
        endDate: Math.round(endDate / 1000)
      })
    );
  }

  const clearFilters = () => {
    setSearch(null);
    setStartDate(null);
    setEndDate(null);
    onFilterSubmit();
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearPresentationsCache());
  };

  return (
    <Grid container className='admin-presentations-view' direction='column' alignItems='stretch' spacing={3}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h5' sx={{ color: '#1B5255', fontWeight: 'bold', fontSize: '2rem' }}>
              {translate('presentations-page-title')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          className='adminPresentationsFilterContainer'>
          <Grid item paddingLeft={1} paddingRight={2}>
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
              {translate('presentations-page-filter-head')}
            </Typography>
          </Grid>

          <Grid item paddingTop={3}>
            <SearchBar
              translate={translate}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                onFilterSubmit();
              }}
              onSubmit={onFilterSubmit}
            />
          </Grid>

          <Grid item paddingTop={1} paddingBottom={1}>
            <Typography paddingLeft={1.5}>{translate('presentations-page-filter-start-date')}</Typography>
            <DatePicker
              required
              max={endDate}
              value={startDate}
              onChange={(unixTime) => {
                setStartDate(unixTime);
                onFilterSubmit();
              }}
              textFieldStyle={{
                width: '16.563rem',
                height: '2.938rem',
                borderRadius: '30px'
              }}
            />
          </Grid>
          <Grid item>
            <Typography paddingLeft={1.5}>{translate('presentations-page-filter-end-date')}</Typography>
            <DatePicker
              required
              min={startDate}
              value={endDate}
              onChange={(unixTime) => {
                setEndDate(unixTime);
                onFilterSubmit();
              }}
              textFieldStyle={{
                width: '16.563rem',
                height: '2.938rem',
                borderRadius: '30px'
              }}
            />
          </Grid>
          <Grid item paddingTop={3}>
            <Button
              sx={{
                backgroundColor: 'rgb(232, 112, 112, 0.63)',
                border: '2px solid #AB0000',
                color: '#000000',
                textTransform: 'none',
                fontWeight: 'bold',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: 'rgb(232, 112, 112, 0.63)',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={clearFilters}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
                {translate('presentations-page-filter-remove-filters')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PresentationRequestTemplateTable
        translate={translate}
        setErrorPopup={setErrorPopup}
        isLoading={isLoading}
        presentationRequestTemplates={presentationTemplates}
        presentationCount={presentationCount}
        page={page}
        setPage={setPage}
      />
    </Grid>
  );
}
