import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getResponseJson, isOk } from "../axios.helper";
import config from "../../config/config";
import { ROW_COUNT } from "../../config/constants.config";

const initialState = {
  value: [],
  status: 1,
  page: 0,
  count: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCatalogAsync = createAsyncThunk("catalog/fetchCatalog", async ({ page, limit, search, holder, useCase, issuer }) => {
  try{const finalLimit = limit === undefined ? ROW_COUNT[0]: limit;

    if (config.mocked) {
      return {page, count: config.mockedData.catalog.length, value: config.mockedData.catalog};
    }
  
    const form = new URLSearchParams();
  
    if (finalLimit) {
      if (page) {
        form.append("offset", finalLimit * page);
      }
      form.append("limit", finalLimit);
    }
  
    if (search) {
      form.append("search", search);
    }
  
    if (holder) {
      form.append("holder", holder);
    }
  
    if (useCase) {
      form.append("useCase", useCase);
    }
  
    if (issuer) {
      form.append("issuer", issuer);
    }
  
    const response = await get("/catalog", form);
  
    if (isOk(response.status)) {
      const responseJson = getResponseJson(response);
  
      if (responseJson.length > 0) {
        return {page, count: Number(response.headers["x-app-total-count"]) || 0, value: responseJson};
      }
    }
  
    return {page, count: 0, value: []};
  }
  catch (exception) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log(exception);
    }
  }
});

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    clearCatalogCache: (state) => {
      state.status = 1;
      state.value = [];
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogAsync.pending, (state) => {
        state.status = 2;
      })
      .addCase(fetchCatalogAsync.fulfilled, (state, action) => {
        state.status = 0;
        const payload = action.payload;
        state.value = payload.value;

        if (payload.page !== undefined && payload.page !== null) {
          state.page = payload.page;
          state.count = Number(payload.count);
        }
      });
  },
});

export const { clearCatalogCache } = catalogSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectCatalog = (state) => state.catalog.value;
export const selectCatalogState = (state) => state.catalog.status;
export const selectCatalogPage = (state) => state.catalog.page;
export const selectCatalogCount = (state) => state.catalog.count;

export default catalogSlice.reducer;
