// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { CREDENTIAL_REQUEST_STATUS, CREDENTIAL_STATUS } from '../../../config/constants.config';

import SearchBar from '../../shared/SearchBars/SearchBar.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

import './CredentialFilters.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_USERS = [];
const DEFAULT_IS_REQUEST = false;
const DEFAULT_IS_LOADING = false;
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_CUSTOMER = '';
const DEFAULT_SET_CUSTOMER = () => {};
const DEFAULT_STATUS = '';
const DEFAULT_SET_STATUS = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_CLEAR_FILTERS = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CredentialFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const users = props.users || DEFAULT_USERS;
  const isRequest = props.isRequest || DEFAULT_IS_REQUEST;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const customer = props.customer || DEFAULT_CUSTOMER;
  const setCustomer = props.setCustomer || DEFAULT_SET_CUSTOMER;
  const status = props.status || DEFAULT_STATUS;
  const setStatus = props.setStatus || DEFAULT_SET_STATUS;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const clearFilters = props.clearFilters || DEFAULT_CLEAR_FILTERS;

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='credentials-filters-container'>
      <Grid item paddingLeft={1} paddingRight={4}>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
          {translate('credentials-page-filter')}
        </Typography>
      </Grid>

      <Grid item>
        <SearchBar
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
        />
      </Grid>

      <Dropdown
        value={customer}
        isLoading={isLoading}
        options={users}
        getOptionLabel={(customer) => customer.businessName}
        getOptionValue={(customer) => customer.did}
        onChange={(customer) => {
          setCustomer(customer);
          onFilterSubmit();
        }}
        label={translate('credentials-page-filter-receiver')}
      />

      <Dropdown
        value={status}
        options={(isRequest ? CREDENTIAL_REQUEST_STATUS : CREDENTIAL_STATUS).map((status) => {
          return { status };
        })}
        getOptionLabel={(status) =>
          translate(`credentials-page-filter-${isRequest ? 'request' : 'emission'}-${status.status.toLowerCase()}`)
        }
        getOptionValue={(status) => status.status}
        onChange={(status) => {
          setStatus(status);
          onFilterSubmit();
        }}
        label={translate('credentials-page-filter-status')}
      />

      <Grid item>
        <Button
          sx={{
            backgroundColor: 'rgb(232, 112, 112, 0.63)',
            border: '2px solid #AB0000',
            color: '#000000',
            textTransform: 'none',
            fontWeight: 'bold',
            marginRight: '10px',
            '&:hover': {
              backgroundColor: 'rgb(232, 112, 112, 0.63)',
              boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
            }
          }}
          onClick={clearFilters}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('credentials-page-remove-filters')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
