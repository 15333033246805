import React from 'react'
import { Grid, Typography } from '@mui/material'
import Slide from '../../../../components/shared/Slides/Slide.component'
import InfoCard from '../../../../components/shared/Cards/InfoCard.component'
import successCasesConfig from '../../../../config/successCases.config'
import './SuccessCases.slide.css'

const subtitleProps = {
  fontSize: "2.5vh",
  lineHeight: "2.92vh",
  textTransform: "uppercase",
  height: "8vh"
}

const descriptionProps = {
  fontSize: "1.7vh",
  lineHeight: "2.1vh",
  color: "black"
}

const cardProps = {
  subtitleProps,
  descriptionProps
}

function title(translate) {
  return (
    <Typography fontWeight="bold" variant="h3">
      {translate("success-cases-title")}
    </Typography>
  )
}

function content(translate) {
  return (
    <Grid container spacing={12}>
      {successCasesConfig.map((element, index) => {
        return (
          <InfoCard key={index}
            subtitle={translate(element.title)}
            description={translate(element.description)}
            {...cardProps}
          />
        )
      })}
    </Grid>
  )
}

const successCases = { name: "successCases", title, content }
export default function SuccessCases(props) {
  successCases.translator = props.translator;

  return (
    <Slide {...successCases} />
  );
}