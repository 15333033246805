// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useLinkClickHandler, useLoaderData, Link, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

// ------FILE MODULES---------------------------------------------------------
import { PAGE_ROUTES, CUSTOMER_STATUS, PRESENTATION_REQUEST_CUSTOMER_STATUS } from '../../../../config/constants.config';
import { decodeAllJwts, mapPresentationRequests } from '../../../../helpers/jwt.helpers';
import { post, isOk, get, getResponseJson } from '../../../../helpers/axios.helper';
import { getColorForStatus } from '../../../../helpers/color.helper';

import PresentationRequestTemplateTable from '../../../../components/private/Tables/PresentationRequestTemplateTable.component';

import SpinnerLoader from '../../../../components/shared/Loader/SpinnerLoader.component';
import WarningBox from '../../../../components/shared/DialogBoxes/WarningBox.component';
import Dropdown from '../../../../components/shared/Dropdowns/Dropdown.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_IS_FAILED = false;
const DEFAULT_CHILDREN = null;

function PresentationRequestLoadFailed(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const isFailed = props.isFailed || DEFAULT_IS_FAILED;
  const children = props.children || DEFAULT_CHILDREN;

  if (!isFailed) {
    return children;
  }

  return (
    <Grid item>
      <Grid container direction='row' alignItems='center' justifyContent='center'>
        <Grid item>
          <Typography>{translate('presentation-request-send-page-failed-to-load')}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

async function loadPresentationRequest(presentationRequestId, setPresentationLoading, setPresentation, setErrorPopup) {
  setPresentationLoading(true);

  try {
    const prResponse = await get(`/dit/presentationRequest/${presentationRequestId}`);

    if (isOk(prResponse.status)) {
      const presentations = await mapPresentationRequests(decodeAllJwts([getResponseJson(prResponse)]), false);

      if (presentations.length > 0) {
        setPresentation(presentations[0]);
      }
    }
  } catch (exception) {
    setErrorPopup(exception);
  }

  setPresentationLoading(false);
}

async function loadUsers(setUsersLoading, setUsers, setErrorPopup) {
  setUsersLoading(true);

  try {
    var urlForm = new URLSearchParams();
    urlForm.append('status', CUSTOMER_STATUS[1]);
    const response = await get('/customer', urlForm);

    if (isOk(response.status)) {
      const users = getResponseJson(response);

      const whoAmIResponse = await get('/corporate');

      if (isOk(whoAmIResponse.status)) {
        const whoAmI = getResponseJson(whoAmIResponse);
        setUsers(users.filter((user) => user.did !== whoAmI.did));
      }
    }
  } catch (exception) {
    setErrorPopup(exception);
  }

  setUsersLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export async function sendPresentationLoader({ params }) {
  return params.presentationId;
}

export default function SendPresentation(props) {
  const translate = props.translator || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;

  const navigate = useNavigate();

  const presentationRequestId = useLoaderData();

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPresentationLoading, setIsPresentationLoading] = useState(undefined);
  const [presentation, setPresentation] = useState(undefined);
  const [isUsersLoading, setIsUsersLoading] = useState(undefined);
  const [users, setUsers] = useState(undefined);

  const failedToGetPresentation = presentation === undefined;
  const failedToGetUsers = users === undefined;

  if (isPresentationLoading === undefined) {
    loadPresentationRequest(presentationRequestId, setIsPresentationLoading, setPresentation, setErrorPopup);
  }

  if (isUsersLoading === undefined) {
    loadUsers(setIsUsersLoading, setUsers, setErrorPopup);
  }

  const isFormInvalid = () => {
    return selectedUsers.length === 0;
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (isFormInvalid()) {
      return;
    }

    const form = {
      ...presentation.storedJwt,
      customers: selectedUsers.map((user) => {
        return { customer: user, customerStatus: PRESENTATION_REQUEST_CUSTOMER_STATUS[0] };
      })
    };

    setFormSendState(true);
    asyncFormSubmit(form);
  };

  const asyncFormSubmit = async (form) => {
    const response = await post(`/dit/presentationRequest/${presentation.storedJwt.jti}`, form);

    if (isOk(response.status)) {
      navigate(0);
    } else {
      setErrorPopup(response);
    }

    setUsers([]);
    setFormSendState(false);
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <Grid container direction='column' alignItems='stretch' spacing={5}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.AdminPresentations}>{translate('presentations-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>
                  {translate('presentations-send-page-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              sx={{
                backgroundColor: '#1B5255',
                color: 'white',
                fontSize: '1.125rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1B5255',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                {translate('presentations-create-back')}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PresentationRequestLoadFailed translate={translate} isFailed={!isPresentationLoading && failedToGetPresentation}>
        <PresentationRequestTemplateTable
          translate={translate}
          setErrorPopup={setErrorPopup}
          isLoading={isPresentationLoading}
          paddingBottom={0}
          presentationRequestTemplates={[presentation]}
          hidePagination
          hideSendButton
        />

        <Grid item>
          <Grid container justifyContent='space-between'>
            <Grid item paddingRight={2}>
              <form onSubmit={validForm}>
                <Grid container direction='column' alignItems='stretch' spacing={2}>
                  <Grid item>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                      {translate('presentations-send-users')}
                    </Typography>
                  </Grid>
                  <Dropdown
                    value={selectedUsers}
                    isLoading={isUsersLoading}
                    multivalue
                    required
                    noOptionsMessage={
                      !isUsersLoading && failedToGetUsers ? translate('admin-presentations-send-no-users') : undefined
                    }
                    options={(users || []).filter((user) =>
                      presentation
                        ? !presentation.decoded.customers.some((customer) => customer.customer.did === user.did)
                        : false
                    )}
                    getOptionLabel={(user) => user.businessName}
                    getOptionValue={(user) => user.did}
                    onChange={(users) => {
                      setSelectedUsers(users);
                    }}
                    selectStyle={{
                      width: '33rem',
                      borderRadius: '10px',
                      fontSize: '1.125rem'
                    }}
                  />

                  <Grid item alignSelf='flex-end'>
                    <Button
                      sx={{
                        backgroundColor: '#1B5255',
                        color: 'white',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#1B5255',
                          boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                        }
                      }}
                      type='submit'>
                      <Typography>{translate('presentations-send-button')}</Typography>
                    </Button>
                    <WarningBox
                      title={translate(`admin-presentations-send-popup-title`)}
                      open={warningBoxOpen}
                      onClose={() => {
                        if (formSendState) return;
                        setWarningBoxOpen(false);
                      }}
                      actions={
                        formSendState
                          ? []
                          : [
                              {
                                label: translate(`admin-presentations-send-popup-reject`),
                                onClick: (event) => {
                                  setWarningBoxOpen(false);
                                }
                              },
                              {
                                label: translate(`admin-presentations-send-popup-accept`),
                                onClick: (event) => {
                                  formSubmit(event);
                                }
                              }
                            ]
                      }>
                      <SpinnerLoader isLoading={formSendState} text={translate(`admin-presentations-send-popup-sending`)}>
                        <Typography>{translate(`admin-presentations-send-popup-body`)}</Typography>
                      </SpinnerLoader>
                    </WarningBox>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item md={6} paddingLeft={2}>
              <Grid container direction='column' alignItems='stretch' spacing={2}>
                <Grid item>
                  <Typography>{translate('presentations-send-table')}</Typography>
                  <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align='left'
                            sx={{
                              borderBottom: '1px solid #000000',
                              fontWeight: 'bold',
                              fontSize: '0.938rem',
                              textTransform: 'uppercase'
                            }}>
                            {translate('presentations-send-table-name')}
                          </TableCell>
                          <TableCell
                            align='right'
                            sx={{
                              borderBottom: '1px solid #000000',
                              fontWeight: 'bold',
                              fontSize: '0.938rem',
                              textTransform: 'uppercase'
                            }}>
                            {translate('presentations-send-table-status')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(presentation ? presentation.decoded.customers : []).map((customer, index) => (
                          <TableRow key={index}>
                            <TableCell align='left' sx={{ whiteSpace: 'nowrap', borderBottom: '1px solid #000000' }}>
                              <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                {customer.customer.businessName}
                              </Typography>
                            </TableCell>
                            <TableCell align='right' sx={{ whiteSpace: 'nowrap', borderBottom: '1px solid #000000' }}>
                              <Typography
                                color={getColorForStatus(customer.customerStatus)}
                                sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                {translate(`presentation-detail-status-${customer.customerStatus.toLowerCase()}`)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PresentationRequestLoadFailed>
    </Grid>
  );
}
