import { configureStore } from '@reduxjs/toolkit'
import corporate from '../helpers/reducers/corporate'
import catalog from '../helpers/reducers/catalog'
import credentials from '../helpers/reducers/adminCredentials'
import presentations from '../helpers/reducers/adminPresentations';
import entityCredentials from '../helpers/reducers/entityCredentials'
import entityPresentations from '../helpers/reducers/entityPresentations'
import customer from '../helpers/reducers/customers'
import session from '../helpers/reducers/session'

export default configureStore({
  reducer: {
    corporate: corporate,
    catalog: catalog,
    credentials: credentials,
    entityCredentials: entityCredentials,
    presentations: presentations,
    entityPresentations: entityPresentations,
    customer: customer,
    session: session
  },
})