// SLIDES
import Landing from './Slides/Landing/Landing.slide'
import Benefits from './Slides/Benefits/Benefits.slide'
import SuccessCases from './Slides/SuccessCases/SuccessCases.slide'
import Participants from './Slides/Participants/Participants.slide'
import Footer from '../../components/shared/Footer/Footer.component'

// CSS
import './MainLanding.view.css'

const slides = [
  Benefits,
  SuccessCases
]

export default function MainLanding(props) {
  const translator = props.translator;
  const changeLanguage = props.changeLanguage;

  return (
    <div className="main-landing-slides">
      <div className="main-landing-welcoming-slide">
        <Landing translator={translator} changeLanguage={changeLanguage} />
      </div>
      <div className="main-landing-rest-slides">
        {slides.map((Slide, index) => {
          return (<Slide key={index} translator={translator} />)
        })}
        <Footer translator={translator} />
      </div>
    </div>
  );
}