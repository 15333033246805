// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------
// ------NODE MODULES---------------------------------------------------------
import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FilledInput from '@mui/material/FilledInput';

import Search from '@mui/icons-material/Search';

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_PLACEHOLDER = 'search-box-text';
const DEFAULT_VALUE = '';
const DEFAULT_DISABLED = false;
const DEFAULT_ON_CHANGE = () => {};
const DEFAULT_ON_SUBMIT = () => {};
const DEFAULT_STYLE = {
  height: '2.938rem',
  width: '16.563rem',
  backgroundColor: 'rgba(255, 255, 255, 0.38)',
  border: '3px solid rgba(4, 146, 148, 1)',
  borderRadius: '30px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: '1rem',
  fontWeight: '500',
  lineHeight: '1rem',
  '&:hover': {
    border: '3px solid rgba(4, 126, 128, 1)'
  }
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SearchBar(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const placeholder = props.placeholder || translate(DEFAULT_PLACEHOLDER);
  const value = props.value || DEFAULT_VALUE;
  const disabled = props.disabled || DEFAULT_DISABLED;
  const onChange = props.onChange || DEFAULT_ON_CHANGE;
  const onSubmit = props.onSubmit || DEFAULT_ON_SUBMIT;
  const style = { ...DEFAULT_STYLE, ...props.style };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl variant='filled'>
        <InputLabel htmlFor='filled-adornment-search'>
          <Typography sx={{ fontSize: '1rem', fontWeight: '500', lineHeight: '1rem' }}>{placeholder}</Typography>
        </InputLabel>
        <FilledInput
          disableUnderline
          value={value}
          disabled={disabled}
          onChange={onChange}
          sx={style}
          type='text'
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={handleSubmit}
                edge='end'
                sx={{
                  backgroundColor: 'rgba(4, 146, 148, 1)',
                  borderRadius: '50%',
                  left: '1%',
                  '&:hover': { backgroundColor: 'rgba(4, 146, 148, 1)' }
                }}>
                <Search fontSize='large' sx={{ color: '#FFFFFF', fontSize: '2rem' }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </form>
  );
}
