export const Root = "/";

export const Login = `${Root}login`;

export const Register = `${Root}register`;

export const RegisterSuccess = `${Register}/success`;

export const Admin = `${Root}admin`;

export const AdminCatalog = `${Root}catalog`;
export const AdminCatalogAddCredential = `${AdminCatalog}/create`;

const AddDid = `add-did`;
export const AdminEntityContacts = `${Admin}/entity-contacts`;
export const AdminEntityContactsInvite = `${AdminEntityContacts}/invite`;
export const AdminEntityContactsAddDid = `${AdminEntityContacts}/${AddDid}`;
export const AdminCompanyContacts = `${Admin}/corporate-contacts`;
export const AdminCompanyContactsAddDid = `${AdminCompanyContacts}/${AddDid}`;

export const AdminCredentials = `${Admin}/credentials`;
export const AdminCredentialsCreate = `${AdminCredentials}/create`;
export const AdminCredentialRequests = `${Admin}/credential-requests`;
export const AdminCredentialRequestsCreate = `${AdminCredentialRequests}/create`;

export const AdminPresentations = `${Admin}/presentations`;
export const AdminPresentationsSend = `${AdminPresentations}/send`;
export const AdminPresentationForm = `${Admin}/presentation-form`;
export const AdminPresentationFormCatalog = `${AdminPresentationForm}/catalog`;

export const Entity = `${Root}entity`;

export const EntityCredentials = `${Entity}/credentials`;
export const EntityCredentialsCreate = `${EntityCredentials}/create`;

export const EntityPresentations = `${Entity}/presentations`;
export const EntityPresentationsSend = `${EntityPresentations}/send`;
export const EntityPresentationRequestDetails = `${EntityPresentations}/request-details`;
export const EntityPresentationSignedDetails = `${EntityPresentations}/signed-details`;

export default {
  Root,
  Login,
  Register,
  RegisterSuccess,
  Admin,
  AdminCatalog,
  AdminCatalogAddCredential,
  AdminEntityContacts,
  AdminEntityContactsInvite,
  AdminEntityContactsAddDid,
  AdminCompanyContacts,
  AdminCompanyContactsAddDid,
  AdminCredentials,
  AdminCredentialsCreate,
  AdminCredentialRequests,
  AdminCredentialRequestsCreate,
  AdminPresentations,
  AdminPresentationsSend,
  AdminPresentationForm,
  AdminPresentationFormCatalog,
  Entity,
  EntityCredentials,
  EntityCredentialsCreate,
  EntityPresentations,
  EntityPresentationsSend,
  EntityPresentationRequestDetails,
  EntityPresentationSignedDetails
};
