// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { useLinkClickHandler, NavLink } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ------FILE MODULES---------------------------------------------------------
import { clearEntityCredentialsCache } from "../../../helpers/reducers/entityCredentials";
import { clearEntityPresentationsCache } from "../../../helpers/reducers/entityPresentations";
import { clearCredentialsCache } from "../../../helpers/reducers/adminCredentials";
import { clearPresentationsCache } from "../../../helpers/reducers/adminPresentations";
import { clearCustomerCache } from "../../../helpers/reducers/customers";
import { clearCatalogCache } from "../../../helpers/reducers/catalog";

import { PAGE_ROUTES } from "../../../config/constants.config";

import Accordion from "../Accordion/Accordion.component";

import "./SidePanel.component.css";

// ---------------------------------------------------------------------------
// PRIVATE COMPONENTS
// ---------------------------------------------------------------------------
function ButtonWithUnderline({ text, to, override, invalidateCaches, color }) {
  const handler = useLinkClickHandler(to);
  let finalColor = color || "#a9d9d0";

  return (
    <Grid item className="side-panel-navigation-item" sx={{ color: finalColor }}>
      <Button className="side-panel-navigation-buton" fullWidth sx={{ textTransform: "none", textAlign: "right" }} onClick={(e) => invalidateCaches(e, handler)}>
        <NavLink className={"side-panel-navigation-link"} to={override || to}>
          <Typography sx={{ fontSize: "0.875rem", color: finalColor, paddingRight: "2rem" }} variant="h6">
            {text}
          </Typography>
        </NavLink>
      </Button>
    </Grid>
  );
}

function EntityAccordion(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const onChange = props.onChange;
  const invalidateCaches = props.invalidateCaches;

  return (
    <Grid item>
      <Accordion isOpen={isOpen} onChange={onChange} title={translate("nav-side-bar-category-entity")} weight="bold">
        <ButtonWithUnderline text={translate("link-to-entity-credentials")} to={PAGE_ROUTES.EntityCredentials} invalidateCaches={invalidateCaches} />
        <ButtonWithUnderline text={translate("link-to-entity-presentations")} to={PAGE_ROUTES.EntityPresentations} invalidateCaches={invalidateCaches} />
      </Accordion>
    </Grid>
  );
}

function AdminAccordion(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const onChange = props.onChange;
  const invalidateCaches = props.invalidateCaches;

  let defaultState = null;
  switch (window.location.pathname) {
    case PAGE_ROUTES.AdminEntityContacts:
    case PAGE_ROUTES.AdminEntityContactsAddDid:
    case PAGE_ROUTES.AdminEntityContactsInvite:
    case PAGE_ROUTES.AdminCompanyContacts:
    case PAGE_ROUTES.AdminCompanyContactsAddDid:
      defaultState = 0;
      break;
    case PAGE_ROUTES.AdminCredentials:
    case PAGE_ROUTES.AdminCredentialsCreate:
    case PAGE_ROUTES.AdminCredentialRequests:
    case PAGE_ROUTES.AdminCredentialRequestsCreate:
      defaultState = 1;
      break;
    case PAGE_ROUTES.AdminPresentations:
    case PAGE_ROUTES.AdminPresentationForm:
    case PAGE_ROUTES.AdminPresentationFormCatalog:
      defaultState = 2;
      break;
    default:
      if (window.location.pathname.startsWith(PAGE_ROUTES.AdminPresentations)) {
        defaultState = 2;
      }
      break;
  }

  const [openAccordion, setOpenAccordion] = useState(defaultState);

  const handleAccordionChange = (accordionIndex) => {
    if (openAccordion === accordionIndex) {
      setOpenAccordion(-1);
      return;
    }
  
    setOpenAccordion(accordionIndex);
  }

  return (
    <Grid item>
      <Accordion isOpen={isOpen} onChange={onChange} title={translate("nav-side-bar-category-admin")} color="#f2e7dc" weight="bold">
        <Accordion isOpen={openAccordion === 0} onChange={() => handleAccordionChange(0)} title={translate("nav-side-bar-category-admin-contacts")} color="#f2e7dc">
          <ButtonWithUnderline text={translate("link-to-admin-contacts-mobile")} to={PAGE_ROUTES.AdminEntityContacts} invalidateCaches={invalidateCaches} color="#f2e7dc" />
          <ButtonWithUnderline text={translate("link-to-admin-contacts-wwc")} to={PAGE_ROUTES.AdminCompanyContacts} invalidateCaches={invalidateCaches} color="#f2e7dc" />
        </Accordion>

        <Accordion isOpen={openAccordion === 1} onChange={() => handleAccordionChange(1)} title={translate("nav-side-bar-category-admin-credentials")} color="#f2e7dc">
          <ButtonWithUnderline text={translate("link-to-admin-credentials")} to={PAGE_ROUTES.AdminCredentials} invalidateCaches={invalidateCaches} color="#f2e7dc" />
          <ButtonWithUnderline text={translate("link-to-admin-credentials-request")} to={PAGE_ROUTES.AdminCredentialRequests} invalidateCaches={invalidateCaches} color="#f2e7dc" />
        </Accordion>

        <Accordion isOpen={openAccordion === 2} onChange={() => handleAccordionChange(2)} title={translate("nav-side-bar-category-admin-presentations")} color="#f2e7dc">
          <ButtonWithUnderline text={translate("link-to-admin-presentations")} to={PAGE_ROUTES.AdminPresentations} invalidateCaches={invalidateCaches} color="#f2e7dc" />
          <ButtonWithUnderline text={translate("link-to-admin-presentations-form")} to={PAGE_ROUTES.AdminPresentationFormCatalog} invalidateCaches={invalidateCaches} color="#f2e7dc" override={PAGE_ROUTES.AdminPresentationForm} />
        </Accordion>
      </Accordion>
    </Grid>
  );
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function SidePanel(props) {
  const translate = props.translator;
  const dispatch = useDispatch();

  const currentLocation = window.location.pathname;
  let defaultState = null;

  if (currentLocation.startsWith(PAGE_ROUTES.Admin))
    defaultState = 1;
  else if (currentLocation.startsWith(PAGE_ROUTES.Entity))
    defaultState = 0;

  const [openAccordion, setOpenAccordion] = useState(defaultState);

  const invalidateCaches = (e, handler) => {
    dispatch(clearCatalogCache());
    dispatch(clearEntityCredentialsCache());
    dispatch(clearEntityPresentationsCache());
    dispatch(clearCustomerCache());
    dispatch(clearCredentialsCache());
    dispatch(clearPresentationsCache());
    handler(e);
  };

const handleAccordionChange = (accordionIndex) => {
  if (openAccordion === accordionIndex) {
    setOpenAccordion(-1);
    return;
  }

  setOpenAccordion(accordionIndex);
}

  return (
    <Grid container direction="column" justifyContent="flex-start">
      <EntityAccordion isOpen={openAccordion === 0} onChange={() => handleAccordionChange(0)} translate={translate} invalidateCaches={invalidateCaches} />
      <AdminAccordion isOpen={openAccordion === 1} onChange={() => handleAccordionChange(1)} translate={translate} invalidateCaches={invalidateCaches} />
    </Grid>
  );
}
