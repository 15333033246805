import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import config from '../config/config';
import { NETWORK_IDS, TECHNOLOGIES, TRUST_FRAMEWORKS, VALID_ID } from '../config/constants.config';
import { get, getResponseJson, isOk, post } from './axios.helper';

const { SignJWT } = require('jose')
const { ethers } = require("ethers");

const abi = [{
  "constant": false,
  "inputs": [{
    "internalType": "bytes",
    "name": "addPublicKeyCallData",
    "type": "bytes"
  }],
  "name": "createAlastriaIdentity",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}]

const delay = ms => new Promise(res => setTimeout(res, ms));

const generateAlastriaDidSubject = (trustFrameWork, networkTechnology, networkId, id) => {
  if (!TRUST_FRAMEWORKS.includes(trustFrameWork) ||
    !TECHNOLOGIES.includes(networkTechnology) ||
    !NETWORK_IDS.includes(networkId) ||
    !VALID_ID.test(id)) {
      return null;
    }
  return `did:${trustFrameWork}:${networkTechnology}:${networkId}:${id}`
}

function makepass(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export default {

  downloadTxt({ filename, content }) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(content)));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  async signin(user) {
    let wallet = new ethers.Wallet(user.privateKey)
    return wallet
  },

  async createDigitalIdentity(code, mobileCode, publicKey) {


    try {
      const wallet = ethers.Wallet.createRandom()
      const aimData = await axios.get(`/contracts/AlastriaIdentityManager/redT/aimRedT`);
      const aim = await new ethers.Contract(aimData.data[0].address, abi, wallet);
      const addPublickeyCallData = await axios.post(`/contracts/AlastriaPublicKeyRegistry/addKey/encode`, { args: [publicKey] })
      const uTx = await aim.populateTransaction.createAlastriaIdentity(addPublickeyCallData.data);
      const tx = await wallet.signTransaction({ ...uTx, nonce: '0x0', gasLimit: '0xfffff', gasPrice: '0x0', chainId: 83584648538 })
      const password = makepass(8)
      const keystore = await wallet.encrypt(password)
      const response = await post('/identity', { signedTransaction: tx, invitationCode: code, mobileCode })

      if (isOk(response.status)) {
        const did = getResponseJson(await axios.post(`/contracts/AlastriaIdentityManager/redT/aimRedT/identityKeys/call`, { "args": [wallet.address] }))
        const alastriaId = generateAlastriaDidSubject(TRUST_FRAMEWORKS[0], TECHNOLOGIES[1], NETWORK_IDS[2], did.slice(2));

        if (alastriaId)
          return { status: response.status, privateKey: wallet.privateKey, did: alastriaId, keystore, password}
        else
          return { status: 400, message: "Cannot generate did"};
      }
      else return response
    } catch (e) { return e.response }
  },

  generateCreateIdentityCallData(publicKey) {
    const generatePublicKeyCallData = (publicKey) => {
      const abi = [{
        "constant": false,
        "inputs": [
          {
            "internalType": "string",
            "name": "publicKey",
            "type": "string"
          }
        ],
        "name": "addKey",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }];
      var iface = new ethers.utils.Interface(abi)
      const data = iface.encodeFunctionData('addKey', [publicKey])
      return data;
    }
    const abi = [{
      "constant": false,
      "inputs": [
        {
          "internalType": "bytes",
          "name": "addPublicKeyCallData",
          "type": "bytes"
        }
      ],
      "name": "createAlastriaIdentity",
      "outputs": [],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }];
    var iface = new ethers.utils.Interface(abi)
    const data = iface.encodeFunctionData('createAlastriaIdentity', [generatePublicKeyCallData(publicKey)])
    return data;
  },

  async createAlastriaId(web3, publicKey) {
    /*txCreateAlastriaID = transactionFactory.identityManager.createAlastriaIdentity(
      web3,
      publicKey.substr(2)
    )*/
    return 'txCreateAlastriaID'
  },

  async createPresentation({ verification }) {
    await this.signPresentation(verification)
  },

  async sign(credentials, verification) {
    try {
      let privateKey = await this.keyObjectFromHexKey(sessionStorage.getItem('privateKey'))
      //  const ecPrivateKey = crypto.createPrivateKey(privateKey)
      //  const pem = await ecPrivateKey.export({ type: 'sec1', format: 'pem' })
      const jwt = await new SignJWT({
        name: verification.name,
        motive: verification.motive,
        useCase: verification.useCase,
        iss: sessionStorage.getItem('did'),
        aud: "did:ala:eth:digitalis:85FDfA24F0431C502350cd60250bc2E620471fcA",
        exp: moment(verification.exp).unix(),
        nbf: moment(verification.nbf).unix(),
        iat: moment(new Date()).unix(),
        jti: uuidv4(),
        sub: verification.iss,
        vp: {
          "@context": [
            "https://www.w3.org/2018/credentials/v1",
            "https://alastria.github.io/identity/credentials/v1"
          ],
          type: [
            "VerifiablePresentation",
            "AlastriaPresentation",
            "DigitalisPresentation"
          ],
          verifiableCredential: credentials,
          iro: verification.jti
        }
      }).setProtectedHeader({
        alg: 'ES256',
        type: 'JWT',
        jwk: JSON.parse(sessionStorage.getItem('jwk'))
      }).sign(privateKey)
      sessionStorage.setItem('token', jwt)
      return jwt;

    } catch (exception) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(exception);
      }
      return exception.response
    }
  },

  async keyObjectFromHexKey(key) {
    //const buf = Buffer.from(sessionStorage.getItem('signingKey'), 'hex')
    try {
      //  const keyObject = await window.crypto.subtle.importKey('pkcs8', buf, {"name": "RSASSA-PKCS1-v1_5","hash": "SHA-256"}, true, ['sign'])
      // const keyObject = await window.crypto.subtle.importKey('jwk', JSON.parse(sessionStorage.getItem('jwk')), {"name": "RSASSA-PKCS1-v1_5","hash": "SHA-256"}, true, ['sign'])
      const keyObject = await window.crypto.subtle.importKey('jwk', JSON.parse(sessionStorage.getItem('jwk')), { "name": "ECDSA", "namedCurve": "P-256" }, true, ['sign'])

      return keyObject
    } catch (exception) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(exception);
      }
    }

  },

  str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

}