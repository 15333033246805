import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getResponseJson, isOk } from "../axios.helper";
import config from "../../config/config";
import { decodeAllJwts, mapCredentialRequests, mapCredentials } from "../jwt.helpers";
import { CREDENTIAL_STATUS, ROW_COUNT } from "../../config/constants.config";

const initialState = {
  value: [],
  isRequest: sessionStorage.getItem("entityCredentialsIsRequest") == null ? false : sessionStorage.getItem("entityCredentialsIsRequest") === 'true', 
  status: 1,
  page: 0,
  count: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchEntityCredentialsAsync = createAsyncThunk("entityCredentials/fetchCredentials", async ({ isRequest, page, search, credentialType, customer, status }) => {
  // count should be made user configurable.
  const limit = ROW_COUNT[0];
  const form = new URLSearchParams();
  let jwts = [];
  let endpoint = "/corporate/credential";
  let customerFilter = "issuer";
  let mappingFunc = mapCredentials;

  if (isRequest) {
    endpoint = "/corporate/credentialRequest";
    customerFilter = "subject";
    mappingFunc = mapCredentialRequests;
  }

  let count = 0;
  if (config.mocked) {
    jwts = isRequest ? config.mockedData.entityCredentials : config.mockedData.credentials;
    count = jwts.length;
  } else {
    form.append("offset", limit * page);
    form.append("limit", limit);
    form.append("isDeleted", false);

    if (credentialType) {
      form.append("type", credentialType);
    }

    if (search) {
      form.append("search", search);
    }

    if (customer) {
      form.append(customerFilter, customer);
    }

    if (status) {
      switch (status) {
        case CREDENTIAL_STATUS[0]:
          form.append("status", status);
          form.append("expiresAfter", Math.floor(Date.now() / 1000));
          break;
        case CREDENTIAL_STATUS[2]:
          form.append("status", CREDENTIAL_STATUS[0]);
          form.append("expiredFrom", Math.floor(Date.now() / 1000));
          break;
        default:
          form.append("status", status);
          break;
      }
    }

    const response = await get(endpoint, form);

    if (isOk(response.status)) {
      jwts = getResponseJson(response);
      count = Number(response.headers["x-app-total-count"]) || 0;
    }
  }

  const value = await mappingFunc(decodeAllJwts(jwts), true);
  return { isRequest, page, count, value };
});

export const entityCredentialsSlice = createSlice({
  name: "entityCredentials",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearEntityCredentialsCache: (state) => {
      state.status = 1;
      state.value = [];
    },

    setEntityCredentialsIsRequest: (state, action) => {
      sessionStorage.setItem("entityCredentialsIsRequest", action.payload);
      state.isRequest = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntityCredentialsAsync.pending, (state) => {
        state.status = 2;
      })
      .addCase(fetchEntityCredentialsAsync.fulfilled, (state, action) => {
        state.status = 0;
        const payload = action.payload;
        state.isRequest = payload.isRequest;
        state.value = payload.value;
        state.page = payload.page;
        state.count = payload.count;
      });
  },
});

export const { clearEntityCredentialsCache, setEntityCredentialsIsRequest } = entityCredentialsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectEntityCredentials = (state) => state.entityCredentials.value;
export const selectEntityCredentialsState = (state) => state.entityCredentials.status;
export const selectEntityCredentialsIsRequest = (state) => state.entityCredentials.isRequest;
export const selectEntityCredentialsPage = (state) => state.entityCredentials.page;
export const selectEntityCredentialsCount = (state) => state.entityCredentials.count;

export default entityCredentialsSlice.reducer;
