export default {
  credentials: [
    {
      jti: "68db6e84-1275-4f04-bcdb-c1f6998b227f",
      createdAt: "2023-03-10T14:05:19.684+00:00",
      signedToken:
        "eyJhbGciOiJFUzI1NksiLCJqd2siOnsia3R5IjoiRUMiLCJ4IjoiUU9ZaWQteVc0STNmeTNlUnNEelpyTzBzb1FqTTJGRzZQREF6NVN2LXZIOCIsInkiOiJXd3BjQTIyZ0RSX3RTUEY3U2txNnlUdDNDV2U0QmxxN0xFM0RRM2dzUmRJIiwiY3J2Ijoic2VjcDI1NmsxIn0sImtpZCI6ImRlZmF1bHQifQ.eyJAY29udGV4dCI6Imh0dHBzOi8vd3d3LnZlaWEuZXMvMjAyMy9qd3QvY29udGV4dC92MSIsIiRzY2hlbWEiOiJodHRwczovL3d3dy52ZWlhLmVzLzIwMjMvand0L3NjaGVtYS92MSIsIm5iZiI6MTY3NzQ4NDgwMCwiZXhwIjoxNjgwMjQ2MDAwLCJnd3UiOiJodHRwOi8vbG9jYWxob3N0IiwianRpIjoiMWJhN2FjZWItZDVkYi00ZWVmLTgyN2QtOWJmZTI5NDg5ZDVhIiwiaXNzIjoiZGlkOnZlaWE6YmVzdTp2ZWlhOjUxNzU4MThiNTlERDM0ODE2NTMxZjBlMTc2NmM0OWU5MzViN2ZkN0YiLCJ2YyI6eyJAY29udGV4dCI6WyJodHRwczovL3d3dy53My5vcmcvMjAxOC9jcmVkZW50aWFscy92MS4iLCJodHRwczovL3d3dy53My5vcmcvMjAxOC9jcmVkZW50aWFscy9leGFtcGxlcy92MSJdLCJ0eXBlIjpbIlZlcmlmaWFibGVDcmVkZW50aWFsIiwiY2MxNGViZGQtYjBkYi00MjZkLWI2NDktMzg0YjE3MDU1NDA5Il0sImlkIjoidW5kZWZpbmVkLzFiYTdhY2ViLWQ1ZGItNGVlZi04MjdkLTliZmUyOTQ4OWQ1YSIsImp0aSI6IjFiYTdhY2ViLWQ1ZGItNGVlZi04MjdkLTliZmUyOTQ4OWQ1YSIsImlzc3VlciI6ImRpZDp2ZWlhOmJlc3U6dmVpYTo1MTc1ODE4YjU5REQzNDgxNjUzMWYwZTE3NjZjNDllOTM1YjdmZDdGIiwiaXNzdWFuY2VEYXRlIjoiMjAyMy0wMy0xMFQxMjozMDoxMS41MDZaIiwidmFsaWRGcm9tIjoxNjc3NDg0ODAwLCJ2YWxpZFVudGlsIjoxNjgwMjQ2MDAwLCJjcmVkZW50aWFsU3ViamVjdCI6IntcImlkXCI6XCJkaWQ6YWxhOnF1b3I6cmVkVDoxMmNiMDg0OGFFNDVFMzM5MjEyODM2MjM4NzE2MjAyMWM5ZGY1NzZmXCIsXCJsZXZlbE9mQXNzdXJhbmNlXCI6MixcImVtcGxveWVlQWNjZXNzTDFcIjpcIjEyXCJ9IiwiY3JlZGVudGlhbFNjaGVtYSI6Imh0dHBzOi8vanNvbi1zY2hlbWEub3JnL2RyYWZ0LzIwMjAtMTIvc2NoZW1hIn0sImlhdCI6MTY3ODQ1MTQxMX0.B7bYfqhCQZivHa9bBQtOY-DGn3ADQy7vCPSd9u9dEMunqTnlft7N3RYeNpsTJXB4k-Cqo1YHQTGi87CSeuwq-Q",
      status: "Valid",
      fileUri: null,
      transactionHash: "",
      jwt: '{"vc": {"id": "undefined/68db6e84-1275-4f04-bcdb-c1f6998b227f", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "type": ["VerifiableCredential", "cc14ebdd-b0db-426d-b649-384b17055409"], "issuer": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "@context": ["https://www.w3.org/2018/credentials/v1.", "https://www.w3.org/2018/credentials/examples/v1"], "validFrom": 1678435200, "validUntil": 1679040000, "issuanceDate": "2023-03-10T14:05:03.377Z", "credentialSchema": "https://json-schema.org/draft/2020-12/schema", "credentialSubject": "{\\"id\\":\\"did:ala:quor:redT:FCC9d35Da068e87478bBf7A5F6e6F3F69A051747\\",\\"levelOfAssurance\\":0,\\"employeeAccessL1\\":\\"98\\"}"}, "exp": 1679040000, "gwu": "http://localhost", "iat": 1678457103, "iss": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "nbf": 1678435200, "$schema": "https://www.veia.es/2023/jwt/schema/v1", "@context": "https://www.veia.es/2023/jwt/context/v1"}',
    },
  ],

  entityCredentials: [
    {
      jti: "68db6e84-1275-4f04-bcdb-c1f6998b227f",
      createdAt: "2023-03-10T14:05:19.684+00:00",
      signedToken:
        "eyJhbGciOiJFUzI1NksiLCJqd2siOnsia3R5IjoiRUMiLCJ4IjoiZE9ickxfSHNjVlJhbVo1blpyV2FMN2o1eW5xVl9ub3ZpOWRXbkZ2M1lFYyIsInkiOiJEUnpvUjFVOG84aGJ5czUwczZMb21tcVpxVG9JTUwzVUNZUVdMR3owc3lJIiwiY3J2Ijoic2VjcDI1NmsxIn0sImtpZCI6ImRlZmF1bHQifQ.eyJAY29udGV4dCI6Imh0dHBzOi8vd3d3LnZlaWEuZXMvMjAyMy9qd3QvY29udGV4dC92MSIsIiRzY2hlbWEiOiJodHRwczovL3d3dy52ZWlhLmVzLzIwMjMvand0L3NjaGVtYS92MSIsImd3dSI6Imh0dHA6Ly9sb2NhbGhvc3QiLCJjYnUiOiJodHRwOi8vY2J1LmNvbSIsImlzcyI6IiIsInN1YiI6IiIsInZjciI6eyJAY29udGV4dCI6Imh0dHBzOi8vd3d3LnczLm9yZy8yMDE4L2NyZWRlbnRpYWxzL2V4YW1wbGVzL3YxIiwidHlwZSI6WyJWZXJpZmlhYmxlQ3JlZGVudGlhbCIsIkFsdW1uaU9mIl0sImRpZE1ldGhvZHMiOlsiZGlkOnZlaWE6YmVzdTp2ZWlhIl0sImZpZWxkX25hbWUiOiJBbHVtbmlPZiIsInJlcXVpcmVkIjp0cnVlLCJsZXZlbE9mQXNzdXJhbmNlIjoyLCJmaWxlIjoiaHR0cHM6Ly9wcml2YXRlLXNlcnZlci9maWxlMDAwMSJ9LCJpYXQiOjE2Nzk1NjMxNTh9.p9RgnNBMkuza2WEvIf_7iyq3ec5ZgBqyAD3EjUYxYbGBW4s4gH7vawFlLIS_g0tVBMr0MId3yrX1-ybSGr5hGA",
      status: "Valid",
      fileUri: null,
      transactionHash: "",
      jwt: '{"vc": {"id": "undefined/68db6e84-1275-4f04-bcdb-c1f6998b227f", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "type": ["VerifiableCredential", "cc14ebdd-b0db-426d-b649-384b17055409"], "issuer": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "@context": ["https://www.w3.org/2018/credentials/v1.", "https://www.w3.org/2018/credentials/examples/v1"], "validFrom": 1678435200, "validUntil": 1679040000, "issuanceDate": "2023-03-10T14:05:03.377Z", "credentialSchema": "https://json-schema.org/draft/2020-12/schema", "credentialSubject": "{\\"id\\":\\"did:ala:quor:redT:FCC9d35Da068e87478bBf7A5F6e6F3F69A051747\\",\\"levelOfAssurance\\":0,\\"employeeAccessL1\\":\\"98\\"}"}, "exp": 1679040000, "gwu": "http://localhost", "iat": 1678457103, "iss": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "nbf": 1678435200, "$schema": "https://www.veia.es/2023/jwt/schema/v1", "@context": "https://www.veia.es/2023/jwt/context/v1"}',
    },
  ],

  invitations: [
    {
      businessName: "GT",
      email: "example0@example.com",
      nif: "123456789012",
      tel: "987654321",
      type: "Email",
      status: "PENDING",
      customerId: "did:ala:quor:redT:6d41bdde717573aef4c40ea0264e1a8d32E7B14C",
    },
  ],

  catalog: [
    {
      catalogId: "cc14ebdd-b0db-426d-b649-384b17055409",
      name: "escritura de constitucion y poderes",
      issuer: "Registro Mercantil",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eleifend arcu dui, eu scelerisque magna dictum ac. Fusce ipsum sapien, congue eu felis vitae, vehicula.",
      format: "Lorem Ipsum",
      useCase: "Identidad Digital Mínima",
      holder: "Persona Júridica",
      creator: "TODO",
      type: "AlumniOf",
      template: '{"degree":"string","studentId":"int","photo":"file"}',
      status: "ACCEPTED",
    },

    {
      catalogId: "AlumniOf",
      name: "Carnet B1",
      issuer: "Registro Mercantil",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eleifend arcu dui, eu scelerisque magna dictum ac. Fusce ipsum sapien, congue eu felis vitae, vehicula.",
      format: "Lorem Ipsum",
      useCase: "Identidad Digital Mínima",
      holder: "Persona Júridica",
      creator: "TODO",
      type: "AlumniOf",
      template: '{"degree":"string"}',
      status: "PENDING",
    },
  ],

  presentationRequests: [
    {
      jti: "68db6e84-1275-4f04-bcdb-c1f6998b227f",
      createdAt: "2023-03-10T14:05:19.684+00:00",
      signedToken:
        "eyJhbGciOiJFUzI1NksiLCJqd2siOnsia3R5IjoiRUMiLCJ4IjoiZE9ickxfSHNjVlJhbVo1blpyV2FMN2o1eW5xVl9ub3ZpOWRXbkZ2M1lFYyIsInkiOiJEUnpvUjFVOG84aGJ5czUwczZMb21tcVpxVG9JTUwzVUNZUVdMR3owc3lJIiwiY3J2Ijoic2VjcDI1NmsxIn0sImtpZCI6ImRlZmF1bHQifQ.eyJAY29udGV4dCI6Imh0dHBzOi8vd3d3LnZlaWEuZXMvMjAyMy9qd3QvY29udGV4dC92MSIsIiRzY2hlbWEiOiJodHRwczovL3d3dy52ZWlhLmVzLzIwMjMvand0L3NjaGVtYS92MSIsImp0aSI6IjA1ZWUzOTU4LTlkMjctNDNkOC1hMzNjLWQxYmE5NTI4YThmYiIsImlzcyI6IiIsImV4cCI6OTk4MzIwMDI4OTExLCJuYmYiOjk5ODMyMDAyODkxMSwiY2J1IjoiaHR0cDovL2NidS5jb20iLCJwX2V4cCI6OTk4MzIwMDI4OTExLCJwciI6eyJAY29udGV4dCI6WyJodHRwczovL3d3dy53My5vcmcvMjAxOC9jcmVkZW50aWFscy9leGFtcGxlcy92MSJdLCJ1cmwiOiIvZXhhbXBsZS1uYW1lIiwiaWQiOiIjMDVlZTM5NTgtOWQyNy00M2Q4LWEzM2MtZDFiYTk1MjhhOGZiIiwidHlwZSI6WyJGb3JtIl0sInByb2NIYXNoIjoiOTg4ODk4OTlhYWFiYmI5OTkiLCJwcm9jVXJsIjoiaHR0cDovL3Byb2MuZXMiLCJwcm9jRGVzYyI6IlRoZSBhaW0gb2YgdGhlIHByb2N1cmVtZW50IiwianRpIjoiMDVlZTM5NTgtOWQyNy00M2Q4LWEzM2MtZDFiYTk1MjhhOGZiIiwiZGF0YSI6W3siQGNvbnRleHQiOiJodHRwczovL3d3dy53My5vcmcvMjAxOC9jcmVkZW50aWFscy9leGFtcGxlcy92MSIsInR5cGUiOlsiVmVyaWZpYWJsZUNyZWRlbnRpYWwiLCJBbHVtbmlPZiJdLCJkaWRNZXRob2RzIjpbImRpZDp2ZWlhOmJlc3U6dmVpYSJdLCJmaWVsZF9uYW1lIjoiQWx1bW5pT2YiLCJyZXF1aXJlZCI6dHJ1ZSwibGV2ZWxPZkFzc3VyYW5jZSI6MiwiZmlsZSI6Imh0dHBzOi8vcHJpdmF0ZS1zZXJ2ZXIvZmlsZTAwMDEifV19LCJpYXQiOjE2Nzk1NjMwNjl9.MFRsTnDk_HCGoDh89uZ7xvMnVlWT0AicmJdDuMyVlMueGxlQ6aR7xOP9Cntvt5lxT1gwuXbGqU_wq7RZ_H6f2Q",
      status: "Valid",
      fileUri: null,
      transactionHash: "",
      jwt: '{"vc": {"id": "undefined/68db6e84-1275-4f04-bcdb-c1f6998b227f", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "type": ["VerifiableCredential", "cc14ebdd-b0db-426d-b649-384b17055409"], "issuer": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "@context": ["https://www.w3.org/2018/credentials/v1.", "https://www.w3.org/2018/credentials/examples/v1"], "validFrom": 1678435200, "validUntil": 1679040000, "issuanceDate": "2023-03-10T14:05:03.377Z", "credentialSchema": "https://json-schema.org/draft/2020-12/schema", "credentialSubject": "{\\"id\\":\\"did:ala:quor:redT:FCC9d35Da068e87478bBf7A5F6e6F3F69A051747\\",\\"levelOfAssurance\\":0,\\"employeeAccessL1\\":\\"98\\"}"}, "exp": 1679040000, "gwu": "http://localhost", "iat": 1678457103, "iss": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "nbf": 1678435200, "$schema": "https://www.veia.es/2023/jwt/schema/v1", "@context": "https://www.veia.es/2023/jwt/context/v1"}',
    },
  ],

  presentations: [
    {
      jti: "68db6e84-1275-4f04-bcdb-c1f6998b227f",
      createdAt: "2023-03-10T14:05:19.684+00:00",
      signedToken:
        "eyJhbGciOiJFUzI1NksiLCJqd2siOnsia3R5IjoiRUMiLCJ4IjoiUU9ZaWQteVc0STNmeTNlUnNEelpyTzBzb1FqTTJGRzZQREF6NVN2LXZIOCIsInkiOiJXd3BjQTIyZ0RSX3RTUEY3U2txNnlUdDNDV2U0QmxxN0xFM0RRM2dzUmRJIiwiY3J2Ijoic2VjcDI1NmsxIn0sImtpZCI6ImRlZmF1bHQifQ.eyJAY29udGV4dCI6Imh0dHBzOi8vd3d3LnZlaWEuZXMvMjAyMy9qd3QvY29udGV4dC92MSIsIiRzY2hlbWEiOiJodHRwczovL3d3dy52ZWlhLmVzLzIwMjMvand0L3NjaGVtYS92MSIsIm5iZiI6MTY3ODg3ODE3MSwiZXhwIjoxNjgxNDcwMTcxLCJnd3UiOiJodHRwOi8vbG9jYWxob3N0IiwianRpIjoiNTEwZmNjYWUtMWUxZC00MTYxLTlkYjktNDI4ZWFlZjIxMTZhIiwiaXNzIjoiZGlkOmFsYTpxdW9yOnJlZFQ6NTE3NTgxOGI1OUREMzQ4MTY1MzFmMGUxNzY2YzQ5ZTkzNWI3ZmQ3RiIsInZwIjp7IkBjb250ZXh0IjpbImh0dHBzOi8vd3d3LnczLm9yZy8yMDE4L2NyZWRlbnRpYWxzL3YxLiIsImh0dHBzOi8vd3d3LnczLm9yZy8yMDE4L2NyZWRlbnRpYWxzL2V4YW1wbGVzL3YxIl0sInR5cGUiOlsiVmVyaWZpYWJsZVByZXNlbnRhdGlvbiJdLCJqdGkiOiI1MTBmY2NhZS0xZTFkLTQxNjEtOWRiOS00MjhlYWVmMjExNmEiLCJ2ZXJpZmlhYmxlQ3JlZGVudGlhbCI6WyJzdHJpbmciXX0sImlhdCI6MTY3ODk1OTU3N30.pV32ExMYddTC6xO4mjQmm7bZ4PvcIZ6PGziI5-poAkqCYgOuGK2XbQhi9Pgs5LPUXS4sEEiAW3ZETshr91snGQ",
      status: "Valid",
      fileUri: null,
      transactionHash: "",
      jwt: '{"vc": {"id": "undefined/68db6e84-1275-4f04-bcdb-c1f6998b227f", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "type": ["VerifiableCredential", "cc14ebdd-b0db-426d-b649-384b17055409"], "issuer": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "@context": ["https://www.w3.org/2018/credentials/v1.", "https://www.w3.org/2018/credentials/examples/v1"], "validFrom": 1678435200, "validUntil": 1679040000, "issuanceDate": "2023-03-10T14:05:03.377Z", "credentialSchema": "https://json-schema.org/draft/2020-12/schema", "credentialSubject": "{\\"id\\":\\"did:ala:quor:redT:FCC9d35Da068e87478bBf7A5F6e6F3F69A051747\\",\\"levelOfAssurance\\":0,\\"employeeAccessL1\\":\\"98\\"}"}, "exp": 1679040000, "gwu": "http://localhost", "iat": 1678457103, "iss": "did:ala:quor:redT:5175818b59DD34816531f0e1766c49e935b7fd7F", "jti": "68db6e84-1275-4f04-bcdb-c1f6998b227f", "nbf": 1678435200, "$schema": "https://www.veia.es/2023/jwt/schema/v1", "@context": "https://www.veia.es/2023/jwt/context/v1"}',
    },
  ],
};
