// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_IS_HIDDEN = false;
const DEFAULT_CHILDREN = null;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function Hider(props) {
  const isHidden = props.isHidden || DEFAULT_IS_HIDDEN;
  const children = props.children || DEFAULT_CHILDREN;

  if (isHidden) {
    return <></>;
  }

  return children;
}