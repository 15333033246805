import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MapfreIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 268.24, height: 44.75 }}>
      <path className="cls-1" d="M72.19,22.89V37.33c-.21,0-.38,0-.55,0-3.16,0-6.33,0-9.49,0-.82,0-.82,0-.82-.86V10.87c0-.82,0-1.65,0-2.47,0-.39.12-.5.52-.5,3.3,0,6.6,0,9.91,0a.67.67,0,0,1,.7.48l3.48,7.15C77,17.77,78.12,20,79.22,22.24c0,.09.09.17.2.37.34-.66.63-1.24.92-1.82q2.43-5,4.87-10c.42-.86.83-1.73,1.28-2.58A.72.72,0,0,1,87,7.92c3.34,0,6.68,0,10,0,.37,0,.49.14.48.49s0,.84,0,1.26v27c0,.77,0,.77-.76.77-3.15,0-6.29,0-9.44,0-.55,0-.66-.16-.66-.68,0-4.53,0-9.06,0-13.58,0-.07,0-.14-.17-.25-2.33,4.77-4.67,9.54-7.07,14.46L72.34,22.85Z" />
      <path className="cls-1" d="M137.16,37.37h-.65c-3.18,0-6.36,0-9.54,0a.7.7,0,0,1-.83-.62c-.33-1.25-.71-2.48-1.06-3.73a.56.56,0,0,0-.64-.51c-3.59,0-7.17,0-10.75,0-.39,0-.47.21-.55.5-.38,1.32-.77,2.64-1.14,4a.46.46,0,0,1-.52.39H101.31s-.1,0-.25-.06c.18-.66.36-1.3.54-2q1.55-5.37,3.1-10.76,1.6-5.61,3.22-11.22.72-2.46,1.4-4.93A.62.62,0,0,1,110,7.9q9.06,0,18.1,0a.67.67,0,0,1,.76.58q2.07,7.3,4.18,14.59l4,14A2.45,2.45,0,0,1,137.16,37.37Zm-18-24.83H119l-4.34,15.08h8.84Z" />
      <path className="cls-1" d="M228.71,22.62a7.5,7.5,0,0,1,6.48,7.62v7.13h-.63c-3.13,0-6.26,0-9.39,0-.55,0-.67-.18-.67-.69,0-2.25,0-4.51,0-6.76a4.59,4.59,0,0,0-2.39-4.1,4.66,4.66,0,0,0-1.76-.6c-1-.1-2,0-3,0,0,.17,0,.37,0,.57v11c0,.31,0,.57-.44.57H206.56a2.27,2.27,0,0,1-.3,0c0-.19,0-.38,0-.56q0-14.16,0-28.32c0-.41.11-.55.53-.55,7,0,14.06,0,21.08,0a7.3,7.3,0,0,1,7.35,7,7.38,7.38,0,0,1-5.71,7.53C229.21,22.48,229,22.55,228.71,22.62ZM217.28,20.1h2.37a4.71,4.71,0,0,0,3.94-2,4.33,4.33,0,0,0,.3-4.87,4.13,4.13,0,0,0-3-1.82c-1-.14-2.09-.08-3.13-.14-.37,0-.49.1-.49.47,0,1.54,0,3.08,0,4.62Z" />
      <path className="cls-1" d="M268.2,20.24V25c-.19,0-.37,0-.56,0H250.86c-.71,0-.71,0-.71.72,0,2.06,0,4.12,0,6.19,0,.49.16.61.63.61,5.56,0,11.12,0,16.68,0,.79,0,.79,0,.79.8v4l-.59,0H239.85c-.46,0-.55-.16-.55-.58q0-14.07,0-28.16c0-.73,0-.73.8-.73h27.58c.42,0,.55.13.55.54,0,1.24,0,2.48,0,3.72,0,.42-.14.54-.54.54-3,0-5.91,0-8.86,0-2.75,0-5.49,0-8.24,0-.34,0-.47.1-.47.45q0,3.33,0,6.66c0,.38.15.47.5.47l16.78,0Z" />
      <path className="cls-1" d="M140.7,22.67v-14c0-.77,0-.77.78-.77,6.6,0,13.21,0,19.82,0a8.58,8.58,0,0,1,3.76,16.17,8,8,0,0,1-3.81,1c-3,0-6.05,0-9.07,0-.51,0-.65.14-.65.65,0,3.65,0,7.31,0,11,0,.69,0,.69-.68.69-3.11,0-6.22,0-9.33,0-.9,0-.82.11-.83-.8,0-.28,0-.56,0-.84V22.67Zm11-2.57h2.42a4.69,4.69,0,0,0,3.36-1.35c2.06-2,2.27-6-1.38-7.18a13.23,13.23,0,0,0-4.16-.29c-.08,0-.23.29-.24.45,0,1.56,0,3.11,0,4.67Z" />
      <path className="cls-1" d="M202.39,12.71h-18a4.36,4.36,0,0,0,0,.45c0,2.17,0,4.34,0,6.5,0,.47.14.59.59.59h16.63l.84,0v.55c0,1.19,0,2.38,0,3.57,0,.57-.2.65-.69.65-1.55,0-3.11,0-4.66,0H185c-.7,0-.7,0-.7.68V37.37h-1c-3.11,0-6.22,0-9.33,0-.45,0-.6-.1-.6-.58q0-14.16,0-28.31c0-.47.14-.6.59-.59h27.74c.47,0,.64.12.63.61C202.37,9.87,202.39,11.23,202.39,12.71Z" />
      <path className="cls-1" d="M44.75,22.35A22.38,22.38,0,1,1,22.53,0,22.4,22.4,0,0,1,44.75,22.35ZM22.3,42.62A20.27,20.27,0,1,0,2.08,22,20.26,20.26,0,0,0,22.3,42.62Z" />
      <path className="cls-1" d="M29,26.68c-1.59,0-3.17,0-4.76,0-.61,0-.84.17-.83.81,0,1.71,0,3.43,0,5.15,0,.67-.18.94-.89.92-1.29,0-1.29,0-1.3-1.29,0-1.56,0-3.12,0-4.68,0-.78-.27-.92-1-.91-3,0-6.08,0-9.12,0a3.31,3.31,0,0,1-3.48-4.34,12.11,12.11,0,0,1,4.16-7,6.07,6.07,0,0,1,2.29-.9c.43-.1.75-.11,1-.59a3.32,3.32,0,0,1,2-1.77A15.22,15.22,0,0,1,27.44,12a4.23,4.23,0,0,1,2.14,1.8,1.15,1.15,0,0,0,.81.5,3.46,3.46,0,0,1,2.33.86A12.84,12.84,0,0,1,37,22.39a3.33,3.33,0,0,1-3.49,4.29ZM22.39,13.15c-1.43.31-2.93.57-4.39,1a1.35,1.35,0,0,0-.85,2.12c1.45,2.52,2.93,5,4.35,7.53.6,1.06,1.22.92,1.76,0,1.41-2.47,2.85-4.93,4.26-7.39.64-1.12.33-2-.92-2.31S23.89,13.46,22.39,13.15ZM14.46,24.5c1.24,0,2.49,0,3.73,0,.76,0,1-.37.66-1-1.2-2.13-2.42-4.26-3.67-6.37a1.06,1.06,0,0,0-1.75-.3,10.21,10.21,0,0,0-3.63,6.3c-.16.9.31,1.37,1.25,1.38C12.19,24.51,13.33,24.5,14.46,24.5Zm15.72,0h0c1.14,0,2.28-.08,3.4,0a1.25,1.25,0,0,0,1.34-1.69,10.79,10.79,0,0,0-3.46-5.89,1.13,1.13,0,0,0-2,.29Q27.7,20.31,26,23.39c-.4.72-.17,1.09.66,1.1C27.8,24.51,29,24.5,30.18,24.5Z" />
    </SvgIcon>
  )
}