import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, getResponseJson, isOk } from "../axios.helper";
import { REDUCER_STATUS } from "../../config/constants.config";

const initialState = {
  value: {},
  status: REDUCER_STATUS.NEEDS_REFRESH,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCorporateAsync = createAsyncThunk("corporate/fetchCorporateAsync", async () => {
  try {
    const whoAmIResponse = await get("/corporate");

    if (!isOk(whoAmIResponse.status)) {
      return {};
    }

    const whoAmI = getResponseJson(whoAmIResponse);

    var urlForm = new URLSearchParams();
    urlForm.append("did", whoAmI.did);
    const customerResponse = await get("/customer", urlForm);
  
    if (!isOk(customerResponse.status)) {
      return {};
    }

    const customer = getResponseJson(customerResponse)[0];

    if (customer) {
      return { ...whoAmI, ...customer };
    }
  }
  catch (exception) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      console.log(exception);
    }
  }
});

export const corporateSlice = createSlice({
  name: "corporate",
  initialState,
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorporateAsync.pending, (state) => {
        state.status = REDUCER_STATUS.LOADING;
      })
      .addCase(fetchCorporateAsync.fulfilled, (state, action) => {
        state.status = REDUCER_STATUS.READY;
        state.value = action.payload;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.catalog.value)`
export const selectWhoAmI = (state) => state.corporate.value;
export const selectWhoAmIStatus = (state) => state.corporate.status;

export default corporateSlice.reducer;
