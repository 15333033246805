// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useLinkClickHandler, Link, useNavigate } from 'react-router-dom';

import { useLoaderData } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

import crypto from 'crypto-browserify';

// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS, TRUST_FRAMEWORKS, TECHNOLOGIES, NETWORK_IDS, PAGE_ROUTES, LEVELS_OF_ASSURANCE } from '../../../../config/constants.config';

import { getResponseJson, isOk, post, get, customFilePost } from '../../../../helpers/axios.helper';
import { fetchCatalogAsync, selectCatalogState, selectCatalog } from '../../../../helpers/reducers/catalog';
import { decodeAllJwts, mapCredentialRequests, sign } from '../../../../helpers/jwt.helpers';
import { clearCredentialsCache } from '../../../../helpers/reducers/adminCredentials';
import { selectWhoAmI } from '../../../../helpers/reducers/corporate';

import Dropdown from '../../../../components/shared/Dropdowns/Dropdown.component';
import DynamicForm from '../../../../components/shared/Forms/DynamicForm.component';
import DatePicker from '../../../../components/shared/DatePicker/DatePicker.component';
import SpinnerLoader from '../../../../components/shared/Loader/SpinnerLoader.component';
import WarningBox from '../../../../components/shared/DialogBoxes/WarningBox.component.js';
import Hider from '../../../../components/shared/Hider/Hider.component';

import config from '../../../../config/config';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function onFormSubmit(form) {
  if (config.mocked) {
    const jwt = await sign(form);
    const signCredential = { jwt, status: 'OK' };
    config.mockedData.credentials.push(signCredential);
    return { status: 200 };
  }

  return await post('/dit/credential', form);
}

async function loadCredentialRequest(credentialRequestId, setCredentialRequestLoading, setCredentialRequest, setErrorPopup) {
  setCredentialRequestLoading(true);

  try {
    if (credentialRequestId) {
      const response = await get(`/dit/credentialRequest/${credentialRequestId}`);

      if (isOk) {
        const credentials = await mapCredentialRequests(decodeAllJwts([getResponseJson(response)]), false);

        if (credentials.length > 0) {
          setCredentialRequest(credentials[0]);
        }
      }
    }
  } catch (exception) {
    setErrorPopup(exception);
  }

  setCredentialRequestLoading(false);
}

async function loadUsers(setUsersLoading, setUsers, setErrorPopup) {
  setUsersLoading(true);

  try {
    var urlForm = new URLSearchParams();
    urlForm.append('status', CUSTOMER_STATUS[1]);
    const response = await get('/customer', urlForm);

    if (isOk(response.status)) {
      const users = getResponseJson(response);
      setUsers(users);
    }
  } catch (exception) {
    setErrorPopup(exception);
  }

  setUsersLoading(false);
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export async function createAdminCredentialLoader({ request }) {
  const url = new URL(request.url);
  return url.searchParams.get('id');
}

export default function CreateAdminCredential(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);
  const whoAmI = useSelector(selectWhoAmI);

  const credentialRequestId = useLoaderData();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [credential, setCredential] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [levelOfAssurance, setLevelOfAssurance] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dynamicFormState, setFormState] = useState({});
  const [isCredentialRequestLoading, setCredentialRequestLoading] = useState(undefined);
  const [credentialRequest, setCredentialRequest] = useState(undefined);
  const [isUsersLoading, setIsUsersLoading] = useState(undefined);
  const [users, setUsers] = useState(undefined);

  const kid = 'default';
  const catalogLoading = catalogState === 2;
  const catalogNeedsReload = catalogState === 1;

  if (catalogNeedsReload) {
    dispatch(fetchCatalogAsync({ limit: null }));
  }

  if (isCredentialRequestLoading === undefined) {
    loadCredentialRequest(credentialRequestId, setCredentialRequestLoading, setCredentialRequest, setErrorPopup);
  }

  if (isCredentialRequestLoading === false && credentialRequest !== undefined && credential === undefined) {
    const catalogEntry = credentialRequest.decoded.vcr.catalog;
    setCredential(catalogEntry);

    const template = JSON.parse(catalogEntry.template);
    const keys = Object.keys(template);
    const form = {};
    form[keys[0]] = credentialRequest.decoded.vcr.file;
    setFormState(form);
  }

  if (isUsersLoading === undefined) {
    loadUsers(setIsUsersLoading, setUsers, setErrorPopup);
  }

  if (isUsersLoading === false && credentialRequest !== undefined && user === undefined) {
    setUser(credentialRequest ? users.filter((user) => user.did === credentialRequest.decoded.iss)[0] : null);
  }

  const isFormInvalid = () => {
    return !kid || !credential || !user || levelOfAssurance === null || !startDate || !endDate;
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (isFormInvalid()) return;

    let form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid,
      validFrom: Math.round(startDate / 1000),
      validUntil: Math.round(endDate / 1000),
      credentialContext: 'https://www.w3.org/2018/credentials/examples/v1',
      credentialType: credential.type,
      credentialSubject: {
        id: user.did,
        levelOfAssurance: levelOfAssurance.level,
        ...dynamicFormState
      },
      credentialSchema: 'https://json-schema.org/draft/2020-12/schema'
    };

    if (credentialRequest) {
      const didMethods = credentialRequest.decoded.vcr.didMethods[0].split(':');
      form.trustFramework = didMethods[1];
      form.networkTechnology = didMethods[2];
      form.networkId = didMethods[3];
      form.credentialRequestJti = credentialRequest.storedJwt.jti;
    }

    setFormSendState(true);
    asyncFormSubmit(form, dynamicFormState);
  };

  const asyncFormSubmit = async (form, dynamicFormState) => {
    const keys = Object.keys(dynamicFormState);

    if (keys.length > 0 && typeof dynamicFormState[keys[0]] !== 'string') {
      let procHash = `0x0000000000000000000000000000000000000000000000000000000000000000`;

      const hash = crypto.createHash('sha256');
      hash.update(`${JSON.stringify(form)}${Date.now()}`);
      procHash = `0x${hash.digest().toString('hex')}`;

      const file = dynamicFormState[keys[0]];
      const fileUpload = await customFilePost(
        `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/api`,
        file,
        procHash
      );

      if (!isOk(fileUpload.status)) {
        setFormSendState(false);
        setWarningBoxOpen(false);
        setErrorPopup(fileUpload);
        return;
      }

      form.credentialSubject[
        keys[0]
      ] = `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/files/${fileUpload.uriSafeFileName}`;
    }

    form.credentialSubject = JSON.stringify(form.credentialSubject);
    const response = await onFormSubmit(form);

    if (isOk(response.status)) {
      dispatch(clearCredentialsCache());
      navigate(-1);
    } else {
      setFormSendState(false);
      setWarningBoxOpen(false);
      setErrorPopup(response);
    }
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid()) return;
    setWarningBoxOpen(true);
  };

  return (
    <Grid container className='create-admin-credential-view' direction='column' alignItems='stretch' spacing={2}>
      <Grid item>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: '#1B5255',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    'a:visited': {
                      textDecoration: 'none',
                      color: '#1B5255'
                    },
                    'a:link': { textDecoration: 'none' },
                    'a:hover': { textDecoration: 'none' },
                    'a:active': { textDecoration: 'none' }
                  }}>
                  <Link to={PAGE_ROUTES.AdminCredentials}>{translate('admin-credentials-page-title')}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: '#1B5255', fontSize: '2rem' }}>
                  {translate('admin-credentials-create-page-title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              sx={{
                height: '2.938rem',
                backgroundColor: '#1B5255',
                color: 'white',
                fontSize: '1.125rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1B5255',
                  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                }
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                {translate('admin-credentials-create-back')}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container justifyContent='space-evenly' alignItems='center'>
          <Grid item>
            <form onSubmit={validForm}>
              <Grid container direction='column' spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('admin-credentials-create-credential-name')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('admin-credentials-create-credential-name-placeholder')}
                  value={credential}
                  required
                  isLoading={catalogLoading}
                  disabled={credentialRequest !== undefined}
                  options={catalog}
                  getOptionLabel={(catalogItem) => catalogItem.name}
                  getOptionValue={(catalogItem) => catalogItem.type}
                  onChange={(catalogItem) => {
                    setCredential(catalogItem);
                    setFormState({});
                  }}
                  selectStyle={{
                    opacity: credentialRequest !== undefined ? '0.5' : '1',
                    width: '50rem',
                    borderRadius: '10px',
                    fontSize: '1.125rem'
                  }}
                />

                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('admin-credentials-create-receiver')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('admin-credentials-create-receiver-placeholder')}
                  value={user}
                  required
                  isLoading={isUsersLoading}
                  disabled={credentialRequest !== undefined}
                  options={users}
                  noOptionsMessage={!isUsersLoading && users === undefined ? translate('admin-credentials-create-no-users') : undefined}
                  getOptionLabel={(user) => user.businessName}
                  onChange={(user) => {
                    setUser(user);
                  }}
                  selectStyle={{
                    opacity: credentialRequest !== undefined ? '0.5' : '1',
                    width: '50rem',
                    borderRadius: '10px',
                    fontSize: '1.125rem'
                  }}
                />

                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('admin-credentials-create-level-of-assurance')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('admin-credentials-create-level-of-assurance-placeholder')}
                  value={levelOfAssurance}
                  required
                  options={LEVELS_OF_ASSURANCE.map((level) => {
                    return { level };
                  })}
                  getOptionLabel={(level) => translate(`level-of-assurance-${level.level}`)}
                  getOptionValue={(level) => level.level}
                  onChange={(level) => {
                    setLevelOfAssurance(level);
                  }}
                  selectStyle={{
                    width: '50rem',
                    borderRadius: '10px',
                    fontSize: '1.125rem'
                  }}
                />

                <Grid item>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                    {translate('admin-credentials-create-dates')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                            {translate('admin-credentials-create-start-date')}
                          </Typography>
                        </Grid>

                        <DatePicker
                          required
                          max={endDate}
                          value={startDate}
                          onChange={(unixTime) => setStartDate(unixTime)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction='column' spacing={1}>
                        <Grid item>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '1.125rem' }}>
                            {translate('admin-credentials-create-end-date')}
                          </Typography>
                        </Grid>

                        <DatePicker
                          required
                          min={Math.max(Date.now(), startDate)}
                          value={endDate}
                          onChange={(unixTime) => setEndDate(unixTime)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <DynamicForm
                  translate={translate}
                  formJson={credential ? credential.template || '{}' : '{}'}
                  format={credential ? credential.format : ''}
                  fixedValue={credentialRequest ? credentialRequest.decoded.vcr.file : undefined}
                  value={dynamicFormState}
                  onChange={setFormState}
                />

                <Grid item>
                  <Grid container justifyContent='flex-end' spacing={2}>
                    <Grid item>
                      <Hider isHidden={!credentialRequest || !credentialRequest.decoded.vcr || !credentialRequest.decoded.vcr.catalog || credentialRequest.decoded.vcr.catalog.format !== 'File'}>
                        <Button
                          target='_blank'
                          href={credentialRequest ? credentialRequest.decoded.vcr.file : ''}
                          sx={{
                            backgroundColor: '#1B5255',
                            color: 'white',
                            fontSize: '1.125rem',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: '#1B5255',
                              boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                            }
                          }}>
                          <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                            {translate('admin-credentials-create-document')}
                          </Typography>
                        </Button>
                      </Hider>
                    </Grid>

                    <Grid item>
                      <Button
                        type='submit'
                        sx={{
                          height: '2.938rem',
                          backgroundColor: '#1B5255',
                          color: 'white',
                          fontSize: '1.125rem',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#1B5255',
                            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
                          }
                        }}>
                        <Typography variant='h5' paddingRight={5} paddingLeft={5}>
                          {translate('admin-credentials-create-send')}
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <WarningBox
                        title={translate('admin-credentials-create-popup-title')}
                        open={warningBoxOpen}
                        onClose={() => {
                          if (formSendState) return;
                          setWarningBoxOpen(false);
                        }}
                        actions={
                          formSendState
                            ? []
                            : [
                                {
                                  label: translate('admin-credentials-create-popup-reject'),
                                  onClick: (event) => {
                                    setWarningBoxOpen(false);
                                  }
                                },
                                {
                                  label: translate('admin-credentials-create-popup-accept'),
                                  onClick: (event) => {
                                    formSubmit(event);
                                  }
                                }
                              ]
                        }>
                        <SpinnerLoader isLoading={formSendState} text={translate('admin-credentials-create-sending')}>
                          <Typography>{translate('admin-credentials-create-popup-body-kid')}</Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {whoAmI.businessName}
                          </Typography>
                        </SpinnerLoader>
                      </WarningBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
