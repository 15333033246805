import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function GrantThorntonIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 379.71, height: 73.56 }}>
      <path className="cls-1" d="M36.55,73.47C18.27,74.66-.63,57.4,0,35.49.52,18.48,15.8-.94,38.5,0,57.9.87,74.19,17.12,74,37.06,73.68,59.58,54.4,74.86,36.55,73.47ZM24.46,42.78C22.67,53.34,32.34,65.66,45.08,67a16.63,16.63,0,0,0,9.55-1.86C63.09,60.28,68.05,52.52,70,43c.61-3.07.43-6.3.61-9.45C62.08,55.49,37,59.33,24.46,42.78ZM49.37,30.5C50.91,21.85,44.38,12,34.44,8c-4.88-2-9.82-2.63-14.65,0C8.63,14.21,2,27.79,3.8,40.47,11.25,18.76,36.24,13.93,49.37,30.5ZM41.5,70.09c-10.24-3.74-17.26-9.47-19.78-19.28-2.7-10.48.46-19.34,9.06-26.38C21.49,22.9,11.77,30.06,8,39.49,5.42,46.13,6,52.17,10.77,57.8,18.64,67,28.46,71.21,41.5,70.09Zm1.83-20.88c8.74.86,17-3.91,21.34-12.5,3.76-7.39,4.24-14.46-2.32-21.9-7.28-8.25-19.29-13-28.57-10.92C54.81,11.69,59.76,35.26,43.33,49.21Zm5.14-11.65c-.14-4.19-8.26-12-11.62-12a1.94,1.94,0,0,0-.72.11C31.41,27.77,27.31,31,26.21,36c-.49,2.21,1.95,5.57,3.85,7.71,5,5.67,8.49,5.6,13.82.08A52.91,52.91,0,0,0,48.47,37.56Z" />
      <path className="cls-1" d="M121.53,23.89l-2.62,2.87c-2.67-1.33-5.06-3-7.71-3.72-8.59-2.21-14.58,2.85-15.45,12.69-.93,10.44,6.44,16.8,16.56,14.3,4.58-1.13,5-2,4.51-8.63-1.94-2.77-8.54,2.3-8-5.1h13.5c0,4,.11,8.08-.09,12.1,0,.9-1,1.92-1.83,2.58-5.85,4.76-16.45,5.6-22.76,1.61-6.58-4.17-7.79-10.78-7.4-17.89.42-7.84,5-13.83,12.23-16C108.36,17,117.8,18.23,121.53,23.89Z" />
      <path className="cls-1" d="M231.44,18.81c3.56-1.15,5.08-.41,4.81,3.21-.24,3.2-.05,6.44-.05,9.33,2.92-.52,5.74-1.37,8.58-1.44,3.73-.09,6.17,2.16,6.33,5.88.24,6,.06,12.08.06,18.31h-4.81c0-4.9-.06-9.58,0-14.26.05-2.94-.66-5.19-4-5.55-3.15-.33-5.57,1.3-5.83,4.81-.37,4.91-.1,9.86-.1,15h-5V18.81Z" />
      <path className="cls-1" d="M159.36,54.92a44.29,44.29,0,0,0-4.66-1.74,5.24,5.24,0,0,0-2.24.31c-1.91.42-3.79,1.21-5.7,1.26-4.95.15-7.89-2.4-8.17-6.67s2.49-7.72,7.2-8.34c2.67-.35,5.39-.38,9.18-.63-1-2-1.53-4.33-2.64-4.67-1.75-.54-4.62-.44-5.76.68-2.06,2-3.88,2.08-7.21,1.14,1.71-2,2.83-4.57,4.46-4.94a33.54,33.54,0,0,1,11.47-.63c3.1.39,3.9,3.68,4,6.43C159.51,42.66,159.36,48.21,159.36,54.92Zm-7.71-12.2c-3.44,1.1-7.28-.12-7.6,4.34-.19,2.68,1.65,3.67,4,3.75,3.45.12,6.53-2.52,6.06-5.51C154,44.25,152.38,43.43,151.65,42.72Z" />
      <path className="cls-1" d="M266.84,29.82c7.13,0,11.57,4.79,11.52,12.44S273.55,54.86,266,54.83c-7.19,0-11.44-4.69-11.43-12.53S259,29.82,266.84,29.82Zm6,12.67c0-6.23-3.62-10-8.36-8.31-3.18,1.1-4.42,3.75-4.51,7-.11,3.54-.1,7.11,3.8,8.86C268.68,52.24,272.78,48.8,272.82,42.49Z" />
      <path className="cls-1" d="M344.6,54.83c-7.23,0-11.84-4.71-11.89-12.2,0-8,4.51-12.78,12.11-12.84s11.82,4.58,11.79,12.51C356.58,49.93,351.9,54.81,344.6,54.83Zm6.56-12.27c0-6.18-3.67-10-8.39-8.36-3.61,1.21-4.6,4.33-4.64,7.69s.4,6.79,4.21,8.28C347.18,52.07,351.11,48.64,351.16,42.56Z" />
      <path className="cls-1" d="M214.39,22.8a61.69,61.69,0,0,1-7.79-.11c-.9-.12-1.64-1.46-2.46-2.25.78-.71,1.55-2,2.35-2q10.56-.24,21.14-.06c3.13.06,2,2.32,1.85,4.44h-9.67V53.67c-5.23,1.14-5.42,1-5.42-3.88Z" />
      <path className="cls-1" d="M183.94,54.16h-4.55c0-4.72,0-9.26,0-13.81s-1.34-6.05-4.7-6.07-5.33,2.09-5.42,6.1c-.09,4.43,0,8.87,0,13.62h-4.72V30l5.67,1.41A25.74,25.74,0,0,1,174.62,30c6.21-1.11,9.45,1.62,9.52,7.9.05,4.94,0,9.89,0,14.83A12.85,12.85,0,0,1,183.94,54.16Z" />
      <path className="cls-1" d="M360.13,29.48c4.48,3.48,8.27.16,12.27.26,4.86.13,7.22,2.47,7.28,7.41.06,5.55,0,11.11,0,16.9h-4.76c0-4.78,0-9.34,0-13.9,0-4.26-1.14-5.65-4.52-5.81s-5.41,1.81-5.55,5.55c-.18,4.67,0,9.35,0,14.22h-4.68Z" />
      <path className="cls-1" d="M301.58,54.15h-4.77V30.62c.57-.17,1.24-.56,1.44-.39,3.21,2.68,6.15-.16,9.21-.38,6.22-.46,9,2,9,8.29.05,5.19,0,10.38,0,15.87h-5c0-4.66,0-9.22,0-13.77s-1.24-6-4.74-6-5.16,2.13-5.2,6.25C301.55,45,301.58,49.41,301.58,54.15Z" />
      <path className="cls-1" d="M199.47,51.57c1.1,1.38,1.42,3-1.27,3.29-4.94.55-7.64-.65-8.17-4.21a49.52,49.52,0,0,1-.17-6.68c0-1.86-.05-3.72,0-5.58.07-2.23.29-4.49-3.05-3.82-.25-2.3-.75-3.88,2-4.74,1-.3,1.05-3.35,1.63-5.5l4.4-.68c.19,2.11.36,4,.56,6.1l4.19.83c0,1.2.1,2.38.17,3.75l-4.53.71a134.11,134.11,0,0,0,.19,13.61C195.54,49.75,198.05,50.6,199.47,51.57Z" />
      <path className="cls-1" d="M322.16,29.48v-5l4.65-1c.22,2.33.42,4.35.63,6.46,2.73.29,5.87.09,4.19,4.54l-4.42.54c0,4.39-.34,8.73.19,13,.17,1.42,2.54,2.56,4.39,3.5,1.46,2.11.81,3.32-2.19,3.41-6.13.19-7.45-1-7.45-7.09V35.32l-4.46-1.43L321,29.25Z" />
      <path className="cls-1" d="M293.94,29.72v4.82c-.89.07-1.6.18-2.3.17-3.37,0-5,1.64-5,5V54h-5.2V42c0-2.34,0-4.69,0-7,0-5,0-4.93,4.89-3.85.75.17,1.68-.75,2.57-.91C290.41,30,292,29.91,293.94,29.72Z" />
      <path className="cls-1" d="M138.53,34.6c-6.44.25-7.2,1-7.21,7.24,0,3.95,0,7.89,0,12.21l-4.86.36c0-7.75,0-15.12.1-22.48,0-.59,1.74-1.8,2.15-1.61,2.29,1.09,4.24.46,6.37-.43.92-.39,2.14-.06,3.46-.06Z" />
    </SvgIcon>
  )
}