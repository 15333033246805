// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

// ------FILE MODULES---------------------------------------------------------
import CatalogCredentialRow from "./CatalogCredentialRow.component";
import Hider from "../../shared/Hider/Hider.component";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => { return string };
const DEFAULT_CATALOG_ENTRY = {};
const DEFAULT_SELECTED_CREDENTIALS = {};
const DEFAULT_HIDE_CHECKBOXES = false;
const DEFAULT_DISABLE_CHECKBOXES = false;
const DEFAULT_CHECK_BOX_CHANGE = () => { };
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_MAX_NAME_LENGTH = 50;
const DEFAULT_MAX_USE_CASE_LENGTH = 40;
const DEFAULT_COLLAPSED_NAME_PADDING = 5;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CollapsibleCatalogCredentialRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const catalogEntry = props.catalogEntry || DEFAULT_CATALOG_ENTRY;
  const selectedCredentials = props.selectedCredentials || DEFAULT_SELECTED_CREDENTIALS;
  const hideCheckboxes = props.hideCheckboxes || DEFAULT_HIDE_CHECKBOXES;
  const disableCheckboxes = props.disableCheckboxes || DEFAULT_DISABLE_CHECKBOXES;
  const checkBoxChange = props.checkBoxChange || DEFAULT_CHECK_BOX_CHANGE;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxUseCaseLength = props.maxUseCaseLength || DEFAULT_MAX_USE_CASE_LENGTH;
  const collapsedNamePadding = props.collapsedNamePadding || DEFAULT_COLLAPSED_NAME_PADDING;

  const [open, setOpen] = useState(false);

  const collapsedRows = catalogEntry.metadata.map((catalogItem) => {
    return <CatalogCredentialRow
      key={catalogItem.type}
      translate={translate}
      catalogEntry={catalogItem}
      checkBoxChange={checkBoxChange}
      selectedCredentials={selectedCredentials}
      disableCheckboxes={disableCheckboxes}
      hideActions={hideActions}
      maxNameLength={maxNameLength - collapsedNamePadding}
      hideCheckboxes={hideCheckboxes}
      maxUseCaseLength={maxUseCaseLength}
      open={open}
      setOpen={setOpen}
      textPaddingLeft={collapsedNamePadding}
    />;
  });

  return (
    <>
      <CatalogCredentialRow
        translate={translate}
        catalogEntry={catalogEntry}
        checkBoxChange={checkBoxChange}
        selectedCredentials={selectedCredentials}
        disableCheckboxes={disableCheckboxes}
        hideActions={hideActions}
        maxNameLength={maxNameLength}
        hideCheckboxes={hideCheckboxes}
        maxUseCaseLength={maxUseCaseLength}
        open={open}
        setOpen={setOpen}
      />
      <Hider isHidden={!open}>
        {collapsedRows}
      </Hider>
    </>
  )
}
