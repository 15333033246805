// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { Fragment } from "react";

import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// ------FILE MODULES---------------------------------------------------------

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_CHILDREN = <></>;
const DEFAULT_HAS_ICON = false;
const DEFAULT_ON_CLICK = () => {};
const DEFAULT_BUTTON_PROPS = {};
const DEFAULT_TOOLTIP = "";
const DEFAULT_TOOLTIP_PROPS = {
  arrow: true,
};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function Button(props) {
  const children = props.children || DEFAULT_CHILDREN;
  const disabled = props.disabled;
  const hasIcon = props.hasIcon || DEFAULT_HAS_ICON;
  const onClick = props.onClick || DEFAULT_ON_CLICK;
  const buttonProps = { ...DEFAULT_BUTTON_PROPS, ...props.buttonProps, onClick, disabled };
  const tooltip = props.tooltip || DEFAULT_TOOLTIP;
  let tooltipProps = { ...DEFAULT_TOOLTIP_PROPS, ...props.tooltipProps, title: tooltip };

  let ButtonComponent = hasIcon ? IconButton : MuiButton;
  let TooltipComponent = Tooltip;

  if (!tooltip) {
    TooltipComponent = Fragment;
    tooltipProps = { key: tooltipProps.key, children: tooltipProps.children };
  }

  return (
    <TooltipComponent {...tooltipProps}>
      <ButtonComponent {...buttonProps}>
        {children}
      </ButtonComponent>
    </TooltipComponent>
  );
}
