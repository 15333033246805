import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const contacts = {
  contactPersonel: ["Luis Pastor", "Raúl López"],
  socialMediaSvgs: [
    (<LinkedInIcon />),
    (<FacebookIcon />),
    (<TwitterIcon />),
    (<YouTubeIcon />)
  ]
}

export default contacts