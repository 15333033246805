import { Button, Typography } from '@mui/material';

const DEFAULT_TEXT = '';
const DEFAULT_ON_CLICK = () => {};
const DEFAULT_DISABLED = false;

export default function GreenButton(props) {
  const text = props.text || DEFAULT_TEXT;
  const onClick = props.onClick || DEFAULT_ON_CLICK;
  const disabled = props.disabled || DEFAULT_DISABLED;

  return (
    <Button
      fullWidth
      disabled={disabled}
      size='small'
      sx={{
        opacity: disabled ? '0.5' : '1',
        backgroundColor: '#A9D9D0',
        border: '2px solid #049294',
        color: '#000000',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'none',
        marginRight: '10px',
        '&:hover': {
          backgroundColor: '#A9D9D0',
          boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
        }
      }}
      onClick={onClick}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{text}</Typography>
    </Button>
  );
}
