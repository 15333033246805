// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ------FILE MODULES---------------------------------------------------------
import { clearCatalogCache, fetchCatalogAsync, selectCatalogState, selectCatalog, selectCatalogPage, selectCatalogCount } from "../../../helpers/reducers/catalog";
import { ROW_COUNT } from "../../../config/constants.config";

import CatalogCredentialTable from "../../../components/private/Tables/CatalogCredentialTable.component";
import CatalogFilters from "../../../components/private/Filters/CatalogFilters.component";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => { return string; };

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function Catalog(props) {
  const translate = props.translator || DEFAULT_TRANSLATE;

  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState(undefined);
  const [search, setSearch] = useState(null);
  const [holder, setHolder] = useState(null);
  const [useCase, setUseCase] = useState(null);
  const [issuer, setIssuer] = useState(null);
  const [page, setPage] = useState(useSelector(selectCatalogPage));

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);
  const catalogCount = useSelector(selectCatalogCount);

  const isFiltersLoading = catalogState === 0 && filterData === undefined;
  const isCatalogLoading = catalogState === 2;
  const catalogNeedsReload = catalogState === 1;

  if (catalogNeedsReload) {
    dispatch(fetchCatalogAsync({
      page,
      search,
      holder: holder ? holder.holder : undefined,
      useCase: useCase ? useCase.useCase : undefined,
      issuer: issuer ? issuer.issuer : undefined,
      limit: filterData ? ROW_COUNT[1] : null
    }));
  }

  if (isFiltersLoading) {
    setFilterData(catalog || []);
    dispatch(clearCatalogCache());
  }

  const clearFilters = () => {
    setSearch(null);
    setHolder(null);
    setUseCase(null);
    setIssuer(null);
    onFilterSubmit();
  };

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCatalogCache());
  }

  return (
    <Grid container className="admin-catalog-view" direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" sx={{ color: "#1B5255", fontWeight: "bold", fontSize: "2rem" }}>
              {translate("catalog-page-title")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <CatalogFilters
          translate={translate}
          catalog={filterData}
          isLoading={isFiltersLoading}
          search={search}
          setSearch={setSearch}
          issuer={issuer}
          setIssuer={setIssuer}
          useCase={useCase}
          setUseCase={setUseCase}
          holder={holder}
          setHolder={setHolder}
          onFilterSubmit={onFilterSubmit}
          clearFilters={clearFilters}
        />
      </Grid>

      <Grid item>
        <CatalogCredentialTable
          translate={translate}
          isLoading={isCatalogLoading}
          catalog={catalog}
          catalogCount={catalogCount}
          page={page}
          setPage={setPage}
          hideActions
          hideCheckboxes
        />
      </Grid>
    </Grid>
  );
}
