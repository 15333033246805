const successCases = [
  {
    title: "provider-title",
    description: "provider-description"
  },
  {
    title: "access-control-title",
    description: "access-control-description"
  },
  {
    title: "logistics-title",
    description: "logistics-description"
  }
]

export default successCases