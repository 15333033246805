// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';

import FingerprintOutlined from '@mui/icons-material/FingerprintOutlined';

// ------FILE MODULES---------------------------------------------------------
import { getColorForStatus } from '../../../helpers/color.helper';

import TableCell from '../../shared/Tables/TableCell.component';
import MaxLengthText from '../../shared/Typography/MaxLengthText.component';
import Hider from '../../shared/Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ENTITY = {};
const DEFAULT_HIDE_ALIAS = false;
const DEFAULT_HIDE_TELEPHONE = false;
const DEFAULT_HIDE_CIF = false;

const MAX_LENGTH = 40;
const MAX_DID_LENGTH = 25;

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function EntityRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const entity = props.entity || DEFAULT_ENTITY;
  const hideAlias = props.hideAlias || DEFAULT_HIDE_ALIAS;
  const hideTelephone = props.hideTelephone || DEFAULT_HIDE_TELEPHONE;
  const hideCif = props.hideCif || DEFAULT_HIDE_CIF;

  return (
    <TableRow>
      <TableCell useTypography={false}>
        <MaxLengthText text={entity.businessName} maxLength={MAX_LENGTH} />
      </TableCell>
      <Hider isHidden={hideAlias}>
        <TableCell>{entity.alias}</TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <MaxLengthText text={entity.email} maxLength={MAX_LENGTH} />
      </TableCell>
      <TableCell useTypography={false}>
        <Hider isHidden={!entity.did}>
          <Grid container direction='row' alignItems='center' justifyContent='center'>
            <Grid item>
              <MaxLengthText text={entity.did} maxLength={MAX_DID_LENGTH} />
            </Grid>
            <Grid item>
              <IconButton onClick={() => navigator.clipboard.writeText(entity.did)}>
                <Tooltip arrow title={translate('entity-table-row-copy-did-button-tooltip')}>
                  <FingerprintOutlined />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </Hider>
      </TableCell>
      <Hider isHidden={hideTelephone}>
        <TableCell>{entity.tel}</TableCell>
      </Hider>
      <Hider isHidden={hideCif}>
        <TableCell>{entity.cif}</TableCell>
      </Hider>
      <TableCell color={getColorForStatus(entity.status)}>
        {translate(`entity-table-row-status-${entity.status.toLowerCase()}`)}
      </TableCell>
    </TableRow>
  );
}
