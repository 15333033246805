import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    secondary: {
      main: '#049294',
    },
  },
  typography: {    
    fontFamily: '"Montserrat"',
  },
});
