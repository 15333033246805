// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

// ------FILE MODULES---------------------------------------------------------
import { clearEntityPresentationsCache } from "../../../helpers/reducers/entityPresentations";
import { PAGE_ROUTES, PRESENTATION_STATUS } from "../../../config/constants.config";
import { isOk, post } from "../../../helpers/axios.helper";

import RedButton from "./RedButton.component";

import SpinnerLoader from "../../shared/Loader/SpinnerLoader.component";
import WarningBox from "../../shared/DialogBoxes/WarningBox.component";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {return string;};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_PRESENTATION_REQUEST = {storedJwt: {}};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const presentation = props.presentationRequest || DEFAULT_PRESENTATION_REQUEST;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [formSendState, setFormSendState] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  
  const storedJwt = presentation.storedJwt;
  const canBeRevoked = storedJwt.status === PRESENTATION_STATUS[0] || storedJwt.status === PRESENTATION_STATUS[1];

  const onRevokeOrDeleteClicked = async () => {
    const form = {
      ...storedJwt
    };

    let queryParams = new URLSearchParams();

    if (canBeRevoked) {
      form.status = PRESENTATION_STATUS[2];
    } else {
      form.isDeleted = true;
      queryParams.append('propagated', true);
    }

    if (!form.fileUri) {
      form.fileUri = '';
    }

    setFormSendState(true);

    const result = await post(`/dit/presentation/${storedJwt.jti}?${queryParams}`, form);
    if (isOk(result.status)) {
      dispatch(clearEntityPresentationsCache());

      let navigationTarget = form.isDeleted ? PAGE_ROUTES.EntityPresentations : 0;
      navigate(navigationTarget);
    } else {
      setPopupOpen(false);
      setErrorPopup(result);
    }

    setFormSendState(false);
  };

  return (
    <Grid item>
      <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>
        <WarningBox
          title={translate(`entity-presentations${canBeRevoked ? '-reject' : '-delete'}-popup-title`)}
          open={popupOpen}
          actions={
            formSendState
              ? []
              : [
                  {
                    label: translate('entity-presentations-reject-popup-cancel'),
                    onClick: (event) => {
                      setPopupOpen(false);
                    }
                  },
                  {
                    label: translate(`entity-presentations-popup${canBeRevoked ? '-reject' : '-delete'}`),
                    onClick: (event) => {
                      onRevokeOrDeleteClicked(event);
                    }
                  }
                ]
          }>
          <SpinnerLoader
            isLoading={formSendState}
            text={translate(`entity-presentations${canBeRevoked ? '-reject' : '-delete'}-popup-sending`)}>
            <Typography>
              {translate(`entity-presentations${canBeRevoked ? '-reject' : '-delete'}-popup-body`)}
            </Typography>
          </SpinnerLoader>
        </WarningBox>
        <Grid item flexGrow={1}>
          <RedButton
            text={translate(`entity-presentations-card-${canBeRevoked ? 'revoke' : 'delete'}`)}
            onClick={() => setPopupOpen(true)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}