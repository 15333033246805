import { Outlet } from "react-router-dom";

import Hider from "../../components/shared/Hider/Hider.component";

import "./Admin.layout.css";

export default function AdminLayout(props) {
  const header = props.header;
  const sidePanel = props.sidePanel;
  const noSidePanel = props.noSidePanel;

  return (
    <div className="admin-layout">
      <div className="admin-layout-header">{header}</div>
      <Hider isHidden={noSidePanel}>
        <div className="admin-layout-side-panel">
          <div className="admin-layout-side-panel-stretch">{sidePanel}</div>
        </div>
      </Hider>
      <div className={props.isEntity ? "admin-layout-background-entity-fake" : "admin-layout-background"}>
        <div className="admin-layout-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
