import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function RepsolIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 309.48, height: 71.69 }}>
      <path className="cls-1" d="M248.25,42.88c-1.51-8.21,6-17.31,15.53-19.93,11.05-2.22,20.35,2,22,10.51,1.18,8.27-5.21,17-15.92,19.46-10.14,1.89-19.89-1.76-21.65-10Zm14.24-10c-3.7,4.22-4.66,9.73-2.12,12.28s7.57,1.23,11.27-3,4.65-9.72,2.13-12.28-7.58-1.24-11.28,3Zm-51.43-1.61a2.86,2.86,0,0,0-1.61-2.48A9.31,9.31,0,0,0,205.1,28h-1.91l-1.84,8.58h1.54a11.57,11.57,0,0,0,6.17-1.36,4.06,4.06,0,0,0,2-3.92ZM190,52.19s-2.88.14-2.5-2.6l5-23.56a3.19,3.19,0,0,1,3-2.45h12.14a39.47,39.47,0,0,1,5.84.4,14.85,14.85,0,0,1,4.38,1.28,7.5,7.5,0,0,1,2.88,2.38,8.14,8.14,0,0,1,1.17,3.9c.22,3.28-1.3,6-4.54,8s-7.84,2.79-13.88,2.79h-3.33L198,52.19Zm-47-21a2.71,2.71,0,0,0-1.49-2.44,8.43,8.43,0,0,0-3.93-.75h-2.73l-1.65,7.71H136a9.89,9.89,0,0,0,5.36-1.18A3.51,3.51,0,0,0,143,31.2Zm-13.31,21h-8.18a2.16,2.16,0,0,1-2.35-2.6l5-23.56a3.22,3.22,0,0,1,3-2.45H141a36.24,36.24,0,0,1,5.24.35,13.56,13.56,0,0,1,4,1.18,6,6,0,0,1,2.59,2.22,5.85,5.85,0,0,1,1.06,3.35c.24,3.28-1.18,5.52-4.1,7.39a19,19,0,0,1-7.32,1.69s-.91.19.14.47A6.31,6.31,0,0,1,145,41.49c2.29,1.82,4,3.71,9.16,10.7H141.87a93.82,93.82,0,0,0-6.49-10.53c-1-1.42-2.55-1.18-3.15-1.25l-2.51,11.78Zm169.85-5.51h9.91l-.67,3.17a3.14,3.14,0,0,1-2.95,2.34h-15.6a2.17,2.17,0,0,1-2.36-2.6l5-23.56a3.2,3.2,0,0,1,3-2.45h8.62l-4.95,23.1ZM171.14,35.29h13l-.65,3a3.21,3.21,0,0,1-3,2.46H170l-1.26,5.93h13l-.67,3.17a3.13,3.13,0,0,1-2.94,2.34H159.39a2.17,2.17,0,0,1-2.35-2.6l5-23.56a3.2,3.2,0,0,1,3-2.45h21.62L186,26.65a3.21,3.21,0,0,1-3,2.44H172.46l-1.32,6.2Zm79.79-11.71-.66,3.07a3.08,3.08,0,0,1-3,2.44h-.69c-6.18,0-6.58,1.71-7.76,7.45l-1.48,6.83a10.51,10.51,0,0,1-10.3,8.82H211.39l.66-3.06a3.13,3.13,0,0,1,3-2.45h3.76c6.19,0,6.59-1.69,7.76-7.44L228,32.42c1.86-8.64,11.31-8.84,12.75-8.84Z" />
      <path className="cls-1" d="M100.69,34.45a17.3,17.3,0,0,0-2-.5,83.41,83.41,0,0,0-11.11-1.37c1-8.62-1.28-16.65-6.39-22.68C75.82,3.52,67.75,0,58.5,0c-13,0-26.4,7-35,18.39-1.61.13-5.14.42-8.72.82-10.43,1.18-11,2.13-11.5,3l0,.08C.35,27.72,0,36.21,0,37.92a1.85,1.85,0,0,0,0,.23,1.35,1.35,0,0,0,.39,1l0,0c.32.33.84.89,5.68,1.25,1.35.1,3,.1,5.18.11l3.46,0c0,.46-.06.92-.07,1.36a30.74,30.74,0,0,0,1.55,10.88,27,27,0,0,0,10.59,14,30.85,30.85,0,0,0,17.21,5c12.85,0,26.21-7,34.86-18.19v0l0,0c9.09-.28,13.85-.5,16.4-.74s3.27-.55,3.69-1.27l.09-.16.11-.23a38.47,38.47,0,0,0,3-14.81A1.87,1.87,0,0,0,100.69,34.45ZM18.41,38.94v0a1.49,1.49,0,0,1,.21-.22A5.71,5.71,0,0,1,21,37.76c.41-.09.85-.18,1.32-.26,2.48-.43,6.35-.82,12.2-1.23h0c.42,0,4.21-.34,7.76-.48h.08c4.15-.19,8.45-.29,12.76-.29,21.43,0,35,.13,43.07,1.88h0c.38.07.67.14.92.21a36.38,36.38,0,0,1-1.57,9.14,23,23,0,0,1-1,2.74c-1.62-.18-3.2-.3-4.86-.44l-1.21-.09c-2.75-.24-5.45-.42-8.27-.56H82c-9.18-.46-13.52-.66-20.21-.66-2.28,0-5,0-8.4.08h0c-11.5.12-23.08.72-34.43,1.77a26.92,26.92,0,0,1-.77-6.25A28.7,28.7,0,0,1,18.41,38.94ZM44,68.21a27.28,27.28,0,0,1-15.27-4.38C24.69,61.12,22.27,58.13,20,53c2.08-.19,7.51-.67,13.54-1,4.35-.25,8.58-.44,12.23-.55,3.3-.11,8.49-.16,15.43-.16h4.31c1.29,0,2.56.08,3.78.12l1.61.05a44,44,0,0,1,4.76.45C67.69,62,55.64,68.21,44,68.21ZM37.93,18.59l.55-1-1.15,0c-2.7.08-5.76.24-9.12.47,8-9.17,19.25-14.6,30.29-14.6,8.2,0,15.33,3.08,20.07,8.67,4.51,5.32,6.47,12.48,5.56,20.21-7.74-.27-15.32-.4-22.54-.4-3.68,0-7.32,0-10.82.1A407.85,407.85,0,0,0,7.5,35.92l-4.19.54A39,39,0,0,1,5.72,24.4L6,24.29l.06,0c.63-.34,3.44-.94,9.46-1.62l.28,0c.37,0,9.11-.91,17.79-1.35A5.54,5.54,0,0,0,37.93,18.59Z" />
    </SvgIcon>
  )
}