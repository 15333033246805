import { Grid, Typography, Button } from "@mui/material";

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { redirect, useLinkClickHandler } from "react-router-dom";
import DigitalisIcon from "../../../components/shared/Icons/DigitalisIcon.component";
import blockchain from "../../../helpers/blockchain.helper";
import "./Success.view.css";
import { PAGE_ROUTES } from "../../../config/constants.config";

export default function Success(props) {
  const translate = props.translator;

  const onDownloadClicked = () => {
    blockchain.downloadTxt({
      filename: "digitalisKeyStore.json",
      content: JSON.parse(sessionStorage.getItem("keystore")),
    });
  };

  return (
    <div className="register-success-view">
      <video autoPlay loop muted className="success-video">
        <source src="/images/key.mp4" type="video/mp4" />
      </video>
      <Grid container direction="column" className="success-content" spacing={3}>
        <Grid item>
          <DigitalisIcon />
        </Grid>

        <Grid item>
          <Typography variant="h6">{translate("sign-up-success-congratulations")}</Typography>
        </Grid>

        <Grid item>
          <Typography>{translate("sign-up-success-title")}</Typography>
        </Grid>

        <Grid item>
          <Typography>{translate("sign-up-success-subtitle")}</Typography>
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="stretch" spacing={2} sx={{ width: "10%" }}>
            <Grid item>
              <Button
                fullWidth
                onClick={onDownloadClicked}
                className="download-button"
                variant="contained"
                sx={{
                  backgroundColor: "#F2E7DC",
                  color: "#146263",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#D9CFC5",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                  },
                }}
              >
                {translate("sign-up-success-download")}
                <FileDownloadOutlinedIcon />
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                onClick={useLinkClickHandler(PAGE_ROUTES.Login)}
                variant="contained"
                sx={{
                  backgroundColor: "#F2E7DC",
                  color: "#146263",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#D9CFC5",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                  },
                }}
              >
                {translate("sign-up-success-log-in")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
