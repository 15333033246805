// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useLinkClickHandler } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";

import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

// ------FILE MODULES---------------------------------------------------------
import { PAGE_ROUTES } from "../../../config/constants.config";

import MaxLengthText from "../../shared/Typography/MaxLengthText.component";
import TableCell from "../../shared/Tables/TableCell.component";
import Hider from "../../shared/Hider/Hider.component";

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => { return string };
const DEFAULT_CATALOG_ENTRY = {};
const DEFAULT_SELECTED_CREDENTIALS = {};
const DEFAULT_HIDE_CHECKBOXES = false;
const DEFAULT_DISABLE_CHECKBOXES = false;
const DEFAULT_CHECK_BOX_CHANGE = () => { };
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_MAX_NAME_LENGTH = 50;
const DEFAULT_MAX_USE_CASE_LENGTH = 40;
const DEFAULT_PADDING_LEFT = undefined;
const DEFAULT_OPEN = false;
const DEFAULT_SET_OPEN = () => { };

const CHECKBOXES = [1, 2, 3];
const STATUS = ["accepted", "pending"];

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogCredentialRow(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const catalogEntry = props.catalogEntry || DEFAULT_CATALOG_ENTRY;
  const selectedCredentials = props.selectedCredentials || DEFAULT_SELECTED_CREDENTIALS;
  const hideCheckboxes = props.hideCheckboxes || DEFAULT_HIDE_CHECKBOXES;
  const disableCheckboxes = props.disableCheckboxes || DEFAULT_DISABLE_CHECKBOXES;
  const checkBoxChange = props.checkBoxChange || DEFAULT_CHECK_BOX_CHANGE;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const maxNameLength = props.maxNameLength || DEFAULT_MAX_NAME_LENGTH;
  const maxUseCaseLength = props.maxUseCaseLength || DEFAULT_MAX_USE_CASE_LENGTH;
  const textPaddingLeft = props.textPaddingLeft || DEFAULT_PADDING_LEFT;
  const open = props.open || DEFAULT_OPEN;
  const setOpen = props.setOpen || DEFAULT_SET_OPEN;

  const href = `${PAGE_ROUTES.EntityCredentialsCreate}?id=${catalogEntry.type}`;
  const redirect = useLinkClickHandler(href);

  let status = null;
  if (catalogEntry.emitted && catalogEntry.emitted.length > 0) {
    status = STATUS[0];
  } else if (catalogEntry.requests && catalogEntry.requests.length > 0) {
    status = STATUS[1];
  }

  return (
    <TableRow>
      <Hider isHidden={hideCheckboxes}>
        <TableCell useTypography={false}>
          <Grid container direction="row" justifyContent="space-around">
            {CHECKBOXES.map(loa =>
              <Grid item key={loa}>
                <Checkbox
                  key={loa}
                  onChange={(e) => {
                    checkBoxChange(e, catalogEntry.type, loa);
                  }}
                  value={selectedCredentials[catalogEntry.type] === loa}
                  checked={selectedCredentials[catalogEntry.type] === loa}
                  disabled={disableCheckboxes}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      </Hider>
      <TableCell useTypography={false}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Hider isHidden={!catalogEntry.metadata || catalogEntry.metadata.length === 0}>
              <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </Hider>
          </Grid>
          <Grid item>
            <MaxLengthText text={catalogEntry.name} maxLength={maxNameLength} typographyProps={{ paddingLeft: textPaddingLeft }} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        {catalogEntry.issuer}
      </TableCell>
      <TableCell>
        {catalogEntry.holder}
      </TableCell>
      <TableCell>
        {catalogEntry.format}
      </TableCell>
      <TableCell useTypography={false}>
        <MaxLengthText text={catalogEntry.useCase} maxLength={maxUseCaseLength} />
      </TableCell>
      <Hider isHidden={hideActions}>
        <TableCell useTypography={status}>
          {!status ? (
            <Link
              onClick={redirect}
              href={href}
              sx={{
                fontSize: "0.75rem",
                fontWeight: "bold",
                color: "#1B5255",
                textDecoration: "underline",
                "a:visited": {
                  textDecoration: "underline",
                  color: "inherit",
                },
              }}>
              {translate("presentation-card-request-credential")}
            </Link>
          ) : (
            translate(`presentation-card-status-${status}`)
          )}
        </TableCell>
      </Hider>
    </TableRow>
  );
}
