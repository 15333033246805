// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from "react";

import { useLinkClickHandler } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";


// ------FILE MODULES---------------------------------------------------------
import { CUSTOMER_STATUS, PAGE_ROUTES, PLATFORM_TYPE } from "../../../config/constants.config";

import { clearCustomerCache, fetchCustomersAsync, selectCustomers, selectCustomersCount, selectCustomersPage, selectCustomersState } from "../../../helpers/reducers/customers";

import EntityTable from "../../../components/private/Tables/EntityTable.component";

import Dropdown from "../../../components/shared/Dropdowns/Dropdown.component";
import SearchBar from "../../../components/shared/SearchBars/SearchBar.component";
import Hider from "../../../components/shared/Hider/Hider.component";

import "./Entities.view.css";

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function Entities(props) {
  const translate = props.translator;
  const comms = props.comms;

  const dispatch = useDispatch();
  const createButtonClickHandler = useLinkClickHandler(PAGE_ROUTES.AdminEntityContactsInvite);

  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectCustomersPage));

  const entities = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);
  const entityCount = useSelector(selectCustomersCount);

  const isLoading = customerState === 2;
  const needsReload = customerState === 1;

  const clearFilters = () => {
    setStatus(null);
    setSearch(null);
    setPage(0);
    dispatch(clearCustomerCache());
  };

  if (needsReload) {
    dispatch(fetchCustomersAsync({
      comms,
      page,
      search,
      status: status ? status.status : undefined
    }));
  }

  return (
    <Grid container className="admin-entities-view" direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography sx={{ color: "#1B5255", fontSize: "2rem", fontWeight: "bold" }}>{translate(`entities-page-title-${comms.toLowerCase()}`)}</Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  className="addDidButton"
                  sx={{
                    backgroundColor: "#1B5255",
                    color: "#ffffff",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#1B5255",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                    },
                  }}
                  onClick={useLinkClickHandler(comms === PLATFORM_TYPE[0] ? PAGE_ROUTES.AdminCompanyContactsAddDid : PAGE_ROUTES.AdminEntityContactsAddDid)}>
                  <AddIcon fontSize="large" />
                  <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }} paddingRight={1} paddingLeft={1}>
                    {translate("entities-page-add-did")}
                  </Typography>
                </Button>
              </Grid>

              <Hider isHidden={comms !== PLATFORM_TYPE[1]}>
                <Grid item>
                  <Button
                    className="entitiesButton"
                    sx={{
                      backgroundColor: "#1B5255",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#1B5255",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                    onClick={createButtonClickHandler}>
                    <AddIcon fontSize="large" />
                    <Typography sx={{ fontSize: "1.125rem", fontWeight: "bold" }} paddingRight={1} paddingLeft={1}>
                      {translate("entities-page-create-button")}
                    </Typography>
                  </Button>
                </Grid>
              </Hider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className="entitiesFilterContainer">
          <Grid item paddingLeft={1} paddingRight={4}>
            <Typography sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>{translate("entities-page-filter")}</Typography>
          </Grid>

          <Grid item>  
            <SearchBar
              translate={translate}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(0);
                dispatch(clearCustomerCache());
              }}
              onSubmit={() => {
                setPage(0);
                dispatch(clearCustomerCache());
              }}
            />
          </Grid>

          <Dropdown
            value={status}
            options={CUSTOMER_STATUS.map(status => { return { status } })}
            getOptionLabel={(status) => translate(`entities-page-status-${status.status.toLowerCase()}`)}
            getOptionValue={(status) => status.status}
            onChange={(status) => {
              setStatus(status);
              setPage(0);
              dispatch(clearCustomerCache());
            }}
            label={translate("entities-page-status")}
          />

          <Grid item>
            <Button
              sx={{
                backgroundColor: "rgb(232, 112, 112, 0.63)",
                border: "2px solid #AB0000",
                color: "#000000",
                textTransform: "none",
                fontWeight: "bold",
                marginRight: "10px",
                "&:hover": {
                  backgroundColor: "rgb(232, 112, 112, 0.63)",
                  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              }}
              onClick={clearFilters}>
              <Typography sx={{ fontSize: "0.875rem", fontWeight: "bold" }} paddingRight={1} paddingLeft={1}>
                {translate("entities-page-filter-remove-filters")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <EntityTable
        translate={translate}
        isLoading={isLoading}
        entities={entities}
        entityCount={entityCount}
        page={page}
        setPage={setPage}
        hideAlias={comms === PLATFORM_TYPE[1]}
        hideTelephone={comms === PLATFORM_TYPE[0]}
        hideCif={comms === PLATFORM_TYPE[1]}
        translatePrefix={`entity-table-${comms.toLowerCase()}`}
      />
    </Grid>
  );
}
