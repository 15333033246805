// REACT imports
import { useState } from "react";
import { useLinkClickHandler, Link, useLoaderData, useNavigate } from "react-router-dom";
import Select2, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForwardIos } from "@mui/icons-material";

// MATERIAL DESIGN imports
import { TextField, Grid, Button, Typography, IconButton } from "@mui/material";

// COMPONENTS imports
import LevelOfAssurance from "../../../components/shared/Icons/LevelOfAssurance.component";
import WarningBox from "../../../components/shared/DialogBoxes/WarningBox.component";
import { SelectDropdownSquareIcon } from "../../../components/shared/Icons/SelectDropdownIcon.component";

// HELPERS imports
import { get, getResponseJson, isOk, post, customFilePost } from "../../../helpers/axios.helper";
import { fetchCatalogAsync, selectCatalogState, selectCatalog } from "../../../helpers/reducers/catalog";
import { clearPresentationsCache } from "../../../helpers/reducers/adminPresentations";
import { clearEntityPresentationsCache } from "../../../helpers/reducers/entityPresentations";

// CONFIG
import config from "../../../config/config";
import { NETWORK_IDS, PAGE_ROUTES, TECHNOLOGIES, TRUST_FRAMEWORKS } from "../../../config/constants.config";

// CSS
import "./CreatePresentation.view.css";
import { decodeAllJwts, mapPresentationRequests } from "../../../helpers/jwt.helpers";

import crypto from "crypto-browserify";
import FileInput from "../../../components/shared/FileInput/FileInput.component";
import DatePicker from "../../../components/shared/DatePicker/DatePicker.component";
import SpinnerLoader from "../../../components/shared/Loader/SpinnerLoader.component";
import { selectWhoAmI } from "../../../helpers/reducers/corporate";

async function onFormSubmit(form) {
  if (config.mocked) {
    return { status: 200 };
  }

  return await post("/dit/presentationRequest", form);
}

export async function createPresentationLoader({ request }) {
  const url = new URL(request.url);
  let delegatedPresentationRequestId = url.searchParams.get("id");
  let unmappedCredentials = url.searchParams.get("credentials");

  let presentations = [];
  let credentials = {};

  if (delegatedPresentationRequestId) {
    const response = await get(`/dit/presentationRequest/${delegatedPresentationRequestId}`);

    if (isOk(response.status)) {
      presentations = [getResponseJson(response)];
    }
  }

  if (unmappedCredentials) {
    credentials = JSON.parse(unmappedCredentials);
  }

  const delegatedPr = (await mapPresentationRequests(decodeAllJwts(presentations), false))[0] || null;
  return { credentials, delegatedPr };
}

export default function CreatePresentation(props) {
  const translate = props.translator;
  const setErrorPopup = props.setErrorPopup;

  const navigate = useNavigate();

  const data = useLoaderData();
  const delegatedPr = data.delegatedPr;

  const delegatedLoas = data.credentials || {};

  if (delegatedPr) {
    delegatedPr.decoded.pr.data.forEach((credential) => {
      if (delegatedLoas[credential.field_name] === undefined) delegatedLoas[credential.field_name] = credential.levelOfAssurance || 0;
    });
  }

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);
  const dispatch = useDispatch();

  const whoAmI = useSelector(selectWhoAmI);

  const [formSendState, setFormSendState] = useState(false);
  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [formType, setFormType] = useState(config.prConfig.formType);
  const [kid, setKid] = useState("default");
  const [name, setName] = useState(delegatedPr ? delegatedPr.decoded.pr.procId : null);
  const [purpose, setPurpose] = useState(delegatedPr ? delegatedPr.decoded.pr.procDesc : null);
  const [credentialLoas, setCredentialLoas] = useState(delegatedLoas);
  const [credentials, setCredentials] = useState(undefined);
  const [startDate, setStartDate] = useState(delegatedPr ? delegatedPr.decoded.nbf * 1000 : null);
  const [endDate, setEndDate] = useState(delegatedPr ? delegatedPr.decoded.exp * 1000 : null);
  const [file, setFile] = useState(null);
  const [loaState, setLoa] = useState("");

  if (catalogState === 1) {
    dispatch(fetchCatalogAsync({ limit: null }));
  }

  if (!catalogState && credentials === undefined) {
    let catalogEntries = [];

    if (delegatedPr) {
      catalogEntries = delegatedPr.decoded.pr.data.map((credential) => credential.type[1]);
    }

    if (data.credentials) {
      catalogEntries = Object.keys(data.credentials);
    }

    setCredentials(catalog.filter((catalogEntry) => catalogEntries.some((credential) => credential === catalogEntry.type)) || []);
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div style={{ display: "inline-block" }}>
            <components.MultiValueLabel {...props} />
            <IconButton
              color="primary"
              onMouseOver={() => {
                setLoa(0);
              }}>
              <LevelOfAssurance level={0} translate={translate} />
            </IconButton>
            <IconButton
              color="primary"
              onMouseOver={() => {
                setLoa(1);
              }}>
              <LevelOfAssurance level={1} translate={translate} />
            </IconButton>
            <IconButton
              color="primary"
              onMouseOver={() => {
                setLoa(2);
              }}>
              <LevelOfAssurance level={2} translate={translate} />
            </IconButton>
            <IconButton
              color="primary"
              onMouseOver={() => {
                setLoa(3);
              }}>
              <LevelOfAssurance level={3} translate={translate} />
            </IconButton>
          </div>
        </components.Option>
      </div>
    );
  };

  const MultiValueLabel = (props) => {
    return (
      <div>
        <div style={{ display: "inline-block" }}>
          <components.MultiValueLabel {...props} />
          <IconButton color="primary">
            <LevelOfAssurance level={Number(credentialLoas[props.data.type])} translate={translate} />
          </IconButton>
        </div>
      </div>
    );
  };

  const nameChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 100) {
      setName(value);
    }
  };

  const purposeChanged = (event) => {
    const value = event.target.value;
    if (value.length <= 50) {
      setPurpose(value);
    }
  };

  const credentialChanged = (value) => {
    setCredentials(value);
    let newLoas;
    const valueCatalogIds = value.map((credential) => {
      return credential.type;
    });
    const changedValue = valueCatalogIds.filter((x) => !Object.keys(credentialLoas).includes(x)).concat(Object.keys(credentialLoas).filter((x) => !valueCatalogIds.includes(x)));
    if (value.length === 0) {
      newLoas = {};
    } else {
      if (credentialLoas[changedValue]) {
        newLoas = credentialLoas;
        delete newLoas[changedValue];
      }
      if (!credentialLoas[changedValue]) {
        newLoas = credentialLoas;
        newLoas[changedValue] = loaState;
      }
    }
    setCredentialLoas(newLoas);
  };

  const isFormInvalid = () => {
    return !name || !purpose || !credentialLoas || !startDate || !endDate;
  };

  const formSubmit = (prType) => {
    if (isFormInvalid()) return;

    const parsedStartDate = Math.round(startDate / 1000);
    const parsedEndDate = Math.round(endDate / 1000);
    const procId = name;

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem("networkTechnology") || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem("networkId") || NETWORK_IDS[2],
      kid,
      validFrom: parsedStartDate,
      validUntil: parsedEndDate,
      presentationRequestContext: "https://www.w3.org/2018/credentials/examples/v1",
      presentationRequestType: prType,
      procHash: `0x0000000000000000000000000000000000000000000000000000000000000000`,
      procId,
      procDesc: purpose,
      procUrl: "",
      credentials: Object.keys(credentialLoas).map((credential) => {
        return {
          trustFramework: "ala",
          networkTechnology: "quor",
          networkId: "redT",
          kid,
          validFrom: parsedStartDate,
          validUntil: parsedEndDate,
          cbu: "http://cbu.com",
          credentialContext: "https://www.w3.org/2018/credentials/examples/v1",
          credentialType: credential,
          levelOfAssurance: credentialLoas[credential],
          file: "https://private-server/file0001",
        };
      }),
    };

    if (!delegatedPr) {
      const hash = crypto.createHash("sha256");
      hash.update(`${JSON.stringify(form)}${Date.now()}`);
      form.procHash = `0x${hash.digest().toString("hex")}`;
    } else {
      form.procHash = delegatedPr.decoded.pr.procHash;
    }

    setFormSendState(true);
    asyncFormSubmit(form, form.procHash);
  };

  const asyncFormSubmit = async (form, procHash) => {
    if (file) {
      const fileUpload = await customFilePost(`${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/api`, file, procHash);

      if (!isOk(fileUpload.status)) {
        setFormSendState(false);
        setWarningBoxOpen(false);
        setErrorPopup(fileUpload);
        return;
      }

      form.procUrl = `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}/files/${fileUpload.uriSafeFileName}`;
    }

    const response = await onFormSubmit(form);

    if (isOk(response.status)) {
      dispatch(clearPresentationsCache());
      dispatch(clearEntityPresentationsCache());
      navigate(delegatedPr ? -1 : PAGE_ROUTES.AdminPresentations);
    } else {
      setFormSendState(false);
      setWarningBoxOpen(false);
      setErrorPopup(response);
    }
  };

  const styles = {
    select: {
      control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: "1.4375em",
        width: "50rem",
        backgroundColor: "rgba(255, 255, 255, 0.38)",
        border: "3px solid rgba(4, 146, 148, 1)",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontSize: "1.125rem",
        opacity: "0.5",
        "&:hover": {
          border: "3px solid rgba(4, 126, 128, 1)",
        },
      }),
    },
  };

  const validForm = (e) => {
    e.preventDefault();
    if (isFormInvalid())
      return;
    setWarningBoxOpen(true);
  };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  sx={{
                    color: "#1B5255",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textDecoration: "none",
                    "a:visited": {
                      textDecoration: "none",
                      color: "#1B5255",
                    },
                    "a:link": { textDecoration: "none" },
                    "a:hover": { textDecoration: "none" },
                    "a:active": { textDecoration: "none" },
                  }}>
                  <Link to={PAGE_ROUTES.AdminPresentations}>{translate("presentations-page-title")}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1B5255", fontSize: "2rem" }}>-</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1B5255", fontSize: "2rem" }}>{translate("presentations-create-page-title")}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              sx={{
                height: "2.938rem",
                backgroundColor: "#1B5255",
                color: "white",
                fontSize: "1.125rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#1B5255",

                  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              }}
              onClick={useLinkClickHandler(-1)}>
              <Typography variant="h5" paddingRight={5} paddingLeft={5}>
                {translate("presentations-create-back")}
              </Typography>
              <ArrowForwardIos />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <form onSubmit={validForm}>
          <Grid container justifyContent="space-evenly" alignItems="center" paddingTop={5}>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-name")}</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    required
                    type="text"
                    onChange={nameChanged}
                    sx={{
                      width: "50rem",
                      backgroundColor: "rgba(255, 255, 255, 0.38)",
                      border: "3px solid rgba(4, 146, 148, 1)",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      fontSize: "1.125rem",
                    }}
                    value={name || ""}
                    placeholder={translate("presentations-create-name-placeholder")}
                    name="name"
                  />
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-purpose")}</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    required
                    onChange={purposeChanged}
                    sx={{
                      width: "50rem",
                      backgroundColor: "rgba(255, 255, 255, 0.38)",
                      border: "3px solid rgba(4, 146, 148, 1)",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      fontSize: "1.125rem",
                    }}
                    value={purpose || ""}
                    name="purpose"
                    placeholder={translate("presentations-create-purpose-placeholder")}
                  />
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-credentials")}</Typography>
                </Grid>
                <Grid item>
                  <Select2
                    isDisabled
                    styles={styles.select}
                    isMulti
                    options={catalog}
                    autoWidth
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    getOptionValue={(option) => {
                      return option.catalogId;
                    }}
                    onChange={(value) => {
                      credentialChanged(value);
                    }}
                    value={credentials || []}
                    IconComponent={SelectDropdownSquareIcon}
                    components={{ MultiValueLabel, Option }}
                  />
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-dates")}</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-start-date")}</Typography>
                        </Grid>

                        <DatePicker
                          required
                          max={endDate}
                          value={startDate} 
                          onChange={(unixTime) => setStartDate(unixTime)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-end-date")}</Typography>
                        </Grid>

                        <DatePicker
                          required
                          min={Math.max(Date.now(), startDate || Date.now())}
                          value={endDate}
                          onChange={(unixTime) => setEndDate(unixTime)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: "bold", fontSize: "1.125rem" }}>{translate("presentations-create-file")}</Typography>
                </Grid>
                            
                <Grid item>
                  <FileInput required value={file || ""} onChange={setFile} placeholder={translate("presentations-create-file-placeholder")}/>
                </Grid>

                <Grid item>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <Button
                        type="submit"
                        onClick={(e) => {           
                          setFormType(config.prConfig.delegatedType);
                        }}
                        disabled={delegatedPr}
                        sx={{
                          height: "2.938rem",
                          backgroundColor: "#1B5255",
                          color: "white",
                          fontSize: "0.875rem",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#1B5255",
                            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                          },
                          "&:disabled": {
                            backgroundColor: "grey",
                            color: "white",
                          },
                        }}>
                        <Typography variant="h5" paddingRight={5} paddingLeft={5}>
                          {translate("presentations-create-delegate")}
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        type="submit"
                        onClick={(e) => {           
                          setFormType(config.prConfig.formType);
                        }}
                        sx={{
                          height: "2.938rem",
                          backgroundColor: "#1B5255",
                          color: "white",
                          fontSize: "0.875rem",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#1B5255",
                            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                          },
                        }}>
                        <Typography variant="h5" paddingRight={5} paddingLeft={5}>
                          {translate("presentations-create-send")}
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item>
                      <WarningBox
                        title={translate(`admin-presentations-create-popup-${formType.toLowerCase()}-title`)}
                        open={warningBoxOpen}
                        onClose={() => {
                          if (formSendState) return;
                          setWarningBoxOpen(false);
                        }}
                        actions={
                          formSendState
                            ? []
                            : [
                                {
                                  label: translate(`admin-presentations-create-popup-${formType.toLowerCase()}-reject`),
                                  onClick: (event) => {
                                    setWarningBoxOpen(false);
                                  },
                                },
                                {
                                  label: translate(`admin-presentations-create-popup-${formType.toLowerCase()}-accept`),
                                  onClick: (event) => {
                                    formSubmit(formType);
                                  },
                                },
                              ]
                        }>
                          <SpinnerLoader isLoading={formSendState} text={translate(`admin-presentations-create-${formType.toLowerCase()}-sending`)}>
                            <Typography>{translate(`admin-presentations-create-popup-${formType.toLowerCase()}-body-kid`)}</Typography>
                            <Typography sx={{ fontWeight: "bold" }}>{whoAmI.businessName}</Typography>
                          </SpinnerLoader>
                      </WarningBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
