import { Button, Typography } from '@mui/material';

const DEFAULT_TEXT = '';
const DEFAULT_ON_CLICK = () => {};

export default function RedButton(props) {
  const text = props.text || DEFAULT_TEXT;
  const onClick = props.onClick || DEFAULT_ON_CLICK;

  return (
    <Button
      fullWidth
      size='small'
      sx={{
        backgroundColor: 'rgb(232, 112, 112, 0.63)',
        border: '2px solid #AB0000',
        color: '#000000',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'none',
        marginRight: '10px',
        '&:hover': {
          backgroundColor: 'rgb(232, 112, 112, 0.63)',
          boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
        }
      }}
      onClick={onClick}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{text}</Typography>
    </Button>
  );
}
