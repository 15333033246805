import React, { useState } from "react";
import { useLinkClickHandler } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { Translate, ArrowForwardIos } from "@mui/icons-material";
import mainLandingConfig from "../../../../config/mainLanding.config";
import Slide from "../../../../components/shared/Slides/Slide.component";
import ButtonWithOutline from "../../../../components/shared/Buttons/ButtonWithOutline.component";
import DigitalisIcon from "../../../../components/shared/Icons/DigitalisIcon.component";
import "./Landing.slide.css";
import { PAGE_ROUTES } from "../../../../config/constants.config";

function customBackground() {
  return (
    <video autoPlay loop muted className="video-background">
      <source src="/images/landing.mp4" type="video/mp4" />
    </video>
  );
}

function Content(translate, changeLanguage) {
  const [infotainerIndex, setIndex] = useState(0);

  function reduceIndex() {
    if (infotainerIndex === 0) return;
    setIndex(infotainerIndex - 1);
  }

  function increaseIndex() {
    if (infotainerIndex === mainLandingConfig.length - 1) return;
    setIndex(infotainerIndex + 1);
  }

  return (
    <div className="landing-content">
      <Grid container paddingTop={10} paddingBottom={10}>
        <Grid item container direction="column" justifyContent="center" alignItems="flex-start" xs={10}>
          <Grid item>
            <DigitalisIcon />
          </Grid>
          <Grid item container direction="column" justifyContent="center" alignItems="flex-start" spacing={5} paddingTop={5}>
            <Grid item>
              <Typography variant="h5">{translate(mainLandingConfig[infotainerIndex].title)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">{translate(mainLandingConfig[infotainerIndex].subtitle)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body">{translate(mainLandingConfig[infotainerIndex].description)}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-end" xs={2}>
          <Grid item paddingBottom={10}>
            <ButtonWithOutline onClick={changeLanguage}>
              <Translate />
            </ButtonWithOutline>
          </Grid>
          <Grid item container direction="column" justifyContent="flex-end" alignItems="stretch" spacing={2}>
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  color: "#A9D9D0",
                  border: "3px solid #A9D9D0",

                  "&:hover": { border: "3px solid #19CFAD", color: "#19CFAD" },
                }}
                onClick={useLinkClickHandler(PAGE_ROUTES.Login)}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "1.188rem",
                      }}
                    >
                      {translate("landing-login-button")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ArrowForwardIos />
                  </Grid>
                </Grid>
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                className="register-button"
                sx={{
                  color: "#F2E7DC",
                  border: "3px solid #F2E7DC",
                  "&:hover": { border: "3px solid #F2C0DC", color: "#F2C0DC" },
                }}
                onClick={useLinkClickHandler(PAGE_ROUTES.Register)}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: "1.188rem" }}>{translate("landing-create-button")}</Typography>
                  </Grid>
                  <Grid item>
                    <ArrowForwardIos />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const landing = { name: "landing", content: Content, customBackground };
export default function Landing(props) {
  landing.translator = props.translator;
  landing.changeLanguage = props.changeLanguage;

  return <Slide {...landing} />;
}
