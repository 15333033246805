import benefits from "./benefits.config";
import contacts from "./contacts.config";
import mainLanding from "./mainLanding.config";
import participants from "./participants.config";
import successCases from "./successCases.config";
import routes from "./routes.config";

export const AVAILABLE_ALGS = ["HS256", "HS384", "HS512", "RS256", "RS384", "RS512", "ES256", "ES384", "ES512", "ES256K", "EdDSA", "none"];
export const ALASTRIA_US = ["US12", "US211", "US221", "US231", "US142"];
export const W3C_CREDENTIAL_CONTEXT = "https://www.w3.org/2018/credentials/v1.";
export const W3C_CREDENTIAL_BASIC_TYPE = "VerifiableCredential";
export const W3C_PRESENTATION_BASIC_TYPE = "VerifiablePresentation";
export const ALASTRIA_TOKEN_TYPE = "AlastriaToken";
export const JWT_VEIA_CONTEXT = "https://www.veia.es/2023/jwt/context/v1";
export const JWT_VEIA_SCHEMA = "https://www.veia.es/2023/jwt/schema/v1";
export const TRUST_FRAMEWORKS = ["ala", "veia"];
export const TECHNOLOGIES = ["besu", "quor", "fabric", "geth"];
export const NETWORK_IDS = ["veia", "local", "redT", "redB"];
export const SETTINGS_NETWORKS = [ {networkId: NETWORK_IDS[2], networkTechnology: TECHNOLOGIES[1]}, {networkId: NETWORK_IDS[3], networkTechnology: TECHNOLOGIES[0]} ]
export const VALID_ID = /^(?:0x)?[0-9A-Fa-f]{40}$/;

// Backend Constants
export const PLATFORM_TYPE = ["WWC", "MOBILE"];
export const CUSTOMER_STATUS = ["STARTED", "COMPLETED", "INVALID"];
export const PRESENTATION_STATUS = ["Valid", "Received", "AskDeletion", "DeletionConfirmation"];
export const PRESENTATION_REQUEST_CUSTOMER_STATUS = ["Pending", "Accepted", "Rejected"];
export const PRESENTATION_REQUEST_STATUS = ["Created", "Deleted"];
export const CREDENTIAL_REQUEST_STATUS = ["Valid", "Deleted", "Resolved", "Rejected"];
export const CREDENTIAL_STATUS = ['Valid', 'AskIssuer', 'Expired', 'Revoked', 'DeletedBySubject'];

// Frontend Constants
export const CREDENTIAL_FILTER_STATUS = ["Revoked", "Pending", "Rejected", "Expired", "Valid"];
export const PAGE_ROUTES = routes;
export const MAX_LENGTH = [35, 25, 15, 10, 5];
export const ROW_COUNT = [5, 7, 10];
export const LEVELS_OF_ASSURANCE = [0, 1, 2, 3];
export const REDUCER_STATUS = {
  READY: 0,
  NEEDS_REFRESH: 1,
  LOADING: 2
}

const pageData = { benefits, contacts, mainLanding, participants, successCases };
export default pageData;
