import { SvgIcon } from "@mui/material";

export default function WrenchScrewdriverIcon() {
  return (
    <SvgIcon viewBox="-12 -12 96 96" style={{ width: 24, height: 24 }}>
      <path d='M58.2,13.8L58.2,13.8c-1.9-1.9-1.9-5,0-6.9l4.4-4.4C63.1,2,62.8,1.2,62.1,1c-4.3-1.2-9.2-0.1-12.6,3.3   c-3.7,3.7-4.6,9-3,13.5c0.1,0.3,0.1,0.7-0.2,1L18.8,46.3c-0.3,0.3-0.6,0.3-1,0.2c-4.6-1.7-9.9-0.7-13.5,3C0.8,53-0.2,57.8,1,62.2   c0.2,0.7,1.1,0.9,1.6,0.4L7,58.2c1.9-1.9,5-1.9,6.9,0l0,0c1.9,1.9,1.9,5,0,6.9l-4.4,4.4c-0.5,0.5-0.3,1.4,0.4,1.6   c4.3,1.2,9.2,0.2,12.6-3.3c3.7-3.7,4.6-9,3-13.5c-0.1-0.3-0.1-0.7,0.2-1l27.6-27.6c0.3-0.3,0.6-0.3,1-0.2c4.6,1.7,9.9,0.7,13.5-3   c3.4-3.4,4.5-8.3,3.3-12.6c-0.2-0.7-1.1-0.9-1.6-0.4L65,13.8C63.1,15.8,60.1,15.8,58.2,13.8z' />
      <g>
        <path d='M25.5,34l8.5-8.5c0.5-0.5,0.5-1.4,0-1.9L13.2,2.8C10.5,0.1,5.7-0.2,2.8,2.5c-3,2.8-3.1,7.6-0.2,10.5l21,21    C24.1,34.5,25,34.5,25.5,34z' />
        <path d='M67.9,61.9c-0.2-0.2-0.4-0.4-0.6-0.5l-3.7-1.9c-0.1-0.1-0.3-0.2-0.4-0.3l-18-18c-0.5-0.5-1.4-0.5-1.9,0    l-2.2,2.2c-0.5,0.5-0.5,1.4,0,1.9l18,18c0.1,0.1,0.2,0.3,0.3,0.4l1.9,3.7c0.1,0.2,0.3,0.4,0.5,0.6l4.9,3.3c0.6,0.4,1.5,0.3,2-0.2    l2.2-2.2c0.5-0.5,0.6-1.4,0.2-2L67.9,61.9z' />
      </g>
    </SvgIcon>
  );
}
