// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import SearchBar from '../../shared/SearchBars/SearchBar.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

import './CatalogFilters.component.css';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_CATALOG = [];
const DEFAULT_WIDTH = '16.563rem';
const DEFAULT_IS_LOADING = false;
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_ISSUER = '';
const DEFAULT_SET_ISSUER = () => {};
const DEFAULT_USE_CASE = '';
const DEFAULT_SET_USE_CASE = () => {};
const DEFAULT_HOLDER = '';
const DEFAULT_SET_HOLDER = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_CLEAR_FILTERS = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const width = props.width || DEFAULT_WIDTH;
  const catalog = props.catalog || DEFAULT_CATALOG;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const issuer = props.issuer || DEFAULT_ISSUER;
  const setIssuer = props.setIssuer || DEFAULT_SET_ISSUER;
  const useCase = props.useCase || DEFAULT_USE_CASE;
  const setUseCase = props.setUseCase || DEFAULT_SET_USE_CASE;
  const holder = props.holder || DEFAULT_HOLDER;
  const setHolder = props.setHolder || DEFAULT_SET_HOLDER;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const clearFilters = props.clearFilters || DEFAULT_CLEAR_FILTERS;

  const style = { width };
  const uniqueDict = {};

  const issuerOptions = catalog.filter((catalogItem) => {
    if (!catalogItem.issuer || uniqueDict[catalogItem.issuer] !== undefined) {
      return false;
    }

    uniqueDict[catalogItem.issuer] = null;
    return true;
  });

  const getIssuerText = (catalogEntry) => {
    return catalogEntry.issuer;
  };

  const useCaseOptions = catalog.filter((catalogItem) => {
    if (!catalogItem.useCase || uniqueDict[catalogItem.useCase] !== undefined) {
      return false;
    }

    uniqueDict[catalogItem.useCase] = null;
    return true;
  });

  const getUseCaseText = (catalogEntry) => {
    return catalogEntry.useCase;
  };

  const holderOptions = catalog.filter((catalogItem) => {
    if (!catalogItem.holder || uniqueDict[catalogItem.holder] !== undefined) {
      return false;
    }

    uniqueDict[catalogItem.holder] = null;
    return true;
  });

  const getHolderText = (catalogEntry) => {
    return catalogEntry.holder;
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='catalog-filters-container'>
      <Grid item paddingLeft={1} paddingRight={2}>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
          {translate('catalog-page-filter-head')}
        </Typography>
      </Grid>

      <Grid item>
        <SearchBar
          translate={translate}
          value={search}
          disabled={isLoading}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
          style={style}
        />
      </Grid>

      <Dropdown
        value={issuer}
        options={issuerOptions}
        getOptionLabel={getIssuerText}
        getOptionValue={getIssuerText}
        onChange={(catalogItem) => {
          setIssuer(catalogItem);
          onFilterSubmit();
        }}
        label={translate('catalog-page-filter-official-issuer')}
        isLoading={isLoading}
        selectStyle={style}
      />

      <Dropdown
        value={useCase}
        options={useCaseOptions}
        getOptionLabel={getUseCaseText}
        getOptionValue={getUseCaseText}
        onChange={(catalogItem) => {
          setUseCase(catalogItem);
          onFilterSubmit();
        }}
        label={translate('catalog-page-filter-use-case')}
        isLoading={isLoading}
        selectStyle={style}
      />

      <Dropdown
        value={holder}
        options={holderOptions}
        getOptionLabel={getHolderText}
        getOptionValue={getHolderText}
        onChange={(catalogItem) => {
          setHolder(catalogItem);
          onFilterSubmit();
        }}
        label={translate('catalog-page-filter-holder')}
        isLoading={isLoading}
        selectStyle={style}
      />

      <Grid item>
        <Button
          disabled={isLoading}
          onClick={clearFilters}
          sx={{
            backgroundColor: 'rgb(232, 112, 112, 0.63)',
            border: '2px solid #AB0000',
            color: '#000000',
            textTransform: 'none',
            fontWeight: 'bold',
            marginRight: '10px',
            '&:hover': {
              backgroundColor: 'rgb(232, 112, 112, 0.63)',
              boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)'
            }
          }}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('catalog-page-filter-remove-filters')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
