import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function SapIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 128.33, height: 63.51 }}>
      <path id="path5384" className="cls-1" d="M82.15,57v4.73h.71v-2h.8l1.3,2h.8l-1.38-2a1.29,1.29,0,0,0,1.25-1.34c0-.93-.57-1.34-1.7-1.34Zm.71.62h1c.49,0,1,.08,1,.67s-.55.81-1.16.81h-.85Zm.89-2.36a4.13,4.13,0,1,0,4.11,4.11A4.07,4.07,0,0,0,83.75,55.25Zm0,.67a3.44,3.44,0,1,1-3.39,3.44A3.38,3.38,0,0,1,83.75,55.92Z" />
      <path className="cls-1" d="M76.9,20.2H74.12V30.37H76.9c3.71,0,6.67-1.23,6.67-5.15S80.61,20.2,76.9,20.2Z" />
      <path className="cls-1" d="M46.92,24.29l-4,12.64a11.82,11.82,0,0,0,4.05.71h0a11.88,11.88,0,0,0,4-.68L47,24.29Z" />
      <path className="cls-1" d="M0,0V63.48H64.85L128.33,0ZM78.2,39.07H74.12V51.2H55.4l-1.85-5.8a21.23,21.23,0,0,1-6.59,1,20.93,20.93,0,0,1-6.74-1.07L38.29,51.2H26.82l1.27-3.39-.47.4a17.28,17.28,0,0,1-10.87,3.65h-.66A29.1,29.1,0,0,1,1.8,48.06l3.9-7.75c4.22,2.49,6.88,3,10.46,3,1.85,0,3.2-.38,4.07-1.28a3,3,0,0,0,.83-2c0-2-2.83-3-6.34-4a29.87,29.87,0,0,1-8.59-3.93A9.89,9.89,0,0,1,1.8,23.25a10.67,10.67,0,0,1,3-7.37C7.6,13,12.08,11.26,17.45,11.26h0a34.11,34.11,0,0,1,14.31,3.39L27.4,22.3a20.47,20.47,0,0,0-9.24-2.49c-3.78-.2-5.69,1.14-5.72,2.6,0,1.78,3.58,3.39,7,4.48C24.57,28.52,31.13,30.71,32.13,37l9.43-25.11H52.49l11,30v-30H76.16v0c12.07,0,17.74,4.3,17.74,13.67C93.9,34.05,88.06,39.07,78.2,39.07Z" />
    </SvgIcon>
  )
}