import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function CtaimaIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 229.49, height: 103.5 }}>
      <path className="cls-1" d="M98.76,44.18v19H94.22V36.25c1.57,0,3.15,0,4.73,0,.21,0,.45.33.61.56l7.38,11.39c.15.23.31.45.53.76.46-.7.88-1.32,1.28-1.94,2.2-3.4,4.41-6.79,6.59-10.2a1.15,1.15,0,0,1,1.15-.61c1.36,0,2.72,0,4.16,0V63.14h-4.56v-19L115.9,44l-8.52,12.63L99,44.12Z" />
      <path className="cls-1" d="M77.2,63.19c-1.51,0-3,0-4.43,0a1,1,0,0,1-.66-.53c-.78-1.74-1.52-3.5-2.24-5.27a.89.89,0,0,0-1-.68q-5.74,0-11.49,0a.86.86,0,0,0-.95.61c-.74,1.79-1.52,3.56-2.27,5.34a.77.77,0,0,1-.83.56c-1.36,0-2.72,0-4.17,0,.09-.26.15-.46.23-.66q5.67-12.9,11.31-25.8a.91.91,0,0,1,1-.64c1.06,0,2.12,0,3.17,0a.86.86,0,0,1,.66.41Q71.38,49.7,77.15,62.87A2.15,2.15,0,0,1,77.2,63.19ZM63.13,41.66,58.47,52.49h9.36Z" />
      <path className="cls-1" d="M153.69,63.19c-1.54,0-3,0-4.54,0-.2,0-.47-.32-.57-.55q-1.17-2.65-2.26-5.34a.82.82,0,0,0-.9-.58q-5.78,0-11.56,0a.85.85,0,0,0-.93.64c-.72,1.74-1.5,3.46-2.21,5.2a.9.9,0,0,1-1,.68c-1.33-.05-2.66,0-4.08,0,.13-.32.22-.58.33-.82q5.58-12.73,11.15-25.46a1.12,1.12,0,0,1,1.27-.83c1,.07,2,0,2.95,0a.93.93,0,0,1,.71.44q5.81,13.13,11.56,26.27A1.75,1.75,0,0,1,153.69,63.19Zm-9.36-10.68c-1.57-3.61-3.1-7.13-4.71-10.85-1.62,3.75-3.13,7.25-4.67,10.85Z" />
      <path className="cls-1" d="M24.45,40l-2.89,3.32a34.9,34.9,0,0,0-3.32-2.17A9,9,0,0,0,5,48,9.81,9.81,0,0,0,7.83,56.9a9.09,9.09,0,0,0,11.72.66c.72-.5,1.39-1.08,2.05-1.6l3,2.93c-4.58,5.67-14,6.35-19.65,1.69-6-5-6.6-14.8-1.25-20.51S19.34,34.51,24.45,40Z" />
      <path className="cls-1" d="M41.12,40.56v21.6c0,1,0,1-1,1H36.41V40.59H27.85V36.28c.26,0,.5,0,.74,0,6.75,0,13.5,0,20.25,0,.69,0,.95.17.91.88,0,.93,0,1.86,0,2.79,0,.53-.18.69-.71.68-2.31,0-4.63,0-7,0Z" />
      <path className="cls-1" d="M220.7,62.89l-7.53-11c2.5-3.72,5-7.45,7.58-11.25.85,1,1.75,1.92,2.55,2.93,2,2.5,3.93,5,5.9,7.55.25.32.45.61.1,1.05-2.79,3.5-5.55,7-8.33,10.52A2.1,2.1,0,0,1,220.7,62.89Z" />
      <path className="cls-1" d="M208.13,45.4c-.48-.58-.84-1-1.21-1.46-2-2.35-4-4.71-6-7.06a.78.78,0,0,1-.2-1c1.5-3.15,3-6.32,4.44-9.48a3.42,3.42,0,0,1,.26-.41c3.21,2.86,6.4,5.69,9.71,8.65Z" />
      <path className="cls-1" d="M208.19,58.35l6.94,10.56-9.5,8.56c-1-2.11-1.9-4.09-2.83-6.07-.6-1.27-1.25-2.51-1.84-3.79a.86.86,0,0,1,0-.77C203.33,64.05,205.7,61.27,208.19,58.35Z" />
      <path className="cls-1" d="M86.81,63.15H82.27V36.3h4.54Z" />
      <path className="cls-1" d="M203.29,51.86l-6.42,7.84a5.22,5.22,0,0,1-.34-.46c-1.35-2.36-2.7-4.71-4-7.08a1.11,1.11,0,0,1-.14-.87c1.37-2.46,2.77-4.9,4.26-7.49Z" />
      <path className="cls-1" d="M194.75,30.16l-2-2c-1.36-1.35-2.72-2.69-4.05-4.06a1.14,1.14,0,0,1-.33-.89c.65-2.66,1.36-5.3,2.06-7.94a1.48,1.48,0,0,1,.13-.26c2.11,1.5,4.21,3,6.29,4.46.67.48,1.7.91,1.86,1.54s-.46,1.57-.81,2.34c-.93,2.09-1.89,4.16-2.84,6.23C195,29.78,194.87,30,194.75,30.16Z" />
      <path className="cls-1" d="M195.08,73.33c.88,1.91,1.74,3.75,2.58,5.59.46,1,.91,2,1.32,3a.84.84,0,0,1-.13.75c-2.53,1.85-5.1,3.67-7.65,5.5,0,0-.1,0-.24,0-.34-1.32-.67-2.64-1-4s-.75-2.66-1.07-4a1.14,1.14,0,0,1,.2-.92c1.86-1.94,3.77-3.84,5.66-5.75C194.8,73.55,194.88,73.49,195.08,73.33Z" />
      <path className="cls-1" d="M191.3,65.89c-1,1.06-1.92,2-2.86,3-.74.75-1.51,1.46-2.31,2.23-.58-1.74-1.09-3.51-1.77-5.22a2.26,2.26,0,0,1,.34-2.45c1-1.35,1.85-2.79,2.85-4.33Z" />
      <path className="cls-1" d="M187.52,44.1c-1.25-1.9-2.42-3.65-3.57-5.42a.83.83,0,0,1-.14-.62c.61-1.93,1.26-3.85,1.93-5.89.86.87,1.65,1.66,2.43,2.47s1.68,1.73,2.49,2.63a.76.76,0,0,1,.17.69C189.77,40,188.68,42,187.52,44.1Z" />
      <path className="cls-1" d="M181.79,17.63c-1.66-1.47-3.2-2.83-4.73-4.21a1,1,0,0,1-.23-.66c.09-2,.2-4,.31-6.06.59.34,1.11.62,1.62.93,1.42.85,2.85,1.7,4.25,2.59.2.13.43.49.38.68C182.9,13.1,182.36,15.29,181.79,17.63Z" />
      <path className="cls-1" d="M182.29,85.81c.58,2.37,1.13,4.57,1.66,6.79a.56.56,0,0,1-.18.48c-1.91,1.2-3.84,2.37-5.87,3.61-.13-2.22-.26-4.29-.37-6.36a.63.63,0,0,1,.21-.45C179.21,88.55,180.69,87.23,182.29,85.81Z" />
      <path className="cls-1" d="M170.05,96.39c.06,1.52.14,2.84.17,4.17a.69.69,0,0,1-.26.55c-1.45.8-2.91,1.56-4.49,2.39.18-1.31.34-2.5.52-3.68a.66.66,0,0,1,.24-.36Z" />
      <path className="cls-1" d="M164.85,0c1.53.8,2.92,1.51,4.29,2.25a.7.7,0,0,1,.32.45c0,1.34,0,2.69,0,4l-.29.11a14.83,14.83,0,0,0-1.84-1.45A4.46,4.46,0,0,1,165,1.26,11.81,11.81,0,0,0,164.85,0Z" />
      <path className="cls-1" d="M180.62,55.48c-.55-1.41-1.05-2.68-1.53-4a.75.75,0,0,1,0-.49c.42-1.12.85-2.23,1.34-3.51.88,1.39,1.68,2.64,2.45,3.91a.62.62,0,0,1-.06.54C182.13,53.09,181.42,54.21,180.62,55.48Z" />
      <path className="cls-1" d="M178.39,72.66c.49,1.52,1,2.93,1.36,4.34a.77.77,0,0,1-.18.65c-.89.88-1.82,1.72-2.85,2.68-.08-.36-.15-.61-.18-.86-.1-.85-.16-1.7-.29-2.54a2.39,2.39,0,0,1,.54-2.09C177.34,74.2,177.8,73.47,178.39,72.66Z" />
      <path className="cls-1" d="M176,22.91c1.14,1.05,2.18,2,3.19,2.95a.65.65,0,0,1,.13.54c-.37,1.27-.77,2.53-1.21,3.94-.85-1.16-1.66-2.2-2.4-3.29a1.79,1.79,0,0,1-.06-1C175.74,25.09,175.86,24.1,176,22.91Z" />
    </SvgIcon>
  )
}