// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLinkClickHandler } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { CREDENTIAL_REQUEST_STATUS, CREDENTIAL_STATUS, MAX_LENGTH, PAGE_ROUTES, ROW_COUNT } from '../../../config/constants.config';

import { clearCredentialsCache } from '../../../helpers/reducers/adminCredentials';
import { getColorForStatus } from '../../../helpers/color.helper';
import { isOk, post } from '../../../helpers/axios.helper';

import CredentialRow from '../Rows/CredentialRow.component';
import RedButton from '../Buttons/RedButton.component';
import GreenButton from '../Buttons/GreenButton.component';

import WarningBox from '../../shared/DialogBoxes/WarningBox.component';
import TableCell from '../../shared/Tables/TableCell.component';
import SingleTableRow from '../../shared/Tables/SingleTableRow.component';
import TableHeader from '../../shared/Tables/TableHeader.component';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

import './CredentialTable.component.css';
import Hider from '../../shared/Hider/Hider.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_SET_ERROR_POPUP = () => {};
const DEFAULT_IS_LOADING = false;
const DEFAULT_CREDENTIALS = [];
const DEFAULT_CREDENTIAL_COUNT = 0;
const DEFAULT_IS_CREDENTIAL_REQUESTS = false;
const DEFAULT_ROWS_PER_PAGE = ROW_COUNT[0];
const DEFAULT_PAGE = 0;
const DEFAULT_SET_PAGE = () => {};
const DEFAULT_HIDE_PAGINATION = false;
const DEFAULT_HIDE_ACTIONS = false;
const DEFAULT_TRANSLATE_PREFIX = 'credential-table';

const loaText = 'loa';
const datesText = 'valid-dates';
const actionsText = 'actions';

const loas = [1, 2, 3];

function getHeaders(hideLoa, hideDates, hideActions, translate, translatePrefix) {
  return [
    {
      text: loaText,
      component: (
        <Grid container direction='row' justifyContent='space-around'>
          {loas.map((loa) => (
            <Grid item key={loa}>
              <Typography sx={{ fontWeight: 'bold' }}>{loa}</Typography>
            </Grid>
          ))}
        </Grid>
      )
    },
    { text: 'name', align: 'left' },
    { text: datesText },
    { text: 'receiver' },
    { text: 'data' },
    { text: 'status' },
    { text: 'proofs' },
    { text: actionsText }
  ]
    .filter((header) => {
      if (hideLoa && header.text === loaText) {
        return false;
      }
      if (hideDates && header.text === datesText) {
        return false;
      }
      if (hideActions && header.text === actionsText) {
        return false;
      }
      return true;
    })
    .map((header) => {
      header.text = translate(`${translatePrefix}-${header.text}`);
      return header;
    });
}

function StateButtons(props) {
  const translate = props.translate;
  const setErrorPopup = props.setErrorPopup;
  const isRequest = props.isRequest;
  const credential = props.credential;
  const storedJwt = props.storedJwt;

  const redirectToApprove = useLinkClickHandler(`${PAGE_ROUTES.AdminCredentialRequestsCreate}?id=${storedJwt.jti}`);
  const dispatch = useDispatch();

  const [warningBoxOpen, setWarningBoxOpen] = useState(false);
  const [formSendState, setFormSendState] = useState(false);

  const endpoint = isRequest ? '/dit/credentialRequest' : '/dit/credential';
  const formStatus = isRequest ? CREDENTIAL_REQUEST_STATUS[3] : CREDENTIAL_STATUS[3];

  const declineOrRevokeCredential = async (event, isDelete) => {
    event.preventDefault();
    const form = { ...storedJwt };
    let queryParams = new URLSearchParams();

    if (isDelete) {
      form.isDeleted = true;
      queryParams.append('propagated', true);
    } else {
      form.status = formStatus;
    }

    if (!form.fileUri) {
      form.fileUri = '';
    }

    setFormSendState(true);

    const result = await post(`${endpoint}/${storedJwt.jti}?${queryParams}`, form);
    if (isOk(result.status)) {
      dispatch(clearCredentialsCache());
    } else {
      setErrorPopup(result);
    }

    setFormSendState(false);
  };

  switch (credential.status) {
    default:
      return (
        <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
          {translate('admin-credentials-page-no-action-available')}
        </Typography>
      );
    case CREDENTIAL_REQUEST_STATUS[0]:
    case CREDENTIAL_STATUS[0]:
      return (
        <Grid container spacing={1} justifyContent='center' alignItems='center'>
          <WarningBox
            title={translate(`admin-credentials-popup-title${isRequest ? '-request' : ''}`)}
            open={warningBoxOpen}
            actions={
              formSendState
                ? []
                : [
                    {
                      label: translate('admin-credentials-popup-reject'),
                      onClick: (event) => {
                        setWarningBoxOpen(false);
                      }
                    },
                    {
                      label: translate(`admin-credentials-popup-revoke${isRequest ? '-request' : ''}`),
                      onClick: (event) => {
                        declineOrRevokeCredential(event, false);
                      }
                    }
                  ]
            }>
            <SpinnerLoader
              isLoading={formSendState}
              text={translate(`admin-credentials-sending${isRequest ? '-request' : ''}`)}>
              <Typography>{translate(`admin-credentials-popup-body${isRequest ? '-request' : ''}`)}</Typography>
            </SpinnerLoader>
          </WarningBox>

          {isRequest ? (
            <>
              <Grid item flexGrow={1}>
                <GreenButton text={translate('admin-credentials-table-accept-button')} onClick={redirectToApprove} />
              </Grid>

              <Grid item flexGrow={1}>
                <RedButton
                  text={translate('admin-credentials-table-reject-button')}
                  onClick={() => setWarningBoxOpen(true)}
                />
              </Grid>
            </>
          ) : (
            <Grid item sx={{ flexGrow: 1 }}>
              <RedButton
                text={translate('admin-credentials-table-revoke-button')}
                onClick={() => setWarningBoxOpen(true)}
              />
            </Grid>
          )}
        </Grid>
      );
    case CREDENTIAL_REQUEST_STATUS[1]:
    case CREDENTIAL_REQUEST_STATUS[2]:
    case CREDENTIAL_REQUEST_STATUS[3]:
    case CREDENTIAL_STATUS[2]:
    case CREDENTIAL_STATUS[3]:
    case CREDENTIAL_STATUS[4]:
      return (
        <Grid container spacing={1} justifyContent='center' alignItems='center'>
          <WarningBox
            title={translate(`admin-credentials-delete-popup-title${isRequest ? '-request' : ''}`)}
            open={warningBoxOpen}
            actions={
              formSendState
                ? []
                : [
                    {
                      label: translate('admin-credentials-delete-popup-reject'),
                      onClick: (event) => {
                        setWarningBoxOpen(false);
                      }
                    },
                    {
                      label: translate(`admin-credentials-delete-popup-revoke${isRequest ? '-request' : ''}`),
                      onClick: (event) => {
                        declineOrRevokeCredential(event, true);
                      }
                    }
                  ]
            }>
            <SpinnerLoader isLoading={formSendState} text={translate('admin-credentials-delete-sending')}>
              <Typography>{translate(`admin-credentials-delete-popup-body${isRequest ? '-request' : ''}`)}</Typography>
            </SpinnerLoader>
          </WarningBox>
          <Grid item sx={{ flexGrow: 1 }}>
            <RedButton
              text={translate('admin-credentials-table-delete-button')}
              onClick={() => setWarningBoxOpen(true)}
            />
          </Grid>
        </Grid>
      );
  }
}
// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CredentialTable(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const setErrorPopup = props.setErrorPopup || DEFAULT_SET_ERROR_POPUP;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const credentials = props.credentials || DEFAULT_CREDENTIALS;
  const credentialCount = props.credentialCount || DEFAULT_CREDENTIAL_COUNT;
  const isCredentialRequests = props.isCredentialRequests || DEFAULT_IS_CREDENTIAL_REQUESTS;
  const rowsPerPage = props.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const page = props.page || DEFAULT_PAGE;
  const setPage = props.setPage || DEFAULT_SET_PAGE;
  const hidePagination = props.hidePagination || DEFAULT_HIDE_PAGINATION;
  const hideActions = props.hideActions || DEFAULT_HIDE_ACTIONS;
  const ActionButtonsComponent = props.actionButtonsComponent || StateButtons;
  const translatePrefix = props.translatePrefix || DEFAULT_TRANSLATE_PREFIX;

  const hideLevelOfAssurance = isCredentialRequests;
  const hideDates = isCredentialRequests;

  const headers = getHeaders(hideLevelOfAssurance, hideDates, hideActions, translate, translatePrefix);

  const paginationColSpan = headers.length;
  const spinnerColSpan = headers.length;

  return (
    <Grid item paddingBottom={4}>
      <TableContainer className='credential-table-container'>
        <Table>
          <TableHeader headers={headers} />
          <TableBody>
            <SpinnerLoader
              isLoading={isLoading}
              text={translate(`${translatePrefix}-loading`)}
              component={SingleTableRow}
              componentProps={{ colSpan: spinnerColSpan, cellProps: { useTypography: false } }}>
              {credentials.map((credential) => {
                const vcOrVcr = isCredentialRequests ? credential.decoded.vcr : credential.decoded.vc;
                const name = vcOrVcr.catalog ? vcOrVcr.catalog.name : vcOrVcr.type[1];
                const startDate = credential.decoded.nbf;
                const endDate = credential.decoded.exp;
                const subjectName = vcOrVcr.credentialSubject.name;
                const hideHashButton = isCredentialRequests;
                const transactionHash = credential.storedJwt.transactionHash;
                const signedToken = credential.storedJwt.signedToken;
                const statusColor = getColorForStatus(vcOrVcr.status, isCredentialRequests);
                const status = vcOrVcr.status;
                const levelOfAssurance = vcOrVcr.credentialSubject.levelOfAssurance;
                const valueIsFile = vcOrVcr.catalog ? vcOrVcr.catalog.format === 'File' : false;
                const value = isCredentialRequests ? vcOrVcr.file : vcOrVcr.credentialSubject[vcOrVcr.type[1]];
                const actionButtons = (
                  <ActionButtonsComponent
                    isRequest={isCredentialRequests}
                    credential={vcOrVcr}
                    storedJwt={credential.storedJwt}
                    setErrorPopup={setErrorPopup}
                    translate={translate}
                  />
                );

                return (
                  <CredentialRow
                    key={credential.storedJwt.jti}
                    translate={translate}
                    name={name}
                    maxNameLength={MAX_LENGTH[isCredentialRequests ? 0 : 1]}
                    startDate={startDate}
                    endDate={endDate}
                    subjectName={subjectName}
                    hideHashButton={hideHashButton}
                    hideActions={hideActions}
                    transactionHash={transactionHash}
                    signedToken={signedToken}
                    statusColor={statusColor}
                    status={status}
                    hideLevelOfAssurance={hideLevelOfAssurance}
                    hideDates={hideDates}
                    levelOfAssurance={levelOfAssurance}
                    valueIsFile={valueIsFile}
                    value={value}
                    translatePrefix={translatePrefix}
                    actionButtons={actionButtons}
                  />
                );
              })}
            </SpinnerLoader>
            <Hider isHidden={hidePagination}>
              <TableRow>
                <TableCell colSpan={paginationColSpan} useTypography={false}>
                  <TablePagination
                    component='div'
                    count={credentialCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onPageChange={(event, newPage) => {
                      setPage(newPage);
                    }}
                  />
                </TableCell>
              </TableRow>
            </Hider>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
