import { VerifiedUserOutlined, SignalCellular0Bar, SignalCellular1Bar, SignalCellular3Bar, SignalCellular4Bar } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";

export default function LevelOfAssurance(props) {
  const level = props.level;
  const translate = props.translate;
  let loaIcon = <SignalCellular0Bar />;

  switch (level) {
    case 1:
      loaIcon = <SignalCellular1Bar />;
      break;
    case 2:
      loaIcon = <SignalCellular3Bar />;
      break;
    case 3:
      loaIcon = <SignalCellular4Bar />;
      break;
    default:
      loaIcon = <SignalCellular0Bar />;
      break;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <VerifiedUserOutlined />
      </Grid>
      <Grid item>
        <Tooltip arrow title={`${translate("loa")} ${level}`}>
          {loaIcon}
        </Tooltip>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: "0.75rem", fontWeight: "bold" }}>
          {translate('loa-short-hand')}{level}
        </Typography>
      </Grid>
    </Grid>
  );
}
