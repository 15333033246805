import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Settings, Translate, NotificationsNone, Logout, Description, ViewInAr, MenuBook } from "@mui/icons-material";
import { useLinkClickHandler } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearSession } from "../../../helpers/reducers/session";
import ButtonWithOutline from "../Buttons/ButtonWithOutline.component";
import "./Header.component.css";
import config from "../../../config/config";
import { PAGE_ROUTES, SETTINGS_NETWORKS } from "../../../config/constants.config";
import WarningBox from "../DialogBoxes/WarningBox.component";
import WrenchScrewdriverIcon from "../Icons/WrenchScrewdriverIcon.component";
import Dropdown from "../Dropdowns/Dropdown.component";

export default function Header(props) {
  const translate = props.translator;
  const changeLanguage = props.changeLanguage;
  const onlyTranslateButton = props.onlyTranslate;

  const dispatch = useDispatch();
  const redirectLandingPage = useLinkClickHandler(PAGE_ROUTES.Root);

  const [selectedNetwork, setSelectedNetwork] = useState(
    !sessionStorage.getItem("networkId")
    ? SETTINGS_NETWORKS[0]
    : SETTINGS_NETWORKS.find((network => network.networkId === sessionStorage.getItem("networkId")))
  );
  const [settingsPopup, setSettingsPopup] = useState(false);

  const logout = (e) => {
    dispatch(clearSession());
    redirectLandingPage(e);
  };

  const openPopup = (e) => {
    setSettingsPopup(true);
  };

  const translateButtonTitle = "header-tooltips-language";

  let buttons = [
    {
      title: "header-tooltips-catalog",
      href: PAGE_ROUTES.AdminCatalog,
      icon: <MenuBook />,
    },
    {
      title: "header-tooltips-file-explorer",
      href: `${window.location.protocol}${config.fileExplorerURL}${window.location.hostname}`,
      icon: <Description />,
    },
    {
      title: "header-tooltips-block-explorer",
      href: `${window.location.protocol}${config.blockExplorerURL}${window.location.hostname}`,
      icon: <ViewInAr />,
    },
    {
      title: translateButtonTitle,
      onClick: changeLanguage,
      icon: <Translate />,
    },
    {
      title: "header-tooltips-settings",
      onClick: openPopup,
      icon: <Settings />,
    },
    {
      title: "header-tooltips-issues",
      href: "https://grantthornton-es.atlassian.net/servicedesk/customer/portal/2/user/login?destination=portal%2F2",
      icon: <WrenchScrewdriverIcon />,
    },
    {
      title: "header-tooltips-notifications",
      icon: <NotificationsNone />,
    },
    {
      title: "header-tooltips-logout",
      onClick: logout,
      icon: <Logout />,
    },
  ];

  if (onlyTranslateButton) {
    buttons = buttons.filter((button) => button.title === translateButtonTitle);
  }

  return (
    <Grid className="header" container direction="column" alignItems="stretch" padding={4}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <img className="header-icon" src="/logos/logo.png" />
          </Grid>

          <Grid item>
            <WarningBox
              title={translate("settings-popup-title")}
              open={settingsPopup}
              minWidth="5%"
              minHeight="20rem"
              actions={[
                {
                  label: translate("settings-popup-close"),
                  onClick: () => {
                    setSettingsPopup(false);
                  },
                },
              ]}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography>{translate("settings-popup-network-selector-title")}</Typography>
                </Grid>
                <Dropdown
                  value={selectedNetwork}
                  options={SETTINGS_NETWORKS}
                  getOptionLabel={(network) => translate(`settings-popup-network-${network.networkId.toLocaleLowerCase()}`)}
                  getOptionValue={(network) => network.networkId}
                  onChange={(network) => {
                      sessionStorage.setItem("networkId", network.networkId);
                      sessionStorage.setItem("networkTechnology", network.networkTechnology);
                      setSelectedNetwork(network)
                  }}
                  selectStyle={{
                    borderRadius: "10px",
                  }}
                />
              </Grid>
            </WarningBox>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              {buttons.map((button) => {
                const clickProps = {};

                if (button.href) {
                  clickProps.target = "_blank";
                  clickProps.href = button.href;
                }

                if (button.onClick) {
                  clickProps.onClick = button.onClick;
                }

                return (
                  <Grid item key={button.title}>
                    <ButtonWithOutline tooltip={translate(button.title)} {...clickProps}>
                      {button.icon}
                    </ButtonWithOutline>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
