import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function DigitalisIcon() {
  return (
    <SvgIcon inheritViewBox style={{ width: 245, height: 68.96 }}>
      <path className="cls-1" d="M95.26,20.66c0-1.08-.06-1.81,0-2.52.18-1.89,1.27-2.63,3.14-2.56s2.61.89,2.6,2.72c0,11.44.1,22.88-.11,34.32-.18,9.08-7,15.52-17.05,16.25-7.26.53-13.69-1.46-18.15-7.66a15,15,0,0,1-1.9-4.65c-.45-1.6,0-3,1.92-3.26,1.67-.28,3.16,0,3.52,2,.89,4.94,6.92,8.18,12.7,8.14,6.48-.06,11-2.69,12.62-7.89.62-2,.6-4.1,1-6.93-6.23,5.43-12.62,7.5-20,5.51a19.35,19.35,0,0,1-10.95-7.58,20.51,20.51,0,0,1,2-25.9C74.2,13.07,84.47,13,95.26,20.66Zm0,14.21A14.21,14.21,0,0,0,80.87,20.33,14.38,14.38,0,0,0,66.55,34.77,14.13,14.13,0,0,0,80.77,49.19C89.14,49.21,95.23,43.19,95.25,34.87Z" />
      <path className="cls-1" d="M34.31,48.8c-5,4.54-10.42,6.75-16.85,5.92a19.44,19.44,0,0,1-12.95-7,20.85,20.85,0,0,1,.43-26.42c5.83-6.53,18.79-10.16,29.28-.7.08-.85.2-1.52.2-2.19q0-6.93,0-13.87c0-2,.67-3.22,3-3.17s2.67,1.3,2.66,3.18q0,23.13,0,46.26c0,2-.44,3.26-2.81,3.26s-2.86-1.35-2.83-3.29C34.43,50.27,34.36,49.73,34.31,48.8Zm-14.4.39A14.14,14.14,0,0,0,34.43,34.81a14.41,14.41,0,1,0-28.82,0A14.17,14.17,0,0,0,19.91,49.19Z" />
      <path className="cls-1" d="M167.54,52.4c-5.1,2.56-10.1,3.27-15.34,1.65a11.83,11.83,0,0,1-8.82-11.95,11.88,11.88,0,0,1,9.07-11.52A19,19,0,0,1,166,31.66c.44.2.88.37,1.32.55.06,0,.16,0,.23-.06,1.5-8.5-4-13.71-12-11.24a6.55,6.55,0,0,0-4.92,5.45c-.41,2.46-1.52,3.21-3.88,2.77s-2.1-2.27-1.79-3.83a12.07,12.07,0,0,1,10-10c5.9-1.16,11.52-.45,15.62,4.74a14.6,14.6,0,0,1,2.84,9.36c0,7,.07,14-.06,20.93,0,1.21-.54,3-1.39,3.47A3.1,3.1,0,0,1,167.54,52.4Zm-9.41-2.95a35.56,35.56,0,0,0,4.7-.83c3.21-1,4.86-3.24,4.9-6.18A5.82,5.82,0,0,0,163,36.26a25.29,25.29,0,0,0-9.26-.14c-3.13.56-4.68,3.24-4.65,6.27a6.31,6.31,0,0,0,4.72,6.3A30.44,30.44,0,0,0,158.13,49.45Z" />
      <path className="cls-1" d="M221.65,14.57c7.43.36,12.07,3.68,13.47,9.84.4,1.76.15,3.19-1.81,3.55-1.76.33-3.44.15-3.79-2.24-.39-2.73-2.16-4.2-4.73-4.92a10.83,10.83,0,0,0-8.48.83c-3.57,2-3.58,6.59.12,8.33,2.61,1.22,5.54,1.72,8.3,2.65a35.54,35.54,0,0,1,6.75,2.63A9.89,9.89,0,0,1,233,51.06c-5.53,5.11-16.84,5.27-22.17,0-1.66-1.65-2.53-4.21-3.41-6.48a2,2,0,0,1,1.87-2.8c1.55-.19,3.11-.31,3.7,1.75,1.1,3.84,3.21,5.65,7.12,5.62a27.78,27.78,0,0,0,7.36-1,4.27,4.27,0,0,0,3-4.26,4.37,4.37,0,0,0-2.93-4.37c-2.88-1.07-5.86-1.82-8.8-2.72-1.24-.38-2.48-.74-3.69-1.2-4.72-1.76-7-4.83-7-9.42a10.13,10.13,0,0,1,7-10A54.93,54.93,0,0,1,221.65,14.57Z" />
      <path className="cls-1" d="M128.09,15.6c0-3,0-5.67,0-8.33,0-1.94.63-3,2.83-3s2.78,1.15,2.74,3.07c-.05,2.1.06,4.22,0,6.32-.06,1.5.53,2,2,1.94a36.06,36.06,0,0,1,3.89,0c1.73.06,2.35,1.07,2.35,2.73s-.68,2.61-2.4,2.67h-.25c-1.8.18-4.23-.26-5.22.73s-.33,3.38-.34,5.15c0,8,0,15.91,0,23.86,0,1.93-.32,3.32-2.74,3.33s-2.85-1.3-2.84-3.27c0-9.08-.07-18.17,0-27.26,0-2.06-.64-2.79-2.63-2.55-.88.1-1.78,0-2.67,0-1.92.09-2.86-.74-2.87-2.71s.93-2.79,2.84-2.74C124.4,15.63,126,15.6,128.09,15.6Z" />
      <path className="cls-1" d="M186.66,27.08c0,7.87,0,15.74,0,23.61,0,2-.3,3.39-2.69,3.39s-3.08-.7-3.09-3.3c0-15.82,0-31.65,0-47.47,0-2,.66-3.28,2.89-3.31s2.93,1.11,2.91,3.22C186.62,11.17,186.66,19.12,186.66,27.08Z" />
      <path className="cls-1" d="M115.43,34.85c0,5.27,0,10.54,0,15.81,0,1.73-.28,3.28-2.53,3.41-2.41.14-3.2-.54-3.21-3.06q0-13,0-26c0-2,0-4.06,0-6.08s.42-3.36,2.78-3.3,3,.82,3,3.44C115.44,24.3,115.43,29.57,115.43,34.85Z" />
      <path className="cls-1" d="M48.78,34.84c0-5.35,0-10.69,0-16,0-2,.5-3.27,2.84-3.22,2.14,0,2.75,1.13,2.74,3.09q0,16.16,0,32.32c0,1.9-.54,3.08-2.74,3.11s-2.87-1.19-2.85-3.23C48.81,45.53,48.77,40.18,48.78,34.84Z" />
      <path className="cls-1" d="M200.87,35c0,5.27,0,10.53,0,15.79,0,2-.43,3.29-2.8,3.29s-2.79-1.36-2.78-3.31q0-16,0-32.07c0-2.09.68-3.18,2.93-3.15s2.67,1.3,2.66,3.17C200.85,24.17,200.87,29.59,200.87,35Z" />
      <path className="cls-1" d="M112.42,1.44a4,4,0,0,1,4,4,4,4,0,0,1-4,3.75,3.68,3.68,0,0,1-3.74-3.86A3.68,3.68,0,0,1,112.42,1.44Z" />
      <path className="cls-1" d="M201.94,4.14a3.85,3.85,0,1,1-7.69.07,3.85,3.85,0,0,1,7.69-.07Z" />
      <path className="cls-1" d="M55.41,5.36a3.86,3.86,0,1,1-3.75-3.93A3.89,3.89,0,0,1,55.41,5.36Z" />
      <path className="cls-1" d="M242.79,4.88a4.32,4.32,0,0,1,1.62,1.61,4.72,4.72,0,0,1,0,4.57,4.58,4.58,0,0,1-6.19,1.62,4.42,4.42,0,0,1-1.62-1.62,4.72,4.72,0,0,1,0-4.57,4.32,4.32,0,0,1,1.62-1.61,4.47,4.47,0,0,1,2.29-.59A4.41,4.41,0,0,1,242.79,4.88Zm-.29,7.32a3.82,3.82,0,0,0,1.4-1.42,4.18,4.18,0,0,0,0-4,3.72,3.72,0,0,0-1.4-1.41,4.09,4.09,0,0,0-4,0,3.72,3.72,0,0,0-1.4,1.41,4.18,4.18,0,0,0,0,4,3.82,3.82,0,0,0,1.4,1.42,4.16,4.16,0,0,0,4,0Zm.24-1.32a.47.47,0,0,1,.1.25.33.33,0,0,1-.14.27.51.51,0,0,1-.32.1.5.5,0,0,1-.44-.25l-1-1.52a.92.92,0,0,0-.32-.33,1,1,0,0,0-.46-.1h-.61V11a.46.46,0,0,1-.13.34.54.54,0,0,1-.68,0,.45.45,0,0,1-.12-.34V6.59a.47.47,0,0,1,.11-.34.44.44,0,0,1,.34-.13h1.77a2,2,0,0,1,1.35.41,1.44,1.44,0,0,1,.49,1.16,1.47,1.47,0,0,1-.36,1,1.68,1.68,0,0,1-1,.5.89.89,0,0,1,.38.18,3.43,3.43,0,0,1,.34.38Zm-1.23-2.51a.77.77,0,0,0,.26-.65.81.81,0,0,0-.25-.66,1.28,1.28,0,0,0-.78-.2h-1.18V8.57h1.18A1.2,1.2,0,0,0,241.51,8.37Z" />
    </SvgIcon>
  )
}