import { CREDENTIAL_STATUS, CUSTOMER_STATUS, CREDENTIAL_REQUEST_STATUS, PRESENTATION_REQUEST_CUSTOMER_STATUS, PRESENTATION_REQUEST_STATUS, PRESENTATION_STATUS } from "../config/constants.config";

export function getColorForStatus(status, isCredentialRequest) {
  const okCredentialStatus = isCredentialRequest ? CREDENTIAL_REQUEST_STATUS[2] : CREDENTIAL_STATUS[0];
  switch (status) {
    case okCredentialStatus:
    case CUSTOMER_STATUS[1]:
    case PRESENTATION_REQUEST_CUSTOMER_STATUS[1]:
      return "#049294";
    case CREDENTIAL_STATUS[2]:
    case CREDENTIAL_STATUS[3]:
    case CREDENTIAL_STATUS[4]:
    case CREDENTIAL_REQUEST_STATUS[1]:
    case CREDENTIAL_REQUEST_STATUS[3]:
    case PRESENTATION_REQUEST_CUSTOMER_STATUS[2]:
    case PRESENTATION_STATUS[2]:
    case PRESENTATION_STATUS[3]:
      return "#AB0000";
    default:
      return "#012A2D";
  }
}
